import DataAPI from '@/lib/axios';
import { routeContains } from '@/util/util';

export default {
  getGeneral(infos) {
    return DataAPI.post('/compare/general', infos);
  },
  getWeek(infos) {
    infos.filters.cumul = true;
    return DataAPI.post('/compare/week', infos);
  },
  getTab() {
    return DataAPI.get('/compare/tab');
  },
  getGraphAndTableForComparisonService(tabId, tabFamily, filters) {
    return this.getCompareKey()
      .then(compareKey => {
        const type = ['financier', 'nb_dep'].includes(tabFamily) ? 'by-graph' : 'average';
        if (compareKey === 'compare') {
          return DataAPI.post(`/${compareKey}/${type}/${tabId}`, { filters });
        }

        return DataAPI.get(`/${compareKey}/${type}/${tabId}`, { params: { filters } });
      });
  },
  getTotalExport(filters) {
    return this.getCompareKey()
      .then(compareKey => {
        if (compareKey === 'compare') {
          return DataAPI.post(`/${compareKey}/total`, { filters });
        }
        return DataAPI.get(`/${compareKey}/total`, { params: { filters } });
      });
  },
  filterParams(filters) {
    const allowed = [
      'id_installation',
      'societe',
      'groupe_cabinet',
      'cabinet',
      'medecin',
      'consolidateSubstitutes',
      'appareil',
      'date_debut',
      'date_fin',
      'workday_count',
      'echelle',
      'include_sat',
      'include_sun',
      'weeks',
      'daysWeek',
      'year',
      'displayType',
      'type_appareil'
    ];

    const filtered = Object.keys(filters)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = filters[key];
        return obj;
      }, {});

    return filtered;
  },
  getCompareKey() {
    return new Promise((resolve, reject) => {
      const compareKey = routeContains(['date_a_date']) ? 'compare'
        : (routeContains(['par_semaine_et_par_jour'])) ? 'compare-week'
          : null;
      if (compareKey) {
        resolve(compareKey);
      } else {
        reject('this route can be call only in [date_a_date] and [par_semaine_et_par_jour] page');
      }
    });
  }
};
