import Cookies from 'js-cookie';
import appService from '@/service/appService';
import { extractConstDashboardValue, getAllowedCrudActions } from '@/util/util';

const state = {
  size: Cookies.get('size') || 'medium',
  grid: {},
  loading: false,
  loaderUidList: [],
  type_dashboard: {}
};

const mutations = {
  SET_SIZE: (state, size) => {
    state.size = size;
    Cookies.set('size', size);
  },
  SET_GRID: (state, grid) => {
    state.grid = grid;
  },
  UPDATE_LOADING: (state, loading) => {
    state.loading = loading;
  },
  PUSH_TO_LOADER_UID_LIST: (state, uid) => {
    state.loaderUidList.push(uid);
  },
  REMOVE_FROM_LOADER_UID_LIST: (state, uid) => {
    const index = state.loaderUidList.indexOf(uid);
    if (index > -1) {
      state.loaderUidList.splice(index, 1);
    }
  },
  UPDATE_TYPE_DASHBOARD: (state, type_dashboard) => {
    state.type_dashboard = type_dashboard;
  }
};

const actions = {
  setSize({ commit }, size) {
    commit('SET_SIZE', size);
  },
  setGrid({ commit }, grid) {
    commit('SET_GRID', grid);
  },
  userAskForModule({ commit }, moduleName) {
    return appService.userAskForModule(moduleName)
      .then(bool => bool)
      .catch(() => false);
  },
  updateLoading({ commit }, bool) {
    commit('UPDATE_LOADING', bool);
  },
  pushToLoaderUidList({ commit, state }, uid) {
    if (!state.loaderUidList.includes(uid)) {
      commit('PUSH_TO_LOADER_UID_LIST', uid);
    }
  },
  removeFromLoaderUidList({ commit, state }, uid) {
    if (state.loaderUidList.includes(uid)) {
      commit('REMOVE_FROM_LOADER_UID_LIST', uid);
    }
  },
  updateTypeDashboard({ commit }, route) {
    const { path } = route;
    const pageInfo = extractConstDashboardValue(path) || {};
    const {
      CRUD, HISTORIC_CRUD, STORE, CHILDRENS
    } = pageInfo;

    if (STORE === 'cmm') {
      if (CRUD) {
        pageInfo.CRUD = getAllowedCrudActions(CRUD);
      }
      if (HISTORIC_CRUD) {
        pageInfo.HISTORIC_CRUD = getAllowedCrudActions(HISTORIC_CRUD);
      }
      if (CHILDRENS) {
        pageInfo.CHILDRENS.HISTORIC_CRUD = getAllowedCrudActions(CHILDRENS.HISTORIC_CRUD);
      }
    }

    commit('UPDATE_TYPE_DASHBOARD', pageInfo);
  }
};

const getters = {
  typeDashboard: (state) => state.type_dashboard,
  getTypeDashboardSlug: (state) => state.type_dashboard?.SLUG,
  currentLoaderUid: (state) => {
    return state.loaderUidList[state.loaderUidList.length - 1];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
