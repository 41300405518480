<template>
    <section class="app-main">
        <transition name="fade-transform"
                    mode="out-in">
            <router-view :key="key" />
        </transition>
    </section>
</template>
<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path;
    }
  }
};
</script>
<style lang="scss" scoped>
.app-main {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.hasTagsView {
  .app-main {
    height: calc(100vh - 34px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style>
.el-popup-parent--hidden .fixed-header {
  padding-right: 15px;
}
</style>
