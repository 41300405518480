import DataAPI from '@/lib/axios';

export default {
  login(infos) {
    return DataAPI.post('/api/security/login_check', infos)
      .then(response => {
        return response.data;
      });
  },
  getRoutePermission(role) {
    return DataAPI.post('/api/security/route_check', { role })
      .then(response => {
        return response;
      });
  },
  refreshToken(refreshToken) {
    return DataAPI.post('/api/security/token/refresh', { refresh_token: refreshToken })
      .then(response => {
        return response.data;
      });
  },
  switchUser() {
    return DataAPI.get('/api/security/switch_user')
      .then(response => {
        return response.data;
      });
  },
  checkAuthenticated() {
    return DataAPI({
      method: 'post',
      url: '/security/check_authenticated'
    });
  }
};
