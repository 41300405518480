import typeDicomsService from '@/service/typeDicomsService';

const state = {
  typeDicoms: [],
  optionsTypeDicoms: []
};

const mutations = {
  UPDATE_TYPE_DICOMS: (state, typeDicoms) => {
    state.typeDicoms = typeDicoms;
  },
  UPDATE_OPTIONS_TYPE_DICOMS: (state, optionsTypeDicoms) => {
    state.optionsTypeDicoms = optionsTypeDicoms;
  }
};

const actions = {
  updateTypeDicoms({ commit }, typeDicoms) {
    commit('UPDATE_TYPE_DICOMS', typeDicoms);
  },
  updateOptionsTypeDicoms({ commit }, optionsTypeDicoms) {
    commit('UPDATE_OPTIONS_TYPE_DICOMS', optionsTypeDicoms);
  },
  getOptionsTypeDicoms({ commit }) {
    const that = this._vm;
    return typeDicomsService.getTypeDicoms()
      .then(typeDicoms => {
        commit('UPDATE_OPTIONS_TYPE_DICOMS', typeDicoms);
        return typeDicoms;
      }).catch(() => {
        that.$message.error('Erreur lors de la recuperation des types DICOMS');
        return Promise.reject();
      });
  }
};

const getters = {
  typeDicoms: (state) => state.typeDicoms
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
