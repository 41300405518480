import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';

Vue.use(Vuex);

// -------------------------------
// Code pour ne pas à avoir besoin d'import chaque module et de les declarer dans le store principal
// c'est un peu le meme principe que pour l'autoloader des class en PHP
// je definie le fichier target; ici /modules et ensuite il comprend automatiquement que chaque fichier
// à l'interieur est un module et l'import tout seul
// la variable modules contient tous les modules de notre fichier; c'est magique.
const modulesFiles = require.context('./module', true, /\.js$/);

const modules = modulesFiles.keys()
  .reduce((mdles, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = modulesFiles(modulePath);
    mdles[moduleName] = value.default;
    return mdles;
  }, {});
// -------------------------------

const store = new Vuex.Store({
  modules,
  getters
});

export default store;
