import DataAPI from '../lib/axios';

export default {
  fetchFlashFocusPeriodicityList() {
    return DataAPI.get('/api/flash_focus_periodicities', {})
      .then(response => response.data);
  },
  fetchFlashFocusList(filters, sort) {
    const params = {
      active: filters.active,
      createdBy: filters.createdBy,
      'installation.idInstallation': filters.installations,
      flashFocusPeriodicity: filters.periodicityId
    };

    if (sort) {
      const order = sort.order === 'ascending' ? 'asc' : 'desc';
      switch (sort.prop) {
        case 'installationName':
          sort.prop = 'installation.libelleCourt';
          break;
        case 'periodicityName':
          sort.prop = 'flashFocusPeriodicity.name';
          break;
        case 'createdByUser':
          sort.prop = 'createdBy.nom';
          break;
        case 'createdAtDateTime':
          sort.prop = 'createdAt';
          break;
        case 'updatedAtDateTime':
          sort.prop = 'updatedAt';
          break;
        default:
          break;
      }

      params[`order[${sort.prop}]`] = order;
    }
    return DataAPI.get('/api/flash_focus', {
      params
    });
  },
  fetchFlashFocus(flashFocusId) {
    return DataAPI.get(`/api/flash_focus/${flashFocusId}`)
      .then(async response => {
        const { data } = response;
        data.installation.id_installation = data.installation.idInstallation;
        data.installation.libelle_long = data.installation.libelleLong;
        data.flashFocusPeriodicity = data.flashFocusPeriodicity.id;

        const flashFocusGranularity = await this.fetchFlashFocusGranularity(data.flashFocusGranularity.id);

        flashFocusGranularity.offices?.forEach((office, index) => {
          if (typeof flashFocusGranularity.offices[index].labelCabinet !== 'undefined') {
            flashFocusGranularity.offices[index].name = flashFocusGranularity.offices[index].labelCabinet;
            delete flashFocusGranularity.offices[index].labelCabinet;
          }
        });
        data.flashFocusGranularity = { id: data.flashFocusGranularity.id, ...flashFocusGranularity };
        return data;
      });
  },
  async fetchFlashFocusGranularity(flashFocusGranularityId) {
    return DataAPI.get(`/api/flash_focus_granularities/${flashFocusGranularityId}`)
      .then(response => {
        const { data } = response;

        data.societies = data.societies.map((society) => {
          society.id = String(society.id);
          return society;
        });

        data.doctors = data.doctors.map((doctor) => {
          doctor.id = String(doctor.id);
          return doctor;
        });

        data.devices = data.devices.map((device) => {
          device.id = String(device.id);
          return device;
        });

        return data;
      });
  },
  fetchUsersByInstallation(id) {
    return DataAPI.get('/api/users', {
      params: {
        installations: id
      }
    });
  },
  fetchUserLimits() {
    return DataAPI.get('/api/flash_focus/uses_and_limits');
  },
  createFlashFocus(flashFocus) {
    return DataAPI.post('/api/flash_focus', flashFocus)
      .then(response => response.data);
  },
  updateFlashFocus(flashFocus) {
    return DataAPI.put(`/api/flash_focus/${flashFocus.id}`, flashFocus)
      .then(response => response.data);
  },
  deleteFlashFocus(flashFocusId) {
    return DataAPI.delete(`/api/flash_focus/${flashFocusId}`)
      .then(response => response.data);
  },
  fetchFlashFocusBlocksAndBlockFields(flashFocusPeriodicityId) {
    return DataAPI.get('/api/flash_focus_blocks', {
      params: {
        flashFocusPeriodicity: flashFocusPeriodicityId
      }
    }).then(response => response.data);
  },
  fetchFlashFocusBlocksAllowedByGranularities(flashFocusPeriodicityId, granularities) {
    return DataAPI.get('/api/flash_focus_blocks/allowed_by_granularities', {
      params: {
        flashFocusPeriodicityId,
        granularities
      }
    }).then(response => response.data);
  },
  sendFlashFocus(flashFocusId, date, useDefaultRecipients, customRecipients) {
    return DataAPI.post('/api/flash_focus/send', {
      flashFocusId,
      date,
      useFlashFocusRecipientUsers: useDefaultRecipients,
      userIds: customRecipients
    });
  }
};
