import router from '@/router/router';
import flashFocusService from '@/service/flashFocusService';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const state = {
  stepMinimum: 1,
  stepMaximum: 4,
  stepActive: 1,
  stepValidate: false,
  userLimits: {},
  contentBlocks: {},
  contentBlocksAllowed: {},
  isUpdate: false,
  flashFocusForms: {
    flashFocus: {},
    recipient: {},
    granularity: {},
    content: {
      blockFieldEnables: []
    }
  }
};

const mutations = {
  UPDATE_STEP_MINIMUM: (state, value) => {
    state.stepMinimum = value;
  },
  UPDATE_STEP_MAXIMUM: (state, value) => {
    state.stepMaximum = value;
  },
  UPDATE_STEP_ACTIVE: (state, value) => {
    state.stepActive = value;
  },
  UPDATE_STEP_VALIDATE: (state, value) => {
    state.stepValidate = value;
  },
  UPDATE_USER_LIMITS: (state, userLimits) => {
    state.userLimits = userLimits;
  },
  UPDATE_CONTENT_BLOCKS: (state, value) => {
    state.contentBlocks = value;
  },
  UPDATE_CONTENT_BLOCKS_ALLOWED: (state, value) => {
    state.contentBlocksAllowed = value;
  },
  UPDATE_IS_UPDATE: (state, value) => {
    state.isUpdate = value;
  },
  UPDATE_FORM_FLASH_FOCUS: (state, value) => {
    state.flashFocusForms.flashFocus = value;
  },
  UPDATE_FORM_RECIPIENT: (state, value) => {
    state.flashFocusForms.recipient = value;
  },
  UPDATE_FORM_GRANULARITY: (state, value) => {
    state.flashFocusForms.granularity = value;
  },
  UPDATE_FORM_CONTENT: (state, value) => {
    state.flashFocusForms.content = value;
  },
  REINIT_FORMS: (state) => {
    state.flashFocusForms.flashFocus = {};
    state.flashFocusForms.recipient = {};
    state.flashFocusForms.granularity = {};
    state.flashFocusForms.content.blockFieldEnables = [];
  }
};

const actions = {
  async initStore({ dispatch, commit }, flashFocusIdToUpdate) {
    try {
      updateAppLoading(dispatch, true);
      if (flashFocusIdToUpdate) {
        commit('UPDATE_IS_UPDATE', true);
        await dispatch('initStoreUpdate', flashFocusIdToUpdate);
      } else {
        commit('UPDATE_IS_UPDATE', false);
        await dispatch('initStoreCreate');
      }
      commit('UPDATE_STEP_VALIDATE', false);
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }
  },
  initStoreCreate({ commit }) {
    commit('REINIT_FORMS');
    commit('UPDATE_STEP_MINIMUM', 1);
    commit('UPDATE_STEP_ACTIVE', 1);
  },
  async initStoreUpdate({ dispatch, commit }, flashFocusIdToUpdate) {
    const flashFocus = await flashFocusService.fetchFlashFocus(flashFocusIdToUpdate);
    commit('UPDATE_FORM_FLASH_FOCUS', flashFocus);
    commit('UPDATE_FORM_RECIPIENT', { flashFocusRecipientUsers: flashFocus.flashFocusRecipientUsers });
    commit('UPDATE_FORM_GRANULARITY', flashFocus.flashFocusGranularity);
    commit('UPDATE_FORM_CONTENT', { blockFieldEnables: flashFocus.flashFocusBlockFieldEnables.map(blockField => blockField.id) });
    commit('UPDATE_STEP_ACTIVE', 1);
    commit('UPDATE_STEP_MINIMUM', 1);
  },
  updateForm({ state, commit }, form) {
    switch (state.stepActive) {
      case 1:
        commit('UPDATE_FORM_FLASH_FOCUS', form);
        break;
      case 2:
        commit('UPDATE_FORM_RECIPIENT', form);
        break;
      case 3:
        commit('UPDATE_FORM_GRANULARITY', form);
        break;
      case 4:
        commit('UPDATE_FORM_CONTENT', form);
        break;
      default:
        commit('REINIT_FORMS');
        break;
    }
  },
  async submitForm({ dispatch }) {
    try {
      updateAppLoading(dispatch, true);
      dispatch('saveFlashFocusForms');
      router.push({ name: 'flash_focus/dashboard_flash_focus' });
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }
  },
  updateStepValidate({ commit }, stepValidate) {
    commit('UPDATE_STEP_VALIDATE', stepValidate);
  },
  incrementStepActive({ state, dispatch, commit }) {
    if (state.stepActive === state.stepMaximum) {
      dispatch('submitForm');
    } else {
      commit('UPDATE_STEP_ACTIVE', state.stepActive + 1);
    }
  },
  decrementStepActive({ state, commit }) {
    commit('UPDATE_STEP_ACTIVE', state.stepActive - 1);
  },
  fetchUserLimits({ dispatch, commit }) {
    return flashFocusService.fetchUserLimits()
      .then((response) => {
        commit('UPDATE_USER_LIMITS', response.data);
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },
  async fetchFlashFocusBlocksAndBlockFields({ commit, state }) {
    const blocksAndBlockFields = await flashFocusService.fetchFlashFocusBlocksAndBlockFields(state.flashFocusForms?.flashFocus.flashFocusPeriodicity);
    const blocksAllowed = await flashFocusService.fetchFlashFocusBlocksAllowedByGranularities(state.flashFocusForms?.flashFocus.flashFocusPeriodicity, state.flashFocusForms?.granularity);
    commit('UPDATE_CONTENT_BLOCKS', blocksAndBlockFields);
    commit('UPDATE_CONTENT_BLOCKS_ALLOWED', blocksAllowed);
  },
  getGranularityInfoByInstallation({ dispatch }, installation) {
    dispatch('installation/updateInstallations', installation, { root: true });
    const promises = [];
    promises.push(dispatch('filter/getSociete', null, { root: true }));
    promises.push(dispatch('office/getCabinet', null, { root: true }));
    promises.push(dispatch('office/getGroupeCabinet', null, { root: true }));
    promises.push(dispatch('filter/getMedecin', null, { root: true }));
    promises.push(dispatch('filter/getAppareil', null, { root: true }));

    Promise.allSettled(promises);
  },
  async saveFlashFocusForms({ dispatch, state }) {
    await dispatch('formatFormsForSave');

    if (state.isUpdate) {
      await dispatch('updateFlashFocus');
    } else {
      await dispatch('createFlashFocus');
    }
  },
  formatFormsForSave({ state }) {
    const {
      flashFocus, recipient, content, granularity
    } = state.flashFocusForms;

    flashFocus.installation = flashFocus.installation.id_installation;
    flashFocus.flashFocusRecipientUsers = recipient.flashFocusRecipientUsers;
    flashFocus.flashFocusBlockFieldEnables = content.blockFieldEnables;
    flashFocus.flashFocusGranularity = {
      id: granularity?.id ? granularity?.id : undefined,
      societies: granularity?.societies ? granularity?.societies.map(item => item.id) : [],
      offices: granularity?.offices ? granularity?.offices.map(item => item.id) : [],
      doctors: granularity?.doctors ? granularity?.doctors.map(item => item.id) : [],
      devices: granularity?.devices ? granularity?.devices.map(item => item.id) : [],
      typeDicoms: granularity?.typeDicoms ? granularity.typeDicoms : [],
      displayComparisonNm1: granularity?.displayComparisonNm1,
      includeSaturday: granularity?.includeSaturday,
      includeSunday: granularity?.includeSunday
    };

    if (state.isUpdate && 'createdBy' in flashFocus) {
      delete flashFocus.createdBy;
    }
  },
  createFlashFocus({ state }) {
    const that = this._vm;

    return flashFocusService.createFlashFocus(state.flashFocusForms.flashFocus)
      .then(flashFocus => {
        that.$notify({
          title: 'Flash Focus créé',
          message: 'Le Flash Focus a été créé avec succès',
          type: 'success'
        });

        return flashFocus.id;
      })
      .catch((error) => {
        that.$notify({
          title: 'Erreur',
          message: 'Le Flash Focus n\'a pas pu être créé',
          type: 'error'
        });
        console.error(error);
      });
  },
  updateFlashFocus({ state }) {
    const that = this._vm;

    return flashFocusService.updateFlashFocus(state.flashFocusForms.flashFocus)
      .then(flashFocus => {
        that.$notify({
          title: 'Flash Focus modifié',
          message: 'Le Flash Focus a été modifié avec succès',
          type: 'success'
        });

        return flashFocus.id;
      })
      .catch((error) => {
        that.$notify({
          title: 'Erreur',
          message: 'Le Flash Focus n\'a pas pu être modifié',
          type: 'error'
        });
        console.error(error);
      });
  },
  async duplicateFlashFocus({ dispatch, commit }, flashFocusId) {
    updateAppLoading(dispatch, true);
    try {
      const flashFocus = await flashFocusService.fetchFlashFocus(flashFocusId);
      flashFocus.createdBy = flashFocus.createdBy.id || flashFocus.createdBy;
      flashFocus.flashFocusBlockFieldEnables = flashFocus.flashFocusBlockFieldEnables?.map(item => item.id) || [];
      flashFocus.flashFocusGranularity = {
        societies: flashFocus.flashFocusGranularity?.societies ? flashFocus.flashFocusGranularity?.societies.map(item => item.id) : [],
        offices: flashFocus.flashFocusGranularity?.offices ? flashFocus.flashFocusGranularity?.offices.map(item => item.id) : [],
        doctors: flashFocus.flashFocusGranularity?.doctors ? flashFocus.flashFocusGranularity?.doctors.map(item => item.id) : [],
        devices: flashFocus.flashFocusGranularity?.devices ? flashFocus.flashFocusGranularity?.devices.map(item => item.id) : [],
        typeDicoms: flashFocus.flashFocusGranularity?.typeDicoms ? flashFocus.flashFocusGranularity?.typeDicoms.map(item => item.id) : [],
        displayComparisonNm1: flashFocus.flashFocusGranularity?.displayComparisonNm1,
        includeSaturday: flashFocus.flashFocusGranularity?.includeSaturday,
        includeSunday: flashFocus.flashFocusGranularity?.includeSunday
      };
      flashFocus.flashFocusRecipientUsers = flashFocus.flashFocusRecipientUsers?.map(item => item.id) || [];
      flashFocus.installation = flashFocus.installation?.idInstallation || flashFocus.installation?.id || flashFocus.installation;
      delete flashFocus.id;
      commit('UPDATE_FORM_FLASH_FOCUS', flashFocus);
      await dispatch('createFlashFocus');
      commit('UPDATE_FORM_FLASH_FOCUS', {});
      await dispatch('flashFocus/fetchItems', null, { root: true });
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
