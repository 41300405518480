import Vue from 'vue';

Vue.directive('numberOnly', {
  bind(el, binding, vNode) {
    if (!binding.hasOwnProperty('value') || binding.value === true) {
      el.addEventListener('keyup', () => {
        const child = el.children[0];
        if (child.value && typeof child.value === 'string') {
          child.value = child.value.replace(/(?!^-)\D/g, '');
          child.value = child.value.replace(/\s\s+/g, ' ');
          return child.value;
        }
        return child.value;
      });
    } else if (binding.value === 'decimal') {
      el.addEventListener('keyup', () => {
        const regex = /^-?(0|[1-9][0-9]*)([,.][0-9]*)?$/;
        const child = el.children[0];
        if (!regex.test(child.value)) {
          let part = '';
          for (let i = 0; i < child.value.length; i++) {
            part += child.value.charAt(i);
            part = regex.test(part) ? part : part.substring(0, part.length - 1);
          }
          child.value = part;
        }
      });
    }
  },
  unbind(el, binding) {
    if (!binding.hasOwnProperty('value') || binding.value === true) {
      el.removeEventListener('keyup', () => {
        const child = el.children[0];
        if (child.value && typeof child.value === 'string') {
          child.value = child.value.replace(/\D/g, '');
          child.value = child.value.replace(/\s\s+/g, ' ');
          return child.value;
        }
        return child.value;
      });
    } else if (binding.value === 'decimal') {
      el.removeEventListener('keyup', () => {
        const regex = /^-?(0|[1-9][0-9]*)([,.][0-9]*)?$/;
        const child = el.children[0];
        if (!regex.test(child.value)) {
          let part = '';
          for (let i = 0; i < child.value.length; i++) {
            part += child.value.charAt(i);
            part = regex.test(part) ? part : part.substring(0, part.length - 1);
          }
          child.value = part;
        }
      });
    }
  }
});

Vue.directive('emptyValue', {
  bind(el, binding, vNode) {
    if (binding.hasOwnProperty('value')) {
      el.addEventListener('blur', () => {
        const child = el.children[0];
        if (child.value === '' || child.value === undefined || child.value === null) {
          child.value = binding.value;
        }
      }, true);
    }
  }
});

const fallBackImage = require('@/assets/images/placeholder-image.png');

const loader = fallBackImage;

Vue.directive('srcFallback', {
  bind(el, binding) {
    try {
      const { value } = binding;
      const img = new Image();
      let loading = loader;
      let error = fallBackImage;
      const original = el.src;
      if (el.src?.includes('[object%20Promise]')) {
        img.src = original;
        el.src = loading;
        return;
      }
      if (typeof value === 'string') {
        loading = value;
        error = value;
      }
      if (value instanceof Object) {
        loading = value.imageLoader || loader;
        error = value.fallBackImage || fallBackImage;
      }
      img.src = original;
      el.src = loading;
      img.onload = () => {
        el.src = original;
      };
      img.onerror = () => {
        el.src = error;
      };
    } catch (e) {
      console.error(e);
    }
  }
});
