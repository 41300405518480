<template>
    <el-button data-cy="button-validate"
               :disabled="disabled"
               :type="!loading ? 'success' : 'info'"
               :size="size"
               :icon="iconIsSave"
               :loading="loading"
               @click="handleClick">
        {{ buttonName }}
    </el-button>
</template>

<script>
export default {
  name: 'UIButtonValidate',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'big'
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    buttonName: {
      type: String,
      default: 'Valider'
    },
    saveIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconSave: 'el-icon-check'
    };
  },
  computed: {
    iconIsSave() {
      return this.saveIcon ? this.iconSave : this.icon;
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    }
  }
};
</script>
