import DataAPI from '../lib/axios';

export default {
  addMaintenances(form) {
    return DataAPI.post('/cmm/maintenance/add', form)
      .then(response => {
        return response.data;
      });
  },
  getMaintenances(materialId) {
    return DataAPI.get('/api/contrat_materiel_maintenances', {
      params: {
        materiel: materialId
      }
    }).then(response => response.data);
  }
};
