import DataAPI from '@/lib/axios';

export default {
  fetchUserListByInstallationIds(installationIds, withInactives, withSuperAdmins) {
    const params = {};

    if (installationIds.length > 0) {
      params.installations = installationIds;
    }

    if (!withInactives) {
      params.actif = true;
    }

    if (withSuperAdmins) {
      params.role = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'];
    }

    return DataAPI.get('/api/users', { params })
      .then(response => response.data);
  },
  fetchUser(id) {
    return DataAPI.get(`/api/users/${id}`)
      .then(response => response.data);
  },
  createUser(user) {
    return DataAPI.post('/api/users', user)
      .then(res => res.data);
  },
  updateUser(user) {
    return DataAPI.put(`/api/users/${user.id}`, user)
      .then(res => res.data);
  }
};
