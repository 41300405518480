import Router from 'vue-router';
import { getProdUrl } from '@/util/util';

// Prevent Navigation Failire with new vue-router
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      const prodUrl = getProdUrl(location.path || location.name);
      if (prodUrl) {
        window.location.href = prodUrl;
      }
      return err;
    }
    return Promise.reject(err);
  });
};
