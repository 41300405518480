import DataAPI from '../lib/axios';

export default {
  getAllowedInstallationInformations() {
    return DataAPI.get('api/installation/allowed_installation_informations')
      .then(response => {
        return response.data;
      });
  }
};
