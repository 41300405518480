import DataAPI from '../lib/axios';

export default {
  getCabinetInfosByInstallation(idsInstallation) {
    return DataAPI.get('api/cabinets', {
      params: {
        installation: idsInstallation
      }
    }).then(response => {
      return response.data.map(d => {
        d.labelCabinet = d.labelCabinet.replace(/\\'/g, "'");
        d.name = d.labelCabinet;
        delete d.labelCabinet;
        return d;
      });
    });
  },
  getGroupeCabinetByInstallationIdsAndUserId(installationIds, userId) {
    return DataAPI.get('/api/groupe_cabinets', {
      params: {
        installation: installationIds,
        user: userId
      }
    }).then(response => {
      const groupeCabinets = response.data;
      groupeCabinets.forEach(groupeCabinet => {
        groupeCabinet.cabinets.forEach(cabinet => {
          cabinet.labelCabinet = cabinet.labelCabinet.replace(/\\'/g, "'");
          cabinet.name = cabinet.labelCabinet;
          delete cabinet.labelCabinet;
        });
      });

      return groupeCabinets;
    });
  }
};
