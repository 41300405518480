import BaseLayout from '@/layout/BaseLayout';
import { getStore } from '@/util/storage';

export default [
  {
    path: '/remplacement',
    component: BaseLayout,
    name: 'remplacement',
    meta: {
      authRequired: true, title: 'Remplacement', icon: 'fas fa-calendar', css_class: 'replacement'
    },
    children: [
      {
        path: 'remp_liste_titu',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=remplacements/remp_liste_titu.php`;
        },
        name: 'remplacement/remp_liste_titu',
        meta: {
          authRequired: true, role: 'ROLE_REPLACEMENT_HOLDER_READ', digitalReplacement: true, title: 'Titulaires', icon: 'fas fa-user', css_class: 'replacement'
        }
      },
      {
        path: 'remp_liste_remplacant',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=remplacements/remp_liste_remplacant.php`;
        },
        name: 'remplacement/remp_liste_remplacant',
        meta: {
          authRequired: true, role: 'ROLE_REPLACEMENT_SUBSTITUTE_READ', digitalReplacement: true, title: 'Remplaçants', icon: 'fas fa-users', css_class: 'replacement'
        }
      },
      {
        path: 'remp_type_remp',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=remplacements/remp_type_remp.php`;
        },
        name: 'remplacement/remp_type_remp',
        meta: {
          authRequired: true, role: 'ROLE_REPLACEMENT_SUBSTITUTE_TYPE_READ', digitalReplacement: true, title: 'Types remplacements', icon: 'fas fa-user', css_class: 'replacement'
        }
      },
      {
        path: 'remp_liste_demande_remp',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=remplacements/remp_liste_demande_remp.php`;
        },
        name: 'remplacement/remp_liste_demande_remp',
        meta: {
          authRequired: true, role: 'ROLE_REPLACEMENT_REQUEST_READ', digitalReplacement: true, title: 'Demandes', icon: 'fab fa-weixin', css_class: 'replacement'
        }
      },
      {
        path: 'remp_calendar_global',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=remplacements/remp_calendar_global.php`;
        },
        name: 'remplacement/remp_calendar_global',
        meta: {
          authRequired: true, role: 'ROLE_REPLACEMENT_CALENDAR_READ', digitalReplacement: true, title: 'Calendrier', icon: 'fas fa-calendar', css_class: 'replacement'
        }
      },
      {
        path: 'remp_liste_suivi_vacations',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=remplacements/remp_liste_suivi_vacations.php.php`;
        },
        name: 'remplacement/remp_liste_suivi_vacations',
        meta: {
          authRequired: true, role: 'ROLE_REPLACEMENT_VACATION_READ', digitalReplacement: true, title: 'Suivi des vacations', icon: 'fas fa-sliders-h', css_class: 'replacement'
        }
      }
    ]
  }
];
