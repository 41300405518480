import installationService from '@/service/installationService';
import { removeUnusePropertyFromFilter } from '@/util/util';

const state = {
  installations: [],
  options_installations: []
};

const mutations = {
  UPDATE_INSTALLATIONS: (state, installations) => {
    state.installations = installations;
  },
  UPDATE_OPTIONS_INSTALLATIONS: (state, optionsInstallations) => {
    state.options_installations = optionsInstallations;
  }
};

const actions = {
  updateInstallations({ commit }, installations) {
    installations = Array.isArray(installations) ? installations : [installations];
    commit('UPDATE_INSTALLATIONS', installations);
  },
  updateOptionsInstallations({ commit }, optionsInstallations) {
    commit('UPDATE_OPTIONS_INSTALLATIONS', optionsInstallations);
  },
  async initState({ getters, dispatch }) {
    const allowedInstallationInformations = await installationService.getAllowedInstallationInformations();
    dispatch('updateOptionsInstallations', allowedInstallationInformations);
    dispatch('updateInstallations', getters.defaultInstallation);
  }
};

const getters = {
  defaultInstallation: (state) => (state.options_installations || [])[0] || {},
  defaultSelectedInstallation: (state) => (state.installations || [])[0] || state.options_installations[0],
  defaultSelectedInstallations: (state) => (state.installations || []) || {},
  defaultSelectedIDInstallation: (state) => (state.installations.length > 0 && state.installations[0] ? state.installations[0]?.id_installation : state.options_installations[0]?.id_installation),
  AllIdsInstallations: (state, getters) => getters.initializationMultipleInstallations.map(el => el?.id_installation),
  initializationSingleInstallation: (state, getters) => (!getters.defaultSelectedIDInstallation ? getters.defaultInstallation : getters.defaultSelectedInstallation),
  initializationMultipleInstallations: (state, getters) => (!getters.defaultSelectedIDInstallation ? [] : state.installations),
  installationPropertyFiltered: (state) => removeUnusePropertyFromFilter(state.installations),
  optionsInstallationPropertyFiltered: (state) => removeUnusePropertyFromFilter(state.options_installations)
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
