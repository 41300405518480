import modalityService from '@/service/modalityService';
import statService from '@/service/statService';

const state = {
  modalities: undefined,
  selectedModalities: [],
  modalityList: {},
  currentTab: 'global'
};

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const mutations = {
  SET_MODALITIES: (state, modalities) => {
    state.modalities = modalities;
  },
  UPDATE_SELECTED_MODALITIES: (state, selectedModalities) => {
    state.selectedModalities = selectedModalities;
  },
  UPDATE_MODALITY_LIST: (state, modalityList) => {
    state.modalityList = modalityList;
  },
  UPDATE_CURRENT_TAB: (state, currentTab) => {
    state.currentTab = currentTab;
  }
};

const actions = {
  loadModalities({ commit, state }) {
    if (state.modalities) {
      return Promise.resolve(state.modalities);
    }
    return modalityService.getModalities()
      .then(modalities => {
        commit('SET_MODALITIES', modalities);
        return modalities;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  },
  updateCurrentTab({ commit }, tab) {
    commit('UPDATE_CURRENT_TAB', tab);
  },
  updateSelectedModalites({ commit }, modalites) {
    commit('UPDATE_SELECTED_MODALITIES', modalites);
  },
  async fetchModalityList({ commit, rootGetters }) {
    return statService.fetchModalityList(rootGetters['installation/defaultSelectedIDInstallation'])
      .then((response) => {
        commit('UPDATE_MODALITY_LIST', response);
        return response;
      })
      .catch((error) => {
        console.error('error', error);
        return Promise.reject(error);
      });
  }
};

const getters = {
  currentTab: (state) => state.currentTab
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
