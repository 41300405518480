<template>
    <el-row>
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 :validate-on-rule-change="false">
            <el-form-item label="Titre"
                          prop="title">
                <el-input v-model="form.title" />
            </el-form-item>
            <el-form-item label="Heure"
                          prop="time">
                <el-time-picker
                    v-model="form.time"
                    placeholder="Horaire libre">
                </el-time-picker>
            </el-form-item>
            <el-form-item label="Module"
                          prop="module">
                <el-select v-model="form.module"
                           placeholder="Sélectionnez un module">
                    <el-option
                        v-for="item in modules"
                        :key="item.id"
                        :label="item.module"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Navigateur utilisé"
                          prop="browser">
                <el-select v-model="form.browser"
                           placeholder="Sélectionnez un navigateur">
                    <el-option
                        v-for="item in browsers"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Résultat attendu"
                          prop="expectedResult">
                <el-input v-model="form.expectedResult"
                          type="textarea"
                          :rows="2"
                          maxlength="100"
                          show-word-limit
                          resize="none" />
            </el-form-item>
            <el-form-item label="Résultat obtenu"
                          prop="currentResult">
                <el-input v-model="form.currentResult"
                          type="textarea"
                          :rows="2"
                          maxlength="100"
                          show-word-limit
                          resize="none" />
            </el-form-item>
            <el-form-item label="Marche à suivre"
                          prop="steps">
                <el-input v-model="form.steps"
                          type="textarea"
                          :rows="2"
                          maxlength="100"
                          show-word-limit
                          resize="none" />
            </el-form-item>
            <el-checkbox v-model="form.mail_notif"
                         class="m-l-sm checkbox">
                Être informé de l'évolution de l'incident par mail
            </el-checkbox>
        </el-form>
    </el-row>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'BugReportAddForm',
  data() {
    return {
      browsers: [
        {
          label: 'Google Chrome',
          value: 'google_chrome'
        },
        {
          label: 'Mozilla Firefox',
          value: 'mozilla_firefox'
        },
        {
          label: 'Internet Explorer',
          value: 'internet_explorer'
        },
        {
          label: 'Safari',
          value: 'safari'
        },
        {
          label: 'Opera',
          value: 'opera'
        },
        {
          label: 'Autre',
          value: 'other'
        }
      ],
      form: {
        title: undefined,
        time: undefined,
        module: undefined,
        browser: undefined,
        expectedResult: undefined,
        currentResult: undefined,
        steps: undefined,
        mail_notif: false,
        statut: 1
      },
      rules: {
        title: {
          required: true,
          message: 'Vous devez renseingner un titre',
          trigger: 'blur'
        },
        time: {
          required: true,
          message: 'Vous devez sélectionner une heure',
          trigger: 'blur'
        },
        module: {
          required: true,
          message: 'Vous devez sélectionner un module',
          trigger: 'blur'
        },
        browser: {
          required: true,
          message: 'Vous devez sélectionner un navigateur',
          trigger: 'blur'
        },
        expectedResult: {
          required: true,
          message: 'Vous devez entrer une description du résultat obtenu',
          trigger: 'blur'
        },
        currentResult: {
          required: true,
          message: 'Vous devez entrer une description du résultat attendu',
          trigger: 'blur'
        }
      }
    };
  },
  computed: {
    ...mapState('module', ['modules'])
  },
  methods: {
    ...mapActions('bugReport', ['createBugReport']),
    async validForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          this.$message.error('Attention, certains champs sont obligatoires');
          return false;
        }
        this.createBugReport(this.form);
        this.$emit('closeModal');
      });
    }
  }
};
</script>
