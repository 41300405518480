import controlResultService from '@/service/controlResultService';

const state = {
  controlResults: []
};

const mutations = {
  SET_CONTROL_RESULTS: (state, controlResults) => {
    state.controlResults = controlResults;
  }
};

const actions = {
  getControlResults({ state, commit }) {
    if (state.controlResults?.length) {
      return Promise.resolve(state.controlResults);
    }
    return controlResultService.getControlResults()
      .then(controlResults => {
        commit('SET_CONTROL_RESULTS', controlResults);
        return controlResults;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
