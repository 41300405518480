import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import isNumber from 'lodash.isnumber';
import businessPlanService from '@/service/businessPlanService';
import CONSTANTS from '@/util/constant/constantApp';

const state = {
  isActiveFilter: true,
  mainPanel: {
    ca: [],
    location: [],
    etp: [],
    others: []
  },
  childsPanel: {
    ca: [],
    location: [],
    etp: []
  },
  search: null,
  sortBy: null,
  sortDesc: null,
  businessPlan: {},
  businessPlanList: [],
  expandAll: false,
  expandAllDefault: false,
  pdfDatas: []
};

const mutations = {
  SET_EXPAND_ALL_DEFAULT(state, value) {
    state.expandAllDefault = value;
  },
  UPDATE_MAIN_PANEL(state, panel) {
    state.mainPanel[panel.key] = panel.value;
  },
  UPDATE_CHILDS_PANEL(state, panel) {
    state.childsPanel[panel.key] = panel.value;
  },
  ADD_PDF_DATAS(state, item) {
    state.pdfDatas.push(item);
  },
  RESET_PDF_DATAS(state) {
    state.pdfDatas = [];
  },
  UPDATE_EXPAND_ALL(state, bool) {
    state.expandAll = bool;
  },
  UPDATE_BUSINESS_PLAN_LIST(state, list) {
    state.businessPlanList = list;
  },
  UPDATE_FILTER_IS_OPEN(state, bool) {
    state.filterIsOpen = bool;
  },
  UPDATE_SEARCH(state, search) {
    state.search = search;
  },
  RESET_BUSINESS_PLAN_SIMULATIONS: (state) => {
    state.businessPlan.businessPlanVersions = [];
  },
  TOGGLE_DISABLE_STUDY(state, bool) {
    state.isActiveFilter = bool;
  },
  SET_BUSINESS_PLAN(state, item) {
    state.businessPlan = item;
  },
  UPDATE_SIMULATION(state, bool) {
    state.simulationHasChanged = bool;
  }
};

const actions = {
  emptyCollapse({ state, commit }) {
    for (const [key, value] of Object.entries(state.mainPanel)) {
      commit('UPDATE_MAIN_PANEL', { key, value: [] });
    }

    for (const [key, value] of Object.entries(state.childsPanel)) {
      commit('UPDATE_CHILDS_PANEL', { key, value: [] });
    }
  },
  async duplicateBusinessPlan({ state, dispatch, commit }, data) {
    dispatch('app/updateLoading', true, { root: true });
    if (data.id) {
      await dispatch('getBusinessPlanByID', data.id);
    }

    const that = this._vm;
    const copyBP = cloneDeep(state.businessPlan);

    try {
      const devices = await copyBP.businessPlanDevices?.map((el) => {
        return {
          device: el.device.id,
          risCaFt: el.risCaFt,
          risTotalFees: el.risTotalFees,
          risNbExam: el.risNbExam,
          risOverrun: el.risOverrun
        };
      });

      const offices = await copyBP.businessPlanPractices?.map((el) => {
        return {
          practice: el.practice.id,
          risCaFt: el.risCaFt,
          risTotalFees: el.risTotalFees,
          risNbExam: el.risNbExam,
          risOverrun: el.risOverrun
        };
      });

      const newBP = await dispatch('addBusinessPlan', {
        title: data.title || copyBP.title,
        startDate: copyBP.startDate,
        endDate: copyBP.endDate,
        type: copyBP.type,
        isActive: copyBP.isActive,
        businessPlanPractices: offices,
        businessPlanDevices: devices,
        installation: copyBP.installation
      });

      if (newBP) {
        await copyBP.businessPlanVersions.reduce(async (promise, el) => {
          await promise;
          const asyncOffices = el.businessPlanVersionPractices.map((item, index) => {
            return {
              nbExams: item.nbExams,
              nbHoursOpeningPeriod: item.nbHoursOpeningPeriod,
              businessPlanPractice: newBP.businessPlanPractices[index].id,
              exceedingRate: item.exceedingRate,
              nbExamsOpeningPeriod: item.nbExamsOpeningPeriod,
              monthlyRentalEquipment: item.monthlyRentalEquipment,
              monthlyEquipmentMaintenance: item.monthlyEquipmentMaintenance,
              qualityControl: item.qualityControl,
              hourlyCostEtpRadiologistFees: item.hourlyCostEtpRadiologistFees,
              hourlyCostEtpSubstituteFees: item.hourlyCostEtpSubstituteFees,
              hourlyCostEtpHandlingType1: item.hourlyCostEtpHandlingType1,
              hourlyCostEtpHandlingType2: item.hourlyCostEtpHandlingType2,
              hourlyCostEtpSecretaryFees: item.hourlyCostEtpSecretaryFees,
              hourlyCostEtpSecretaryFees2: item.hourlyCostEtpSecretaryFees2,
              nbEtpRadiologists: item.nbEtpRadiologists,
              nbEtpSubstitutes: item.nbEtpSubstitutes,
              nbEtpHandlingType1: item.nbEtpHandlingType1,
              nbEtpHandlingType2: item.nbEtpHandlingType2,
              nbEtpSecretary: item.nbEtpSecretary,
              nbEtpSecretary2: item.nbEtpSecretary2
            };
          });

          const asyncDevices = el.businessPlanVersionDevices.map((item, index) => {
            return {
              nbExams: item.nbExams,
              nbHoursOpeningPeriod: item.nbHoursOpeningPeriod,
              businessPlanDevice: newBP.businessPlanDevices[index].id,
              exceedingRate: item.exceedingRate,
              nbExamsOpeningPeriod: item.nbExamsOpeningPeriod,
              monthlyRentalEquipment: item.monthlyRentalEquipment,
              monthlyEquipmentMaintenance: item.monthlyEquipmentMaintenance,
              qualityControl: item.qualityControl,
              hourlyCostEtpRadiologistFees: item.hourlyCostEtpRadiologistFees,
              hourlyCostEtpSubstituteFees: item.hourlyCostEtpSubstituteFees,
              hourlyCostEtpHandlingType1: item.hourlyCostEtpHandlingType1,
              hourlyCostEtpHandlingType2: item.hourlyCostEtpHandlingType2,
              hourlyCostEtpSecretaryFees: item.hourlyCostEtpSecretaryFees,
              hourlyCostEtpSecretaryFees2: item.hourlyCostEtpSecretaryFees2,
              nbEtpRadiologists: item.nbEtpRadiologists,
              nbEtpSubstitutes: item.nbEtpSubstitutes,
              nbEtpHandlingType1: item.nbEtpHandlingType1,
              nbEtpHandlingType2: item.nbEtpHandlingType2,
              nbEtpSecretary: item.nbEtpSecretary,
              nbEtpSecretary2: item.nbEtpSecretary2
            };
          });

          await dispatch('addBusinessPlanVersion', {
            title: el.title,
            medicalPurchases: el.medicalPurchases,
            otherMaterialCharges: el.otherMaterialCharges,
            computing: el.computing,
            subContracting: el.subContracting,
            energy: el.energy,
            purchasesAndExternalServices: el.purchasesAndExternalServices,
            purchaseTaxesAndFees: el.purchaseTaxesAndFees,
            realEstateRental: el.realEstateRental,
            insurance: el.insurance,
            otherExpenses: el.otherExpenses,
            isRealData: el.isRealData,
            businessPlan: newBP.id,
            businessPlanVersionDevices: asyncDevices,
            businessPlanVersionPractices: asyncOffices,
            updateSimulations: false
          });
        }, undefined);
        if (data.reloadSimulation) {
          await dispatch('getBusinessPlanByID', newBP.id);
        }

        dispatch('app/updateLoading', false, { root: true });
        that.$message.success('Une copie de ce business plan a bien été créée');
      }
    } catch (error) {
      const { businessPlan, businessPlanList } = state;

      commit('app/UPDATE_LOADING', false, { root: true });
      commit('UPDATE_BUSINESS_PLAN_LIST', businessPlanList.filter(el => el.id !== businessPlan.id));

      dispatch('app/updateLoading', false, { root: true });

      that.$message.error('Une erreur est survenue lors de la duplication du business plan, veuillez réesayer');
    }

    return state.businessPlan;
  },
  async duplicateBusinessPlanAndUpdate({ state, dispatch, commit }, data) {
    dispatch('app/updateLoading', true, { root: true });

    if (data.id) {
      await dispatch('getBusinessPlanByID', data.id);
    }

    const that = this._vm;
    const copyBP = cloneDeep(state.businessPlan);

    try {
      const asyncDevices = await Promise.all(copyBP.businessPlanDevices?.map(async (el) => {
        const data = {
          startDate: copyBP.startDate,
          endDate: copyBP.endDate,
          installationId: copyBP.installation,
          id: el.device.id
        };

        const result = await dispatch('getAppareilStatsByID', data);

        return {
          device: el.device.id,
          risCaFt: Math.round(result.caFt),
          risTotalFees: Math.round(result.totalFees),
          risNbExam: Math.round(result.nbExams),
          risOverrun: Math.round(result.overrun)
        };
      }));

      const asyncOffices = await Promise.all(copyBP.businessPlanPractices?.map(async (el) => {
        const data = {
          startDate: copyBP.startDate,
          endDate: copyBP.endDate,
          installationId: copyBP.installation,
          id: el.practice.id
        };

        const result = await dispatch('getCabinetStatsByID', data);

        return {
          practice: el.practice.id,
          risCaFt: Math.round(result.caFt),
          risTotalFees: Math.round(result.totalFees),
          risNbExam: Math.round(result.nbExams),
          risOverrun: Math.round(result.overrun)
        };
      }));

      const newBP = await dispatch('addBusinessPlan', {
        title: data.title || copyBP.title,
        startDate: copyBP.startDate,
        endDate: copyBP.endDate,
        type: copyBP.type,
        isActive: copyBP.isActive,
        businessPlanPractices: asyncOffices,
        businessPlanDevices: asyncDevices,
        installation: copyBP.installation
      });

      if (newBP) {
        await copyBP.businessPlanVersions.reduce(async (promise, el) => {
          await promise;
          const asyncOffices = await Promise.all(el.businessPlanVersionPractices.map(async (item, index) => {
            const data = {
              startDate: copyBP.startDate,
              endDate: copyBP.endDate,
              installationId: copyBP.installation,
              id: item.businessPlanPractice.practice.id
            };

            const result = await dispatch('getCabinetStatsByID', data);

            return {
              nbExams: item.nbExams,
              nbHoursOpeningPeriod: result.nbHoursOpeningPeriod,
              businessPlanPractice: newBP.businessPlanPractices[index].id,
              exceedingRate: item.exceedingRate,
              nbExamsOpeningPeriod: item.nbExamsOpeningPeriod,
              monthlyRentalEquipment: item.monthlyRentalEquipment,
              monthlyEquipmentMaintenance: item.monthlyEquipmentMaintenance,
              qualityControl: item.qualityControl,
              hourlyCostEtpRadiologistFees: item.hourlyCostEtpRadiologistFees,
              hourlyCostEtpSubstituteFees: item.hourlyCostEtpSubstituteFees,
              hourlyCostEtpHandlingType1: item.hourlyCostEtpHandlingType1,
              hourlyCostEtpHandlingType2: item.hourlyCostEtpHandlingType2,
              hourlyCostEtpSecretaryFees: item.hourlyCostEtpSecretaryFees,
              hourlyCostEtpSecretaryFees2: item.hourlyCostEtpSecretaryFees2,
              nbEtpRadiologists: item.nbEtpRadiologists,
              nbEtpSubstitutes: item.nbEtpSubstitutes,
              nbEtpHandlingType1: item.nbEtpHandlingType1,
              nbEtpHandlingType2: item.nbEtpHandlingType2,
              nbEtpSecretary: item.nbEtpSecretary,
              nbEtpSecretary2: item.nbEtpSecretary2
            };
          }));

          const asyncDevices = await Promise.all(el.businessPlanVersionDevices.map(async (item, index) => {
            const data = {
              startDate: copyBP.startDate,
              endDate: copyBP.endDate,
              installationId: copyBP.installation,
              id: item.businessPlanDevice.device.id
            };

            const result = await dispatch('getAppareilStatsByID', data);

            return {
              nbExams: item.nbExams,
              nbHoursOpeningPeriod: result.nbHoursOpeningPeriod,
              businessPlanDevice: newBP.businessPlanDevices[index].id,
              exceedingRate: item.exceedingRate,
              nbExamsOpeningPeriod: item.nbExamsOpeningPeriod,
              monthlyRentalEquipment: item.monthlyRentalEquipment,
              monthlyEquipmentMaintenance: item.monthlyEquipmentMaintenance,
              qualityControl: item.qualityControl,
              hourlyCostEtpRadiologistFees: item.hourlyCostEtpRadiologistFees,
              hourlyCostEtpSubstituteFees: item.hourlyCostEtpSubstituteFees,
              hourlyCostEtpHandlingType1: item.hourlyCostEtpHandlingType1,
              hourlyCostEtpHandlingType2: item.hourlyCostEtpHandlingType2,
              hourlyCostEtpSecretaryFees: item.hourlyCostEtpSecretaryFees,
              hourlyCostEtpSecretaryFees2: item.hourlyCostEtpSecretaryFees2,
              nbEtpRadiologists: item.nbEtpRadiologists,
              nbEtpSubstitutes: item.nbEtpSubstitutes,
              nbEtpHandlingType1: item.nbEtpHandlingType1,
              nbEtpHandlingType2: item.nbEtpHandlingType2,
              nbEtpSecretary: item.nbEtpSecretary,
              nbEtpSecretary2: item.nbEtpSecretary2
            };
          }));

          await dispatch('addBusinessPlanVersion', {
            title: el.title,
            medicalPurchases: el.medicalPurchases,
            otherMaterialCharges: el.otherMaterialCharges,
            computing: el.computing,
            subContracting: el.subContracting,
            energy: el.energy,
            purchasesAndExternalServices: el.purchasesAndExternalServices,
            purchaseTaxesAndFees: el.purchaseTaxesAndFees,
            realEstateRental: el.realEstateRental,
            insurance: el.insurance,
            otherExpenses: el.otherExpenses,
            isRealData: el.isRealData,
            businessPlan: newBP.id,
            businessPlanVersionDevices: asyncDevices,
            businessPlanVersionPractices: asyncOffices,
            updateSimulations: false
          });
        }, undefined);
        if (data.reloadSimulation) {
          await dispatch('getBusinessPlanByID', newBP.id);
        }

        dispatch('app/updateLoading', false, { root: true });
        that.$message.success('Une copie de ce business plan a bien été créée');
      }
    } catch (error) {
      const { businessPlan, businessPlanList } = state;

      commit('app/UPDATE_LOADING', false, { root: true });
      commit('UPDATE_BUSINESS_PLAN_LIST', businessPlanList.filter(el => el.id !== businessPlan.id));

      dispatch('app/updateLoading', false, { root: true });

      that.$message.error('Une erreur est survenue lors de la duplication du business plan, veuillez réesayer');
    }

    return state.businessPlan;
  },
  filterRequest({ dispatch, rootState, state }, data) {
    const { cabinets } = rootState.office;
    const { appareils } = rootState.device;

    const cabinetsMap = cabinets.length > 0 ? cabinets.map(el => el.id) : [];
    const devicesMap = appareils.length > 0 ? appareils.map(el => el.id) : [];

    if (data.sorting) {
      const { value } = data.sorting;

      switch (data.sorting.name) {
        case 'title':
          data.order.title = value ? 'asc' : 'desc';
          break;
        case 'updatedAt':
          data.order.updatedAt = value ? 'asc' : 'desc';
          break;
        case 'period':
          data.order.startDate = value ? 'asc' : 'desc';
          break;
        case 'createdAt':
          data.order.createdAt = value ? 'asc' : 'desc';
          break;
        case 'offices':
          data.order.businessPlanPractices = value ? 'asc' : 'desc';
          break;
        case 'devices':
          data.order.businessPlanDevices = value ? 'asc' : 'desc';
          break;
        default:
          break;
      }
    }

    dispatch('getBusinessPlanList', {
      type: data.type,
      title: state.search,
      isActive: state.isActiveFilter,
      businessPlanPractices: data.type === 'cabinet' ? cabinetsMap : [],
      businessPlanDevices: data.type === 'appareil' ? devicesMap : [],
      order: data.order || { updatedAt: 'desc' }
    });
  },

  initBusinessPlanList({ dispatch, state, rootState }, data) {
    const { cabinets } = rootState.office;
    const { appareils } = rootState.device;

    const cabinetsMap = cabinets.length > 0 ? cabinets.map(el => el.id) : [];
    const devicesMap = appareils.length > 0 ? appareils.map(el => el.id) : [];

    dispatch('getBusinessPlanList', {
      type: data.type,
      title: state.search,
      isActive: state.isActiveFilter,
      businessPlanPractices: data.type === 'cabinet' ? cabinetsMap : [],
      businessPlanDevices: data.type === 'appareil' ? devicesMap : [],
      order: {
        updatedAt: 'desc'
      }
    });
  },
  getAppareilStatsByID({ commit, dispatch }, data) {
    dispatch('app/updateLoading', true, { root: true });

    return businessPlanService.getAppareilStats(data)
      .then(res => {
        dispatch('app/updateLoading', false, { root: true });

        return res.data;
      })
      .catch(error => {
        dispatch('app/updateLoading', false, { root: true });

        console.error(error);
        return false;
      });
  },
  getCabinetStatsByID({ commit }, data) {
    commit('app/UPDATE_LOADING', true, { root: true });

    return businessPlanService.getCabinetStats(data)
      .then(res => {
        commit('app/UPDATE_LOADING', false, { root: true });

        return res.data;
      })
      .catch(error => {
        commit('app/UPDATE_LOADING', false, { root: true });

        console.error(error);
        return false;
      });
  },
  getBusinessPlanList({ commit, dispatch }, data) {
    dispatch('app/updateLoading', true, { root: true });

    return businessPlanService.getAllBusinessPlan(data)
      .then(res => {
        const items = res.data;
        const formatedList = items['hydra:member'].map(el => {
          return {
            id: el.id,
            isActive: el.isActive,
            title: el.title,
            devices: el.businessPlanDevices?.map(item => item.device.libelle),
            offices: el.businessPlanPractices?.map(item => item.practice.labelCabinet),
            period: {
              start: el.startDate,
              end: el.endDate
            },
            comment: el.comment,
            createdAt: el.createdAt,
            startDate: el.startDate,
            updatedAt: el.updatedAt
          };
        });
        commit('UPDATE_BUSINESS_PLAN_LIST', formatedList);

        dispatch('app/updateLoading', false, { root: true });

        return items;
      })
      .catch(error => {
        dispatch('app/updateLoading', false, { root: true });
        console.error(error);
      });
  },
  updateBusinessPlan({ commit, dispatch }, data) {
    dispatch('app/updateLoading', true, { root: true });

    const that = this._vm;
    return businessPlanService.updateBusinessPlan(data.id, data.data)
      .then(res => {
        dispatch('app/updateLoading', false, { root: true });

        commit('SET_BUSINESS_PLAN', res.data);
      }).catch(error => {
        dispatch('app/updateLoading', false, { root: true });
        console.error(error);
        that.$message.error('Une erreur est survenue lors de la modification du business plan, veuillez réesayer');
        return null;
      });
  },
  updateBusinessPlanVersion({ commit, state, dispatch }, item) {
    dispatch('app/updateLoading', true, { root: true });

    const that = this._vm;
    const { businessPlan } = state;

    const devices = item.businessPlanVersionDevices.map((el, index) => {
      return {
        nbExams: item.businessPlanVersionDevices[index].nbExams,
        nbHoursOpeningPeriod: item.businessPlanVersionDevices[index].nbHoursOpeningPeriod,
        businessPlanDevice: item.businessPlanVersionDevices[index].businessPlanDevice.id,
        exceedingRate: item.businessPlanVersionDevices[index].exceedingRate,
        nbExamsOpeningPeriod: item.businessPlanVersionDevices[index].nbExamsOpeningPeriod,
        monthlyRentalEquipment: el.monthlyRentalEquipment,
        monthlyEquipmentMaintenance: el.monthlyEquipmentMaintenance,
        qualityControl: el.qualityControl,
        hourlyCostEtpRadiologistFees: el.hourlyCostEtpRadiologistFees,
        hourlyCostEtpSubstituteFees: el.hourlyCostEtpSubstituteFees,
        hourlyCostEtpHandlingType1: el.hourlyCostEtpHandlingType1,
        hourlyCostEtpHandlingType2: el.hourlyCostEtpHandlingType2,
        hourlyCostEtpSecretaryFees: el.hourlyCostEtpSecretaryFees,
        hourlyCostEtpSecretaryFees2: el.hourlyCostEtpSecretaryFees2,
        nbEtpRadiologists: el.nbEtpRadiologists,
        nbEtpSubstitutes: el.nbEtpSubstitutes,
        nbEtpHandlingType1: el.nbEtpHandlingType1,
        nbEtpHandlingType2: el.nbEtpHandlingType2,
        nbEtpSecretary: el.nbEtpSecretary,
        nbEtpSecretary2: el.nbEtpSecretary2
      };
    });

    const practices = item.businessPlanVersionPractices.map((el, index) => {
      return {
        nbExams: item.businessPlanVersionPractices[index].nbExams,
        nbHoursOpeningPeriod: item.businessPlanVersionPractices[index].nbHoursOpeningPeriod,
        businessPlanPractice: item.businessPlanVersionPractices[index].businessPlanPractice.id,
        exceedingRate: item.businessPlanVersionPractices[index].exceedingRate,
        nbExamsOpeningPeriod: item.businessPlanVersionPractices[index].nbExamsOpeningPeriod,
        monthlyRentalEquipment: el.monthlyRentalEquipment,
        monthlyEquipmentMaintenance: el.monthlyEquipmentMaintenance,
        qualityControl: el.qualityControl,
        hourlyCostEtpRadiologistFees: el.hourlyCostEtpRadiologistFees,
        hourlyCostEtpSubstituteFees: el.hourlyCostEtpSubstituteFees,
        hourlyCostEtpHandlingType1: el.hourlyCostEtpHandlingType1,
        hourlyCostEtpHandlingType2: el.hourlyCostEtpHandlingType2,
        hourlyCostEtpSecretaryFees: el.hourlyCostEtpSecretaryFees,
        hourlyCostEtpSecretaryFees2: el.hourlyCostEtpSecretaryFees2,
        nbEtpRadiologists: el.nbEtpRadiologists,
        nbEtpSubstitutes: el.nbEtpSubstitutes,
        nbEtpHandlingType1: el.nbEtpHandlingType1,
        nbEtpHandlingType2: el.nbEtpHandlingType2,
        nbEtpSecretary: el.nbEtpSecretary,
        nbEtpSecretary2: el.nbEtpSecretary2
      };
    });

    return businessPlanService.updateBusinessPlanVersion(item.id, {
      title: item.title,
      medicalPurchases: item.medicalPurchases,
      otherMaterialCharges: item.otherMaterialCharges,
      computing: item.computing,
      subContracting: item.subContracting,
      energy: item.energy,
      purchasesAndExternalServices: item.purchasesAndExternalServices,
      purchaseTaxesAndFees: item.purchaseTaxesAndFees,
      realEstateRental: item.realEstateRental,
      insurance: item.insurance,
      otherExpenses: item.otherExpenses,
      isRealData: item.isRealData,
      businessPlan: businessPlan.id,
      businessPlanVersionDevices: devices,
      businessPlanVersionPractices: practices
    })
      .then(res => {
        const index = businessPlan.businessPlanVersions.findIndex(el => el.id === res.data.id);
        const cloneBP = cloneDeep(businessPlan);

        cloneBP.businessPlanVersions[index] = res.data;

        commit('SET_BUSINESS_PLAN', {
          id: cloneBP.id,
          title: cloneBP.title,
          businessPlanDevices: cloneBP.businessPlanDevices,
          businessPlanPractices: cloneBP.businessPlanPractices,
          businessPlanVersions: cloneBP.businessPlanVersions,
          installation: isNumber(cloneBP.installation) ? cloneBP.installation : parseInt(cloneBP.installation.split('/').pop()),
          startDate: cloneBP.startDate,
          endDate: cloneBP.endDate,
          isActive: cloneBP.isActive
        });

        dispatch('app/updateLoading', false, { root: true });
      })
      .catch((error) => {
        dispatch('app/updateLoading', false, { root: true });

        console.error(error);
        that.$message.error('Une erreur est survenue lors de la création du business plan, veuillez réessayer');
        return null;
      });
  },
  addBusinessPlan({ commit }, item) {
    commit('app/UPDATE_LOADING', true, { root: true });

    const that = this._vm;

    return businessPlanService.addBusinessPlan(item)
      .then(res => {
        commit('SET_BUSINESS_PLAN', res.data);
        return res.data;
      })
      .catch((error) => {
        console.error(error);
        commit('app/UPDATE_LOADING', false, { root: true });

        that.$message.error('Une erreur est survenue lors de la création du business plan, veuillez réessayer');
        return null;
      });
  },

  async addBusinessPlanVersion({ commit, state, dispatch }, item) {
    const that = this._vm;
    const { businessPlan } = state;

    dispatch('app/updateLoading', true, { root: true });

    return businessPlanService.addBusinessPlanVersion(item)
      .then(res => {
        commit('app/UPDATE_LOADING', false, { root: true });

        if (item.updateSimulations) {
          commit('SET_BUSINESS_PLAN', { ...businessPlan, businessPlanVersions: [...businessPlan.businessPlanVersions, res.data] });
        }
        return res.data;
      })
      .catch((error) => {
        const { businessPlan, businessPlanList } = state;
        commit('app/UPDATE_LOADING', false, { root: true });
        commit('UPDATE_BUSINESS_PLAN_LIST', businessPlanList.filter(el => el.id !== businessPlan.id));

        that.$message.error('Une erreur est survenue lors de l\'ajout d\'une simulation, veuillez réessayer');

        if (!item.simulationRoute) {
          businessPlanService.deleteBusinessPlan(businessPlan.id);
        }
      });
  },

  getBusinessPlanByID({ commit, dispatch }, id) {
    dispatch('app/updateLoading', true, { root: true });

    const that = this._vm;

    return businessPlanService.getBusinessPlan(id)
      .then(res => {
        const item = res.data;
        commit('SET_BUSINESS_PLAN', {
          id: item.id,
          title: item.title,
          businessPlanDevices: item.businessPlanDevices,
          businessPlanPractices: item.businessPlanPractices,
          businessPlanVersions: item.businessPlanVersions,
          installation: isNumber(item.installation) ? item.installation : parseInt(item.installation.split('/').pop()),
          type: item.type,
          startDate: item.startDate,
          endDate: item.endDate,
          isActive: item.isActive,
          comment: item.comment
        });

        dispatch('app/updateLoading', false, { root: true });

        return item;
      })
      .catch((error) => {
        dispatch('app/updateLoading', false, { root: true });

        console.error(error);
        const { status } = error.response;
        if (status === 401) {
          return that.$message.error('Vous n\'avez pas le droit de consulter ce business plan');
        }
        if (status === 404) {
          return that.$message.error('Le business plan n\'existe pas');
        }
      });
  },
  deleteBusinessPlan({ commit, state, dispatch }, id) {
    dispatch('app/updateLoading', true, { root: true });

    const that = this._vm;

    return businessPlanService.deleteBusinessPlan(id)
      .then(() => {
        const { businessPlanList } = state;

        dispatch('app/updateLoading', false, { root: true });
        commit('UPDATE_BUSINESS_PLAN_LIST', businessPlanList.filter(el => el.id !== id));

        that.$message.success('Le business plan a bien été supprimé');
      })
      .catch((error) => {
        dispatch('app/updateLoading', false, { root: true });

        const { status } = error.response;
        if (status === 401) {
          return that.$message.error('Vous n\'avez pas le droit de faire cette action');
        }
        if (status === 404) {
          return that.$message.error('Le business plan n\'existe pas');
        }
      });
  },
  deleteBusinessPlanVersions({ commit, state, dispatch }, id) {
    dispatch('app/updateLoading', true, { root: true });

    const that = this._vm;
    const { businessPlan } = state;

    return businessPlanService.deleteBusinessPlanVersions(id)
      .then(() => {
        that.$message.success('La simulation a bien été supprimée !');

        commit('SET_BUSINESS_PLAN', { ...businessPlan, businessPlanVersions: businessPlan.businessPlanVersions.filter(el => el.id !== id) });
        dispatch('app/updateLoading', false, { root: true });
      })
      .catch((error) => {
        dispatch('app/updateLoading', false, { root: true });
        console.error(error);
        const { status } = error.response;

        if (status === 401) {
          return that.$message.error('Vous n\'avez pas le droit de faire cette action');
        }
        if (status === 404) {
          return that.$message.error('Le business plan n\'existe pas');
        }
      });
  },
  onFiltersChanged({
    dispatch, rootGetters, rootState, commit
  }, values) {
    const { newVal, oldVal } = values;
    const { cabinets } = rootState.office;
    const idInstallationHasChanged = newVal.installations !== oldVal.installations;
    const idCabinetHasChanged = newVal.cabinet !== oldVal.cabinet;
    const idModalitiesHasChanged = newVal.modalities !== oldVal.modalities;

    dispatch('filter/updateFieldsLoading', true, { root: true });

    const promises = [];
    if (idInstallationHasChanged) {
      const ids = rootGetters['installation/AllIdsInstallations'];

      promises.push(dispatch('office/getCabinet', ids, { root: true }));
      if (!cabinets.length) {
        promises.push(dispatch('device/getOptionsAppareils', null, { root: true }));
      }
      promises.push(dispatch('modality/updateSelectedModalites', [], { root: true }));
    }

    if (idCabinetHasChanged) {
      promises.push(dispatch('modality/updateSelectedModalites', [], { root: true }));
      promises.push(dispatch('device/filterDevicesByOfficeIds', null, { root: true }));
    }

    if (idModalitiesHasChanged) {
      promises.push(dispatch('device/filterDevicesByModalitiesIds', null, { root: true }));
    }

    return Promise.allSettled(promises)
      .then(() => {
        dispatch('filter/updateFieldsLoading', false, { root: true });

        return true;
      });
  },
  toggleFilterIsOpen({ commit }, bool) {
    commit('UPDATE_FILTER_IS_OPEN', bool);
  },
  toggleDisableStudy({ commit, state }) {
    commit('TOGGLE_DISABLE_STUDY', !state.isActiveFilter);
  },
  updateSearch({ commit }, search) {
    commit('UPDATE_SEARCH', search);
  },
  updateFieldsLoading({ commit }, bool) {
    commit('UPDATE_LOADING', bool);
  },
  resetFilters({ commit, dispatch, rootState }) {
    if (rootState.installation.options_installations.length > 1) {
      commit('installation/UPDATE_INSTALLATIONS', [], { root: true });
    }
    commit('UPDATE_SEARCH', '');
    commit('TOGGLE_DISABLE_STUDY', true);
    commit('modality/UPDATE_SELECTED_MODALITIES', [], { root: true });
    commit('device/UPDATE_ERROR', null, { root: true });
    commit('device/UPDATE_APPAREILS', [], { root: true });
    dispatch('office/updateCabinets', [], { root: true });
  }
};

const getters = {
  businessPlanSimulations: (state) => state.businessPlan.businessPlanVersions,
  businessPlanVersionsLimit: (state) => state.businessPlan.businessPlanVersions.length === 3,
  businessPlanVersionReal: (state) => state.businessPlan?.businessPlanVersions?.find(el => el.isRealData),
  businessPlanStartDate: (state) => moment(state.businessPlan.startDate).format(CONSTANTS.DATE_FORMAT.MOMENT.SHOW_IT),
  businessPlanEndDate: (state) => moment(state.businessPlan.endDate).format(CONSTANTS.DATE_FORMAT.MOMENT.SHOW_IT),
  businessPlanDateDiff: (state) => {
    const a = moment(state.businessPlan.endDate);
    const b = moment(state.businessPlan.startDate);

    if (a.diff(b, 'months') === 0) {
      return '(mois)';
    }
    if (a.diff(b, 'months') > 0 && a.diff(b, 'months') < 3) {
      return '(trimestre)';
    }
    if (a.diff(b, 'months') > 3 && a.diff(b, 'months') < 6) {
      return '(semestre)';
    }
    if (a.diff(b, 'months') > 6) {
      return '(année)';
    }
  },
  businessPlanDevicesOrOfficesList: (state) => {
    const list = state.businessPlan?.businessPlanDevices?.length > 0 ? state.businessPlan?.businessPlanDevices : state.businessPlan?.businessPlanPractices;

    if (list) {
      return list.map(el => {
        if (el.device) {
          return el.device.libelle;
        }
        return el.practice.labelCabinet;
      });
    }

    return [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
