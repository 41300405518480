import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/business-plan',
    component: BaseLayout,
    name: 'business_plan',
    meta: {
      authRequired: true, title: 'Business Plan', icon: 'fas fa-chart-line', css_class: 'financier'
    },
    children: [
      {
        path: 'par_appareil',
        component: () => import('@/views/BusinessPlan/Devices/BPDevice'),
        name: 'business_plan/par_appareil',
        meta: {
          authRequired: true, role: 'ROLE_BP_READ', title: 'Par appareil', activityManagement: true, cubeTitle: 'Business Plan par appareil', icon: 'mri'
        }
      },
      {
        path: 'par_cabinet',
        component: () => import('@/views/BusinessPlan/Offices/BPOffice'),
        name: 'business_plan/par_cabinet',
        meta: {
          authRequired: true, role: 'ROLE_BP_READ', title: 'Par cabinet', activityManagement: true, cubeTitle: 'Business Plan par cabinet', icon: 'clinic-medical-solid'
        }
      },
      {
        path: 'simulation/:id',
        hidden: true,
        component: () => import('@/views/BusinessPlan/Simulations/BPSimulation'),
        name: 'business_plan/simulation',
        meta: { authRequired: true }
      }
    ]
  }
];
