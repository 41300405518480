import BaseLayout from '@/layout/BaseLayout';
import { getStore } from '@/util/storage';

export default [
  {
    path: '/feedback',
    component: BaseLayout,
    name: 'feedback',
    meta: {
      title: 'Feedback', icon: 'fas fa-smile', css_class: 'financier'
    },
    children: [
      {
        path: 'correspondant',
        component: () => import('@/views/CES/CESSynthesisBase'),
        meta: {
          authRequired: true, title: 'Feedback correspondants', icon: 'fas fa-smile', css_class: 'financier'
        },
        children: [
          {
            path: 'satisfcorr_liste_campagne_satisf',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=satisfaction_correspondants/satisfcorr_liste_campagne_satisf.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_FEEDBACK_CORRESPONDANT_CAMPAIGN_READ', title: 'Dashboard campagnes', icon: 'fas fa-smile', css_class: 'financier'
            }
          },
          {
            path: 'satisfcorr_stats',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=satisfcorr_stats.php`;
            },
            name: 'satisfcorr_stats',
            meta: {
              authRequired: true, role: 'ROLE_FEEDBACK_CORRESPONDANT_STATS_READ', title: 'Résultats', icon: 'fas fa-poll-h', css_class: 'financier'
            }
          },
          {
            path: 'satisfcorr_list_questionnaires',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=satisfaction_correspondants/satisfcorr_list_questionnaires.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_FEEDBACK_CORRESPONDANT_SURVEY_READ', title: 'Questionnaires', icon: 'fas fa-clipboard-list', css_class: 'financier'
            }
          },
          {
            path: 'satisfcorr_list_model_mail',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=satisfaction_correspondants/satisfcorr_list_model_mail.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_FEEDBACK_CORRESPONDANT_RESULTS_READ', title: 'Résultats', icon: 'fas fa-poll-h', css_class: 'financier'
            }
          }
        ]
      },
      {
        path: 'stats_satisfaction',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=stats_satisfaction.php`;
        },
        name: 'stats_satisfaction',
        meta: {
          authRequired: true, role: 'ROLE_FEEDBACK_PATIENT_READ', activityManagement: true, title: 'Feedback patients', icon: 'fas fa-smile', css_class: 'financier'
        }
      }
    ]
  }
];
