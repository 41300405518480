import contractService from '@/service/contractService';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const state = {
  contrats: [],
  contrat_types: undefined
};

const mutations = {
  UPDATE_CONTRATS: (state, contrats) => {
    state.contrats = contrats;
  },
  UPDATE_CONTRAT_TYPES: (state, types) => {
    state.contrat_types = types;
  }
};

const actions = {
  getContrat({ dispatch }, id) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return contractService.getContrat(id)
      .catch(() => {
        that.$message.error('Erreur lors de la recuperation des types de contrat');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  getContratByMaterialId({ commit, dispatch }, { materialId, sort = undefined }) {
    const that = this._vm;
    updateAppLoading(dispatch, true);

    return contractService.getContratByMaterialId(materialId, sort)
      .then(contrats => {
        commit('UPDATE_CONTRATS', contrats);
        return contrats;
      }).catch(() => {
        that.$message.error('Erreur lors de la recuperation des types de contrat');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  getContratTypes({ commit, dispatch, state }) {
    const that = this._vm;
    if (state.contrat_types) {
      return Promise.resolve(state.contrat_types);
    }
    updateAppLoading(dispatch, true);
    return contractService.getContratTypes()
      .then(types => commit('UPDATE_CONTRAT_TYPES', types))
      .catch(() => {
        that.$message.error('Erreur lors de la recuperation des types de contrat');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  createContrat({ dispatch }, contrat) {
    if (contrat.id) {
      return dispatch('updateContrat', contrat);
    }
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return contractService.createContrat(contrat)
      .then(async newContrat => {
        await dispatch('rent/applyTmpRents', newContrat.id, { root: true });
        that.$notify({
          title: 'Contrat créé',
          message: 'Votre contrat a bien été ajouté',
          type: 'success'
        });
        return newContrat.id;
      }).catch(() => {
        that.$message.error('Erreur lors de la création du contrat');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  updateContrat({ dispatch }, contrat) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return contractService.updateContrat(contrat)
      .then(updatedContrat => {
        that.$notify({
          title: 'Contrat modifié',
          message: 'Votre contrat a bien été modifié',
          type: 'success'
        });
        return updatedContrat.id;
      }).catch(() => {
        that.$message.error('Erreur lors de la modification du contrat');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  getContratDocuments({ dispatch }, emlId) {
    updateAppLoading(dispatch, true);
    return contractService.getContratDocuments(emlId)
      .finally(() => {
        updateAppLoading(dispatch, false);
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
