import DataAPI from '../lib/axios';

export default {
  getMapData(installationId, officeId, building, floor, room) {
    return DataAPI.get('api/cmm/map_material/data', {
      params: {
        installationId,
        officeId,
        building,
        floor,
        room
      }
    }).then(response => response.data);
  },
  mapFileExists(installationId, officeId, building, floor) {
    return DataAPI.get('/api/cmm/map_material/file_exists', {
      params: {
        installationId,
        officeId,
        building,
        floor
      }
    }).then(response => response.data);
  },
  uploadMap(mapFormData) {
    return DataAPI.post('/api/cmm/map_material/upload', mapFormData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
