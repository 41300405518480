import { accorization } from '@/util/util';
import CONSTANTS from '@/util/constant/constantApp';
import DataAPI from '../lib/axios';

const baseUrl = '/cmm/files/';

function getParentEntity(type) {
  return CONSTANTS.CMM.CMM_ROUTE_KEY_FOR_TYPE_DOCUMENTS[type] || type;
}

function generateFormData(file, typeId, targetId) {
  const formData = new FormData();
  formData.append('name', file.name);
  file.describe = typeof file.describe === 'undefined' ? '' : file.describe;
  formData.append('describe', file.describe);
  if (typeId) formData.append('type', typeId);
  formData.append('target_id', targetId);
  formData.append('file[]', file.file?.raw);

  return formData;
}

export default {
  uploadDocuments(files, type, typeId, targetId) {
    if (!files || !type || files.length === 0) {
      return Promise.reject('noData to upload');
    }

    const promises = [];
    const filesPushed = [];
    const options = { headers: { 'Content-Type': 'multipart/form-data' } };
    files.forEach(file => {
      if (!file.added) {
        const formData = generateFormData(file, typeId, targetId);
        promises.push(DataAPI.post(`${baseUrl + getParentEntity(type)}`, formData, options));
        filesPushed.push(file);
      }
    });

    return Promise.allSettled(promises)
      .then(responses => {
        filesPushed.forEach((fp, index) => {
          fp.added = responses[index].status === 'fulfilled';
          fp.error = responses[index].status !== 'fulfilled';
        });
        files.forEach(file => {
          const findingFP = filesPushed.find(fp => fp.file?.uid === file.file?.uid);
          if (findingFP) {
            file.added = findingFP.added;
            file.error = findingFP.error;
          }
        });
        const doneFiles = files.filter(file => file.added);
        const errorFiles = files.filter(file => file.error);
        return {
          success: {
            files: doneFiles,
            message: `${accorization(doneFiles.length, 'fichier', true, 'aucun fichier')} sur ${responses.length} ont été enregistrés`
          },
          errors: {
            files: errorFiles,
            message: errorFiles.length ? `${accorization(errorFiles.length, 'fichier', true, 'aucun fichier')} sur ${responses.length} n'ont pas été enregistrés` : null
          }
        };
      });
  },
  uploadDocument(file, type, typeId, targetId) {
    const options = { headers: { 'Content-Type': 'multipart/form-data' } };
    if (!file || !type) {
      return Promise.reject('noData to upload');
    }
    if (file.added) {
      return Promise.reject('document already added');
    }
    const formData = generateFormData(file, typeId, targetId);
    const url = `${baseUrl + getParentEntity(type)}`;

    return DataAPI.post(url, formData, options)
      .then(res => res.data);
  },
  deleteDocumentById(fileId, type) {
    if (!fileId) {
      return Promise.resolve({});
    }

    return DataAPI.delete(`${baseUrl + getParentEntity(type)}/${fileId}`)
      .then(res => res.data);
  },
  getDocument(fileId, type) {
    return DataAPI.get(`${baseUrl + getParentEntity(type)}/${fileId}`, { responseType: 'blob' })
      .then(res => {
        const { data } = res;
        return {
          url: URL.createObjectURL(data),
          type: data.type
        };
      });
  },
  getMaterialDocuments(materialId) {
    return DataAPI.get('/api/contrat_document_materiels', {
      params: {
        materiel: materialId
      }
    }).then(res => res.data);
  },
  getContratFilesByMaterial(materialId) {
    return DataAPI.get('/api/contrat_document_contrats', {
      params: {
        'contrat.materiel': materialId
      }
    }).then(res => res.data);
  },
  getEmlFilesByMaterial(materialId) {
    return DataAPI.get('/api/contrat_eml_has_docs', {
      params: {
        'eml.materiel': materialId
      }
    }).then(res => res.data);
  },
  getMaintenanceFilesByMaterial(materialId) {
    return DataAPI.get('/api/contrat_maintenance_documents', {
      params: {
        'maintenance.materiel': materialId
      }
    }).then(res => res.data);
  },
  getIncidentFilesByMaterial(materialId) {
    return DataAPI.get('/api/contrat_incident_documents', {
      params: {
        'incident.materiel': materialId
      }
    }).then(res => res?.data);
  },
  getQualityControlFilesByMaterial(materialId) {
    return DataAPI.get('/api/contrat_c_q_documents', {
      params: {
        'cq.materiel': materialId
      }
    }).then(res => res.data);
  },
  getRadioProtectionControlFilesByMaterial(materialId) {
    return DataAPI.get('/api/contrat_r_p_documents', {
      params: {
        'crp.materiel': materialId
      }
    }).then(res => res.data);
  }
};
