<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const {
      icon,
      title
    } = context.props;
    const vnodes = [];

    if (icon) {
      if (icon.includes('fa')) {
        vnodes.push(<i class={[icon, 'sub-fa-icon']} />);
      } else {
        vnodes.push(<svg-icon icon-class={icon}/>);
      }
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>);
    }
    return vnodes;
  }
};
</script>
<style scoped>
  .sub-fa-icon {
    color: #000000;
    margin-right: 16px;
    font-size: 14px;
    width: 14px;
    height: 14px;
  }
</style>
