import { Message } from 'element-ui';

const showMessage = Symbol('showMessage');

/**
 * Override message for elementui to display one by one
 */
class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single);
  }

  warning(options, single = true) {
    this[showMessage]('warning', options, single);
  }

  info(options, single = true) {
    this[showMessage]('info', options, single);
  }

  error(options, single = true) {
    this[showMessage]('error', options, single);
  }

  [showMessage](type, options, single) {
    if (single) {
      // Determine whether message already exists
      if (document.getElementsByClassName('el-message').length === 0) {
        Message[type](options);
      }
    } else {
      Message[type](options);
    }
  }
}

export default new DonMessage();
