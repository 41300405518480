import isEqual from 'lodash.isequal';
import moment from 'moment';
import statService from '@/service/statService';
import comparisonService from '@/service/comparisonService';
import objectiveService from '@/service/objectiveService';
import {
  routeContains, generateRandomIndex, getDuplicate, getWorkdayCount, getGranularityCount, extractConstTabValue, isObject, removeUnusePropertyFromFilter, removeUnusePropertyFromFilterGroupeCabinet
} from '@/util/util';
import CONSTANTS from '@/util/constant/constantApp';
import { getStore, setStore } from '@/util/storage';
import router from '@/router/router';
import PrevisionnelService from '@/service/previsionalService';

const {
  GLOBAL, SOCIETE, CABINET, MEDECIN, APPAREIL
} = CONSTANTS.CES.TAB;

const REGISTER_FILTERS = 'REGISTER_FILTERS';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const state = {
  include_sat: false,
  include_sun: false,
  date_debut: moment().subtract(1, 'months').format(CONSTANTS.DATE_FORMAT.MOMENT.VALUE),
  date_fin: moment().format(CONSTANTS.DATE_FORMAT.MOMENT.VALUE),
  echelle: 'jour',
  societe: [],
  medecin: [],
  appareil: [],
  options_societes: [],
  options_medecins: [],
  options_appareils: [],
  options_echelles: [
    {
      label: 'Jour',
      value: 'jour'
    },
    {
      label: 'Semaine',
      value: 'semaine'
    },
    {
      label: 'Mois',
      value: 'mois'
    },
    {
      label: 'Année',
      value: 'annee'
    }
  ],
  data: {},
  graph: {},
  table: {},
  fieldsLoading: false,
  weeks: [1],
  daysWeek: [1, 2, 3, 4, 5, 6, 7],
  year: moment().year(),
  displayType: {
    isCumul: true,
    target: null
  },
  consolidateSubstitutes: false,
  type_appareil: [],
  workday_count: 0,
  week_count: 0,
  month_count: 0,
  year_count: 0,
  registerFilters: null,
  old_filter: {},
  filterIsOpen: false,
  hours: [8, 18]
};

const mutations = {
  UPDATE_SOCIETE: (state, societe) => {
    state.societe = societe;
  },
  UPDATE_MEDECIN: (state, medecin) => {
    state.medecin = medecin;
  },
  UPDATE_APPAREIL: (state, appareil) => {
    state.appareil = appareil;
  },
  UPDATE_OPTIONS_SOCIETE: (state, societes) => {
    state.options_societes = societes;
  },
  UPDATE_OPTIONS_MEDECIN: (state, medecins) => {
    state.options_medecins = medecins;
  },
  UPDATE_OPTIONS_APPAREIL: (state, appareils) => {
    state.options_appareils = appareils;
  },
  UPDATE_DATE_DEBUT: (state, value) => {
    if (isObject(value)) {
      value = moment(value).format(CONSTANTS.DATE_FORMAT.MOMENT.VALUE);
    }
    state.date_debut = value;
  },
  UPDATE_DATE_FIN: (state, value) => {
    if (isObject(value)) {
      value = moment(value).format(CONSTANTS.DATE_FORMAT.MOMENT.VALUE);
    }
    state.date_fin = value;
  },
  UPDATE_WORKDAY_COUNT: (state, workday_count) => {
    state.workday_count = workday_count;
  },
  UPDATE_WEEK_COUNT: (state, week_count) => {
    state.week_count = week_count;
  },
  UPDATE_MONTH_COUNT: (state, month_count) => {
    state.month_count = month_count;
  },
  UPDATE_YEAR_COUNT: (state, year_count) => {
    state.year_count = year_count;
  },
  UPDATE_WEEKS: (state, value) => {
    state.weeks = value;
  },
  UPDATE_DAYS_WEEK: (state, value) => {
    state.daysWeek = value;
  },
  UPDATE_YEAR: (state, value) => {
    state.year = value;
  },
  UPDATE_ECHELLE_TEMPS: (state, value) => {
    state.echelle = value;
  },
  UPDATE_OPTIONS_ECHELLES: (state, values) => {
    const { maxDate, minDate } = values;
    const diff = moment(maxDate).diff(moment(minDate), 'months', true);

    const jour = state.options_echelles.find(item => item.value === 'jour');
    const semaine = state.options_echelles.find(item => item.value === 'semaine');
    const mois = state.options_echelles.find(item => item.value === 'mois');
    const annee = state.options_echelles.find(item => item.value === 'annee');

    jour.disabled = diff > 1;
    semaine.disabled = diff > 6 || diff < 0.19;
    mois.disabled = diff > 24 || diff < 1;
    annee.disabled = diff < 11;
    state.options_echelles = [jour, semaine, mois, annee];

    if (diff > 1 && state.echelle === jour.value) {
      state.echelle = semaine.value;
    }
    if (diff > 6 && state.echelle === semaine.value) {
      state.echelle = mois.value;
    }
    if (diff > 24 && state.echelle === mois.value) {
      state.echelle = annee.value;
    }
    if (diff < 11 && state.echelle === annee.value) {
      state.echelle = mois.value;
    }
    if (diff < 1 && state.echelle === mois.value) {
      state.echelle = semaine.value;
    }
    if (diff < 0.19 && state.echelle === semaine.value) {
      state.echelle = jour.value;
    }
  },
  UPDATE_DISPLAY_TYPE: (state, datas) => {
    const { isCumul, target } = datas || {};
    const { isCumul: oldIsCumul } = state.displayType;
    const possiblesTarget = [GLOBAL.NAME, SOCIETE.NAME, CABINET.NAME, MEDECIN.NAME, APPAREIL.NAME];
    const validateTarget = possiblesTarget.find(t => t === target);
    let autoTarget = possiblesTarget.find(t => router.currentRoute.path.includes(t));
    if (!autoTarget) {
      const route = decodeURIComponent(window.location.href);
      autoTarget = possiblesTarget.find(t => route.includes(t));
    }

    let newCumul = isCumul !== undefined ? isCumul : oldIsCumul;
    if (!newCumul && newCumul !== false) {
      newCumul = true;
    }
    state.displayType = {
      isCumul: newCumul,
      target: validateTarget || autoTarget
    };
  },
  UPDATE_CONSOLIDATE_SUBSTITUTES: (state, value) => {
    state.consolidateSubstitutes = value;
  },
  UPDATE_TYPE_APPAREIL: (state, value) => {
    state.type_appareil = value;
  },
  UPDATE_INCLUDE_SAT: (state, include_sat) => {
    state.include_sat = include_sat;
  },
  UPDATE_INCLUDE_SUN: (state, include_sun) => {
    state.include_sun = include_sun;
  },
  UPDATE_DATA: (state, data) => {
    state.data = isObject(data) ? data : {};
  },
  UPDATE_GRAPH: (state, graph) => {
    state.graph = isObject(graph) ? graph : {};
  },
  UPDATE_TABLE: (state, table) => {
    state.table = isObject(table) ? table : {};
  },
  UPDATE_FIELDS_LOADING: (state, fieldsLoading) => {
    state.fieldsLoading = fieldsLoading;
  },
  ADD_REGISTER_FILTERS: (state, filterToRegister) => {
    const {
      filterName, filterInstallation, filterCabinets, filterGroupeCabinets
    } = filterToRegister;
    state.registerFilters = state.registerFilters || getStore(REGISTER_FILTERS) || [];
    state.registerFilters.push({
      id: generateRandomIndex(10),
      name: filterName,
      currentRoutePath: router.currentRoute.path,
      filters: {
        id_installation: filterInstallation?.id_installation,
        installation: filterInstallation,
        societe: state.societe,
        groupe_cabinet: filterGroupeCabinets,
        cabinet: filterCabinets,
        medecin: state.medecin,
        consolidateSubstitutes: state.consolidateSubstitutes,
        type_appareil: state.type_appareil,
        appareil: state.appareil,
        echelle: state.echelle,
        include_sat: state.include_sat,
        include_sun: state.include_sun
      }
    });
    setStore(REGISTER_FILTERS, state.registerFilters);
  },
  REMOVE_REGISTER_FILTERS: (state, id) => {
    state.registerFilters = state.registerFilters || getStore(REGISTER_FILTERS) || [];
    state.registerFilters = state.registerFilters.filter(rFilter => rFilter.id !== id);
    setStore(REGISTER_FILTERS, state.registerFilters);
  },
  UPDATE_FILTER_IS_OPEN: (state, bool) => {
    state.filterIsOpen = bool;
  },
  UPDATE_HOURS: (state, hours) => {
    state.hours = hours;
  }
};

const actions = {
  getSociete({ commit, rootState, rootGetters }) {
    const { filter } = rootState;
    const id_inst = rootGetters['installation/initializationSingleInstallation'];

    return statService.getSocieteGetByInstallation({ id_inst })
      .then((response) => {
        const options_societe = response.data;
        commit('UPDATE_SOCIETE', getDuplicate(filter.societe, options_societe));
        commit('UPDATE_OPTIONS_SOCIETE', options_societe);
        return options_societe;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },
  getMedecin({
    commit, state, rootState, rootGetters
  }) {
    const { office } = rootState;
    const selectedInstallation = rootGetters['installation/initializationSingleInstallation'];
    return statService.getMedecinGetByInstallation({
      id_inst: selectedInstallation,
      societe: state.societe,
      cabinet: office.cabinets,
      date_debut: state.date_debut,
      date_fin: state.date_fin,
      include_sat: state.include_sat,
      include_sun: state.include_sun,
      consolidateSubstitutes: state.consolidateSubstitutes
    }).then((response) => {
      const options_medecins = response.data;
      commit('UPDATE_MEDECIN', getDuplicate(state.medecin, options_medecins));
      commit('UPDATE_OPTIONS_MEDECIN', options_medecins);
      return options_medecins;
    }).catch((error) => {
      console.error(error);
      return null;
    });
  },
  getAppareil({
    commit, state, rootState, rootGetters
  }) {
    const { office } = rootState;
    const selectedInstallation = rootGetters['installation/initializationSingleInstallation'];
    return statService.getAppareilGetByInstallation({
      id_inst: selectedInstallation,
      societe: state.societe,
      cabinet: office.cabinets,
      date_debut: state.date_debut,
      date_fin: state.date_fin,
      include_sat: state.include_sat,
      include_sun: state.include_sun,
      consolidateSubstitutes: state.consolidateSubstitutes
    }).then((response) => {
      const options_appareils = response.data;
      commit('UPDATE_APPAREIL', getDuplicate(state.appareil, options_appareils));
      commit('UPDATE_OPTIONS_APPAREIL', options_appareils);
      return options_appareils;
    }).catch((error) => {
      console.error(error);
      return null;
    });
  },
  getObjectifs({ dispatch }, data) {
    const { objectif, id, id_installation } = data;
    updateAppLoading(dispatch, true);
    return objectiveService.getObjectiveByType({ objectif, id, id_installation }, objectif)
      .then((response) => {
        return response.data;
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  async getDataCS({
    commit, state, dispatch, rootGetters, rootState
  }, datas) {
    commit('UPDATE_DATA', []);
    commit('UPDATE_GRAPH', []);
    commit('UPDATE_TABLE', []);
    const { tab, page } = datas;
    updateAppLoading(dispatch, true);
    commit('UPDATE_DISPLAY_TYPE');
    const filters = rootGetters['filterParams/getFilterCES'];
    const childTab = rootGetters['modality/currentTab'];
    if (tab === 'modalite') {
      await dispatch('modality/fetchModalityList', null, { root: true });
    }
    return statService.getCS(filters, tab, page, childTab)
      .then((response) => {
        commit('UPDATE_DATA', response.display);
        commit('UPDATE_GRAPH', response.graphs);
        commit('UPDATE_TABLE', response.tables);
        return response;
      })
      .catch((error) => {
        console.error('error', error);
        return Promise.reject(error);
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  async getGraphExamens({
    commit, state, dispatch, rootGetters
  }, codes) {
    const filters = rootGetters['filterParams/getFilterCES'];
    const payload = { codes, filters };
    return statService.getGraphExamens(payload)
      .then((response) => {
        const { data } = response;
        commit('UPDATE_GRAPH', data);
      })
      .catch((error) => {
        console.error(error);
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  async getCompareTotalExport({ rootGetters }) {
    const filters = rootGetters['filterParams/getFilterComparaison'];
    try {
      const response = await comparisonService.getTotalExport(filters);
      return response.data.total;
    } catch (e) {
      console.error(e);
    }
  },
  getGraphAndTableForComparaison({
    commit, state, dispatch, rootGetters, rootState
  }, tabCompare) {
    const { tabId, tabFamily } = tabCompare;
    updateAppLoading(dispatch, true);
    commit('UPDATE_DISPLAY_TYPE', { isCumul: state.displayType.isCumul });

    const filters = rootGetters['filterParams/getFilterComparaison'];
    comparisonService.getGraphAndTableForComparisonService(tabId, tabFamily, filters)
      .then((response) => {
        commit('UPDATE_DATA', response.data);
      })
      .catch((error) => {
        console.error(error);
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });

    this.filters = filters;
  },
  getPrevisionnel({ commit, dispatch, rootGetters }) {
    updateAppLoading(dispatch, true);

    const filters = rootGetters['filterParams/getFilterPrevisional'];
    PrevisionnelService.getPrevisionnel(filters)
      .then((response) => {
        commit('UPDATE_DATA', response.data);
      })
      .catch((error) => {
        console.error(error);
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });

    this.filters = filters;
  },
  updateSociete({ commit, state, dispatch }, societe) {
    societe = !Array.isArray(societe) ? [societe] : societe;
    dispatch('updateDisplayTypeIfNeeded', societe);

    commit('UPDATE_SOCIETE', societe);
  },
  updateMedecin({ commit, dispatch }, medecin) {
    medecin = !Array.isArray(medecin) ? [medecin] : medecin;
    dispatch('updateDisplayTypeIfNeeded', medecin);

    commit('UPDATE_MEDECIN', medecin);
  },
  updateModalityAppareil({ commit, dispatch, state }, type_appareil) {
    type_appareil = !Array.isArray(type_appareil) ? [type_appareil] : type_appareil;
    if (type_appareil.length > 0 && state.appareil.length > 0) {
      const appareil = state.appareil.filter(item => {
        return !!type_appareil.find(ta => ta.type_appareil === item.type_dicom);
      });
      dispatch('updateAppareil', appareil);
    }

    dispatch('updateTypeAppareil', type_appareil);
  },
  updateTypeAppareil({ commit, dispatch }, type_appareil) {
    commit('UPDATE_TYPE_APPAREIL', type_appareil);
  },
  updateAppareil({ commit, dispatch }, appareil) {
    appareil = !Array.isArray(appareil) ? [appareil] : appareil;
    dispatch('updateDisplayTypeIfNeeded', appareil);

    commit('UPDATE_APPAREIL', appareil);
  },
  updateActiveEchelleTemps({ commit }, echelle) {
    commit('UPDATE_ECHELLE_TEMPS', echelle);
  },
  updateConsolidateSubstitutes({ commit }, consolidateSubstitutes) {
    commit('UPDATE_CONSOLIDATE_SUBSTITUTES', consolidateSubstitutes);
  },
  updateDisplayType({ commit }, { isCumul, target }) {
    commit('UPDATE_DISPLAY_TYPE', { isCumul, target });
  },
  updateIncludeSat({ commit }, include_sat) {
    commit('UPDATE_INCLUDE_SAT', include_sat);
  },
  updateIncludeSun({ commit }, include_sun) {
    commit('UPDATE_INCLUDE_SUN', include_sun);
  },
  updateWeeks({ commit }, daysWeek) {
    commit('UPDATE_WEEKS', daysWeek);
  },
  updateDaysWeek({ commit }, daysWeek) {
    commit('UPDATE_DAYS_WEEK', daysWeek);
  },
  updateYear({ commit }, year) {
    commit('UPDATE_YEAR', year);
  },
  updateRange({ commit, dispatch }, dates) {
    const { minDate, maxDate } = dates;
    commit('UPDATE_DATE_DEBUT', minDate);
    commit('UPDATE_DATE_FIN', maxDate);

    dispatch('updateWorkdayCount');

    commit('UPDATE_OPTIONS_ECHELLES', { maxDate, minDate });
  },
  updateWorkdayCount({ commit, state }) {
    const workdayCount = getWorkdayCount(
      state.date_debut,
      state.date_fin,
      state.include_sat,
      state.include_sun
    );
    const weekCount = getGranularityCount(state.date_debut,
      state.date_fin, 'weeks');
    const monthCount = getGranularityCount(state.date_debut,
      state.date_fin, 'months');
    const yearCount = getGranularityCount(state.date_debut,
      state.date_fin, 'years');
    commit('UPDATE_WORKDAY_COUNT', workdayCount);
    commit('UPDATE_WEEK_COUNT', weekCount);
    commit('UPDATE_MONTH_COUNT', monthCount);
    commit('UPDATE_YEAR_COUNT', yearCount);
  },
  updateDisplayTypeIfNeeded({ state, dispatch }, datas) {
    if (datas.length === 1 && !state.displayType?.isCumul) {
      dispatch('updateDisplayType', { isCumul: true });
    }
  },
  loadFields({ dispatch }) {
    dispatch('updateFieldsLoading', true);

    const promises = [];
    promises.push(dispatch('codeExam/fetchCodeExams', null, { root: true }));
    if (routeContains([SOCIETE.NAME, CABINET.NAME, MEDECIN.NAME, APPAREIL.NAME])) {
      promises.push(dispatch('getSociete'));
    }
    if (routeContains([CABINET.NAME, MEDECIN.NAME, APPAREIL.NAME])) {
      promises.push(dispatch('office/getCabinet', null, { root: true }));
      promises.push(dispatch('office/getGroupeCabinet', null, { root: true }));
    }
    if (routeContains([MEDECIN.NAME, APPAREIL.NAME])) {
      promises.push(dispatch('getMedecin'));
    }
    if (routeContains([APPAREIL.NAME])) {
      promises.push(dispatch('getAppareil'));
    }

    Promise.allSettled(promises)
      .then(() => {
        dispatch('updateFieldsLoading', false);
      });
  },
  onFiltersChanged({ dispatch }, values) {
    const { newVal, oldVal } = values;
    const idInstallationHasChanged = newVal.id_installation !== oldVal.id_installation;
    const societeHasChanged = !isEqual(newVal.societe, oldVal.societe);
    const cabinetHasChanged = !isEqual(newVal.cabinet, oldVal.cabinet);
    const medecinHasChanged = !isEqual(newVal.medecin, oldVal.medecin);

    const includeSatHasChanged = !isEqual(newVal.include_sat, oldVal.include_sat);
    const includeSunHasChanged = !isEqual(newVal.include_sun, oldVal.include_sun);

    dispatch('updateFieldsLoading', true);

    const promises = [];
    if (idInstallationHasChanged) {
      promises.push(dispatch('codeExam/fetchCodeExams', null, { root: true }));
      if (routeContains([SOCIETE.NAME, CABINET.NAME, MEDECIN.NAME, APPAREIL.NAME])) {
        promises.push(dispatch('getSociete'));
      }
      if (routeContains([CABINET.NAME, MEDECIN.NAME, APPAREIL.NAME])) {
        promises.push(dispatch('office/getCabinet', newVal.id_installation, { root: true }));
        promises.push(dispatch('office/getGroupeCabinet', null, { root: true }));
      }
      if (routeContains([MEDECIN.NAME, APPAREIL.NAME])) {
        promises.push(dispatch('getMedecin'));
      }
      if (routeContains([APPAREIL.NAME])) {
        promises.push(dispatch('getAppareil'));
      }
    }
    if (societeHasChanged) {
      if (routeContains([MEDECIN.NAME, APPAREIL.NAME])) {
        promises.push(dispatch('getMedecin'));
      }
      if (routeContains([APPAREIL.NAME])) {
        promises.push(dispatch('getAppareil'));
      }
    }
    if (cabinetHasChanged || includeSatHasChanged || includeSunHasChanged) {
      if (routeContains([MEDECIN.NAME, APPAREIL.NAME])) {
        promises.push(dispatch('getMedecin'));
      }
      if (routeContains([APPAREIL.NAME])) {
        promises.push(dispatch('getAppareil'));
      }
    }
    if (medecinHasChanged) {
      if (routeContains([APPAREIL.NAME])) {
        promises.push(dispatch('getAppareil'));
      }
    }

    return Promise.allSettled(promises)
      .then(() => {
        dispatch('updateFieldsLoading', false);
        return true;
      });
  },
  resetFields({ commit, dispatch }) {
    if (routeContains([GLOBAL.NAME])) {
      commit('UPDATE_SOCIETE', []);
    }
    if (routeContains([GLOBAL.NAME, SOCIETE.NAME])) {
      dispatch('office/updateGroupeCabinet', [], { root: true });
      dispatch('office/updateCabinets', [], { root: true });
    }
    if (routeContains([GLOBAL.NAME, SOCIETE.NAME, CABINET.NAME])) {
      commit('UPDATE_MEDECIN', []);
    }
    if (routeContains([GLOBAL.NAME, SOCIETE.NAME, CABINET.NAME, MEDECIN.NAME])) {
      commit('UPDATE_APPAREIL', []);
      commit('UPDATE_TYPE_APPAREIL', []);
    }
  },
  addRegisterFilters({ commit, rootState }, filterName) {
    const { office, installation } = rootState;
    const filterCabinets = office.cabinets;
    const filterGroupeCabinets = office.groupe_cabinet;
    const filterInstallation = installation.installations;
    commit('ADD_REGISTER_FILTERS', {
      filterName, filterInstallation, filterCabinets, filterGroupeCabinets
    });
    const that = this._vm;
    const h = that.$createElement;
    that.$notify.success({
      title: 'Filtre sauvegardé !',
      message: h('p', null, [
        h('span', null, 'Le filtre '),
        h('strong', null, that.$capitalize(filterName)),
        h('span', null, ' à bien été enregistré.')
      ])
    });
  },
  applyRegisterFilters({
    dispatch, commit, getters
  }, id) {
    const selectedRegisterFilters = getters.registerFilters.find(rFilter => rFilter.id === id);
    const { filters } = selectedRegisterFilters;
    if (selectedRegisterFilters) {
      dispatch('installation/updateInstallations', filters.installation, { root: true });
      commit('UPDATE_SOCIETE', filters.societe);
      dispatch('office/updateGroupeCabinet', filters.groupe_cabinet, { root: true });
      dispatch('office/updateCabinets', filters.cabinet, { root: true });
      commit('UPDATE_MEDECIN', filters.medecin);
      commit('UPDATE_CONSOLIDATE_SUBSTITUTES', filters.consolidateSubstitutes);
      commit('UPDATE_TYPE_APPAREIL', filters.type_appareil);
      commit('UPDATE_APPAREIL', filters.appareil);
      commit('UPDATE_ECHELLE_TEMPS', filters.echelle);
      commit('UPDATE_INCLUDE_SAT', filters.include_sat);
      commit('UPDATE_INCLUDE_SUN', filters.include_sun);
      if (filters.displayType) {
        commit('UPDATE_DISPLAY_TYPE', filters.displayType);
      }
    }
  },
  resetFilters({ dispatch, commit, rootGetters }) {
    dispatch('installation/updateInstallations', rootGetters['installation/defaultInstallation'], { root: true });
    commit('UPDATE_SOCIETE', []);
    dispatch('office/updateGroupeCabinet', undefined, { root: true });
    dispatch('office/updateCabinets', [], { root: true });
    commit('UPDATE_MEDECIN', []);
    commit('UPDATE_CONSOLIDATE_SUBSTITUTES', false);
    commit('UPDATE_TYPE_APPAREIL', []);
    commit('UPDATE_APPAREIL', []);
    commit('UPDATE_DISPLAY_TYPE', { isCumul: true });
  },
  removeRegisterFilters({ commit }, id) {
    commit('REMOVE_REGISTER_FILTERS', id);
    const that = this._vm;
    that.$notify.success({
      title: 'Filtre Supprimé !'
    });
  },
  updateFieldsLoading({ commit }, bool) {
    commit('UPDATE_FIELDS_LOADING', bool);
  },
  toggleFilterIsOpen({ commit }, bool) {
    commit('UPDATE_FILTER_IS_OPEN', bool);
  },
  updateHours({ commit }, hours) {
    commit('UPDATE_HOURS', hours);
  }
};

const getters = {
  societe: (state) => state.societe,
  societePropertyFiltered: (state) => removeUnusePropertyFromFilter(state.societe),
  medecin: (state) => state.medecin,
  medecinPropertyFiltered: (state) => removeUnusePropertyFromFilter(state.medecin),
  appareil: (state) => state.appareil,
  appareilPropertyFiltered: (state) => removeUnusePropertyFromFilter(state.appareil),
  date_debut: (state) => state.date_debut,
  date_fin: (state) => state.date_fin,
  echelle: (state) => state.echelle,
  include_sat: (state) => state.include_sat,
  include_sun: (state) => state.include_sun,
  weeks: (state) => state.weeks,
  daysWeek: (state) => state.daysWeek,
  workday_count: (state) => state.workday_count,
  year: (state) => state.year,
  displayType: (state) => state.displayType,
  consolidateSubstitutes: (state) => state.consolidateSubstitutes,
  type_appareil: (state) => state.type_appareil,
  registerFilters: (state) => {
    return state.registerFilters || getStore(REGISTER_FILTERS) || [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
