<template>
    <el-button :disabled="disabled"
               :size="size"
               icon="el-icon-close"
               @click="handleClick">
        {{ buttonName }}
    </el-button>
</template>

<script>
export default {
  name: 'UIButtonCancel',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'big'
    },
    buttonName: {
      type: String,
      default: 'Annuler'
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    }
  }
};
</script>

<style scoped>

</style>
