import incidentService from '@/service/incidentService';

const state = {
  incidents: []
};
const mutations = {
  UPDATE_INCIDENTS: (state, data) => {
    state.incidents = data;
  }
};
const actions = {
  addIncident({ commit }, form) {
    return incidentService.addIncident(form)
      .then(incident => incident.id)
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
