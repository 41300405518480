import importCsvService from '@/service/importCsvService';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const state = {
  typeList: [
    { value: 'installation', label: 'Installations', module: 'cmm' },
    { value: 'office', label: 'Cabinets', module: 'cmm' },
    { value: 'contract', label: 'Contrats', module: 'cmm' },
    { value: 'rent', label: 'Loyers', module: 'cmm' },
    { value: 'maintenance', label: 'Maintenances préventives', module: 'cmm' },
    { value: 'incident', label: 'Maintenances curatives', module: 'cmm' },
    { value: 'material', label: 'Matériels', module: 'cmm' },
    { value: 'training', label: 'Formations', module: 'education' }
  ],
  items: [],
  importCsvErrors: [],
  filters: {
    type: undefined
  }
};

const mutations = {
  UPDATE_ITEMS: (state, data) => {
    state.items = typeof data === 'object' ? data : {};
  },
  UPDATE_FILTER_TYPE: (state, type) => {
    state.filters.type = type;
  },
  UPDATE_IMPORT_CSV_ERROR: (state, errors) => {
    state.importCsvErrors = errors;
  }
};

const actions = {
  updateimportCsvErrors({ commit }, importCsvErrors) {
    commit('UPDATE_IMPORT_CSV_ERROR', importCsvErrors);
  },
  async fetchItems({
    commit, dispatch, getters, rootGetters
  }, sort) {
    updateAppLoading(dispatch, true);

    let items = [];
    try {
      items = await importCsvService.fetchImportCsvList(getters.getFilters, sort);

      const userIsAdmin = rootGetters['security/userIsAdmin'];
      if (!userIsAdmin) {
        items = await dispatch('filterItemsUserNotAdmin', items);
      }

      commit('UPDATE_ITEMS', items);
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }

    return items;
  },
  filterItemsUserNotAdmin({ rootState, rootGetters }, items) {
    const optionsInstallations = rootState.installation.options_installations;
    const user = rootGetters['security/getUser'];

    return items.filter(item => {
      if (item.installation?.idInstallation) {
        if (Array.isArray(state.filters.installation) && state.filters.installation.length === 0) {
          return optionsInstallations.find(optionsInstallation => optionsInstallation.id_installation == item.installation.idInstallation);
        }

        return state.filters.installation == item.installation.idInstallation;
      }
      return item.createdBy.id == user.id;
    });
  },
  async fetchItem({ dispatch }, importCsvId) {
    updateAppLoading(dispatch, true);
    let importCsv;
    try {
      const response = await importCsvService.fetchImportCsv(importCsvId);
      importCsv = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }

    return importCsv;
  },
  async downloadFile({ dispatch }, { typeFile, importCsvId, module }) {
    updateAppLoading(dispatch, true);
    try {
      let file;
      if (typeFile === 'import') {
        file = await importCsvService.downloadImportFile(importCsvId, module);
      } else if (typeFile === 'error') {
        file = await importCsvService.downloadErrorFile(importCsvId, module);
      } else {
        file = await importCsvService.downloadOfficeCorrespondenceFile(importCsvId, module);
      }

      const link = document.createElement('a');
      link.href = file.url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }
  },
  async downloadExportModality({ dispatch }) {
    updateAppLoading(dispatch, true);
    try {
      const file = await importCsvService.downloadExportModality();
      const link = document.createElement('a');
      link.href = file.url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }
  },
  async getErrorFile({ dispatch, commit }, { importCsvId, module }) {
    updateAppLoading(dispatch, true);
    try {
      const response = await importCsvService.getErrorFile(importCsvId, module);
      commit('UPDATE_IMPORT_CSV_ERROR', response.data);
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }
  },
  updateFilterType({ commit }, type) {
    commit('UPDATE_FILTER_TYPE', type);
  },
  resetFilters({ dispatch }) {
    dispatch('installation/updateInstallations', [], { root: true });
    dispatch('user/updateUserFilterSelected', undefined, { root: true });
    dispatch('updateFilterType', undefined);
  },
  uploadImportCsv({ commit, dispatch }, { importCsv, importCsvIdToUpdate = null }) {
    if (!importCsv) {
      console.error('No data to upload');
      updateAppLoading(dispatch, false);
      return null;
    }
    if (importCsv.added) {
      console.error('Document already added');
      updateAppLoading(dispatch, false);
      return null;
    }

    return importCsvService.uploadImportCsv(importCsv, importCsvIdToUpdate)
      .then((response) => {
        if (!response.valid) {
          commit('UPDATE_IMPORT_CSV_ERROR', response.errors);
        }
        return response;
      })
      .catch((error) => {
        console.error(error);
        return null;
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  }
};

const getters = {
  getTypeLabelFromValue: (state) => (typeValue) => {
    const type = state.typeList.find((type) => type.value === typeValue);
    return type.label || '';
  },
  getFilters: (state, getters, rootState) => {
    const { filters } = state;
    const { installation, user } = rootState;
    const installations = installation.installations.length > 0 ? installation.installations : [];
    filters.installation = installations.map(installation => installation.id_installation);
    filters.createdBy = user.userFilterSelected?.id;
    return filters;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
