import DataAPI from '../lib/axios';

export default {
  getRentsByContratId(contratId) {
    return DataAPI.get('/api/contrat_rents', {
      params: { contrat: contratId }
    }).then(res => res.data);
  },
  createRent(rent) {
    return DataAPI.post('/api/contrat_rents', rent)
      .then(res => res.data);
  },
  updateRent(rent) {
    return DataAPI.put(`/api/contrat_rents/${rent.id}`, rent)
      .then(res => res.data);
  },
  deleteRent(rentId) {
    return DataAPI.delete(`/api/contrat_rents/${rentId}`)
      .then(res => res.data);
  }
};
