/**
 * 10000 => "10 000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  const rounded = Math.round(num);
  return !isNaN(rounded)
    ? (
      +rounded || 0
    ).toString()
      .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ' '))
    : 0;
}

export function dateFR(d) {
  // if this is a year format, return it
  if (d.match(/^\d{4}$/)) {
    return d;
  }

  if (d.match(/^\d{2}$/)) {
    return d;
  }

  if (typeof d !== 'object') {
    d = new Date(d);
  }

  const dd = String(d.getDate())
    .padStart(2, '0');
  const mm = String(d.getMonth() + 1)
    .padStart(2, '0'); // January is 0!
  const yyyy = d.getFullYear();

  return `${dd}/${mm}/${yyyy}`;
}

export function getTypeDicom(type) {
  const dicom = {
    CR: 'Radiographie',
    US: 'Echographie',
    MG: 'Mammographie',
    PX: 'Panoramique / Dentaire',
    DX: 'Digital XRay',
    MR: 'IRM',
    CT: 'Scanner',
    OT: 'Autres',
    XA: 'AngioScanner',
    Imports: 'Imports'
  };

  return dicom[type];
}
