import prototypeMessage from '@/prototype/prototypeMessage';
import CONSTANTS from '@/util/constant/constantApp';
import {
  accorization, cloneDeep, routeContains,
  toThousandFilterCustom, toEuro
} from '@/util/util';

export default {
  install(Vue) {
    Vue.prototype.$CONSTANTS = CONSTANTS;
    Vue.prototype.$message = prototypeMessage;
    Vue.prototype.$capitalize = (value) => (value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined);
    Vue.prototype.$uppercase = (value) => value.toUpperCase();
    Vue.prototype.$toInt = (string) => (typeof string === 'string' && !isNaN(parseInt(string)) ? parseInt(string) : string);
    Vue.prototype.$toString = (int) => (typeof int === 'number' && !isNaN(int) ? int.toString() : int);
    Vue.prototype.$wait = (time = 1000) => new Promise((f) => setTimeout(f, time));
    Vue.prototype.$onResize = (event) => {
      const width = event ? event.target.innerWidth : window.innerWidth;
      const height = event ? event.target.innerHeight : window.innerHeight;
      let size = 'xl';
      const range = {
        xs: {
          less: [],
          more: ['sm', 'md', 'lg']
        },
        sm: {
          less: ['xs'],
          more: ['md', 'lg']
        },
        md: {
          less: ['xs', 'sm'],
          more: ['lg']
        },
        lg: {
          less: ['xs', 'sm', 'md'],
          more: []
        }
      };
      if (width < 480) {
        size = 'xs';
      } else if (width < 768) {
        size = 'sm';
      } else if (width < 991) {
        size = 'md';
      } else if (width < 1199) {
        size = 'lg';
      }
      return {
        size,
        width,
        height,
        isLessThan(theSize, include) {
          const { less } = range[theSize];
          const array = include ? [...less, theSize] : less;
          return array.includes(size);
        },
        isMoreThan(theSize, include) {
          const { more } = range[theSize];
          const array = include ? [...more, theSize] : more;
          return array.includes(size);
        }
      };
    };
    Vue.prototype.$extractNumbers = (string) => {
      const numb = string.match(/\d/g);
      return numb ? numb.join('') : null;
    };
    Vue.prototype.$extractLetters = (string) => {
      let b = '';
      for (let i = 0; i < string.length; i++) {
        if (string[i] >= 'A' && string[i] <= 'z') b += string[i];
      }
      return b;
    };

    Vue.prototype.$countLetter = (value, max) => {
      const number = value ? value.length : 0;
      let rest = max - number;
      if (rest >= 0) {
        return `${rest}`;
      }
      rest -= rest;
      return `${rest}`;
    };
    Vue.prototype.$accorization = (items, singularWord, showNumber, messageOnEmpty) => accorization(items, singularWord, showNumber, messageOnEmpty);

    Vue.prototype.$stringToColor = (str) => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += (`00${value.toString(16)}`).substr(-2);
      }
      return color;
    };
    Vue.prototype.$isPromise = (p) => p && Object.prototype.toString.call(p) === '[object Promise]';
    Vue.prototype.$routeContains = (array) => routeContains(array);
    Vue.prototype.$sum = (array) => array.reduce((acc, val) => acc + val, 0);
    Vue.prototype.$focusNext = (elem) => {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem);
      elem.form.elements.item(
        currentIndex < elem.form.elements.length - 1
          ? currentIndex + 1
          : 0
      ).focus();
    };

    const formatData = (value, dataInCurrency) => {
      if (isNaN(value)) {
        return value;
      }
      return dataInCurrency ? toEuro(toThousandFilterCustom(value)) : toThousandFilterCustom(value);
    };
    Vue.prototype.$formatData = formatData;
    Vue.prototype.$parseValue = (value) => (isNaN(value) ? value : formatData(Math.round(value)));
  }
};

if (!Object.entries) {
  Object.entries = function (obj) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i);

    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
    return resArray;
  };
  console.log(
    '%c Object.entries Polyfill Done',
    'background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF',
    Object.entries
  );
}
if (!Object.fromEntries) {
  Object.fromEntries = l => l.reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
  console.log(
    '%c Object.fromEntries Polyfill Done',
    'background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF',
    Object.fromEntries
  );
}

// replaceAll Polyfill
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr);
    }
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}

const getEntries = (o) => Object.entries(o).flatMap(([k, v]) => {
  if (Object(v) === v && !v.name) {
    return getEntries(v);
  } if (v.name) {
    return [[k, v.name]];
  }
  return [[k, v]];
});
