import officeService from '@/service/officeService';
import { getDuplicate, removeUnusePropertyFromFilter, removeUnusePropertyFromFilterGroupeCabinet } from '@/util/util';

const state = {
  cabinets: [],
  options_cabinets: [],
  options_cabinets_cached: {},
  groupe_cabinet: [],
  options_groupe_cabinets: [],
  cache_options_cabinets: [],
  cache_options_groupe_cabinets: []
};

const mutations = {
  UPDATE_CABINETS: (state, cabinets) => {
    cabinets = !Array.isArray(cabinets) ? [cabinets] : cabinets;
    state.cabinets = cabinets;
  },
  UPDATE_OPTIONS_CABINETS: (state, options_cabinets) => {
    state.options_cabinets = options_cabinets;
  },
  RESET_OPTION_CABINETS_CACHED: (state) => {
    state.options_cabinets_cached = [];
  },
  UPDATE_GROUPE_CABINET: (state, groupe_cabinet) => {
    state.groupe_cabinet = groupe_cabinet;
  },
  UPDATE_OPTIONS_GROUPE_CABINET: (state, groupe_cabinets) => {
    state.options_groupe_cabinets = groupe_cabinets;
  },
  UPDATE_OPTIONS_CABINETS_CACHED: (state, { options_cabinets, installationId }) => {
    state.options_cabinets_cached[installationId] = options_cabinets;
  },
  UPDATE_CACHE_OPTIONS_GROUPE_CABINET: (state, { options_cabinets, installationId }) => {
    state.cache_options_groupe_cabinets[installationId] = options_cabinets;
  },
  RESET_CACHE_OPTIONS_GROUPE_CABINETS: (state) => {
    state.cache_options_groupe_cabinets = [];
  }
};
const actions = {
  getCabinet({
    commit, rootGetters, state, dispatch, getters
  }, idsInstallation) {
    const that = this._vm;
    const initIdsInstallation = idsInstallation || rootGetters['installation/AllIdsInstallations'];
    const newIds = !Array.isArray(initIdsInstallation) ? [initIdsInstallation] : initIdsInstallation;
    if (newIds.length === 1) {
      const cached = getters.cabinetsCached(newIds[0]);
      if (cached) {
        commit('UPDATE_OPTIONS_CABINETS', cached);
        commit('UPDATE_CABINETS', getDuplicate(state.cabinets, cached));
        return Promise.resolve(cached);
      }
    }
    dispatch('filter/updateFieldsLoading', true, { root: true });
    return officeService.getCabinetInfosByInstallation(newIds)
      .then(options_cabinets => {
        commit('UPDATE_OPTIONS_CABINETS', options_cabinets);
        commit('UPDATE_CABINETS', getDuplicate(state.cabinets, options_cabinets));
        if (newIds.length === 1) {
          commit('UPDATE_OPTIONS_CABINETS_CACHED', { options_cabinets, installationId: newIds[0] });
        }
        dispatch('filter/updateFieldsLoading', false, { root: true });
        return options_cabinets;
      }).catch((error) => {
        console.error(error);
        that.$message.error('Une erreur est survenue lors du chargements des cabinets');
        return null;
      });
  },
  getGroupeCabinet({
    state, dispatch, rootGetters, commit, getters
  }, installationIdList = undefined) {
    const installationIds = installationIdList || [rootGetters['installation/defaultSelectedIDInstallation']];
    const user = rootGetters['security/getUser'];
    const newIds = !Array.isArray(installationIds) ? [installationIds] : installationIds;
    if (newIds.length === 1) {
      const cached = getters.groupeCabinetsCached(newIds[0]);
      if (cached) {
        dispatch('updateOptionsGroupeCabinets', cached);
        dispatch('updateGroupeCabinet', state.groupe_cabinet);
        return Promise.resolve(cached);
      }
    }

    return officeService.getGroupeCabinetByInstallationIdsAndUserId(installationIds, user.id)
      .then(optionsGroupeCabinet => {
        dispatch('updateOptionsGroupeCabinets', optionsGroupeCabinet);
        dispatch('updateGroupeCabinet', []);
        if (newIds.length === 1) {
          commit('UPDATE_CACHE_OPTIONS_GROUPE_CABINET', { options_cabinets: optionsGroupeCabinet, installationId: newIds[0] });
        }
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },
  updateCabinets({ commit }, cabinets) {
    return commit('UPDATE_CABINETS', cabinets);
  },
  updateOptionsCabinets({ commit }, options_cabinets) {
    commit('UPDATE_OPTIONS_CABINETS', options_cabinets);
  },
  resetOptionCabinetsCached({ commit }) {
    commit('RESET_OPTION_CABINETS_CACHED');
  },
  updateOptionsGroupeCabinets({ commit }, options_groupe_cabinets) {
    commit('UPDATE_OPTIONS_GROUPE_CABINET', options_groupe_cabinets);
  },
  updateGroupeCabinet({ commit }, groupeCabinet) {
    commit('UPDATE_GROUPE_CABINET', groupeCabinet);
  },
  resetCacheOptionsGroupeCabinets({ commit }) {
    commit('RESET_CACHE_OPTIONS_GROUPE_CABINETS');
  }
};

const getters = {
  cabinetsCached: (state) => (installationId) => state.options_cabinets_cached[installationId],
  groupeCabinetsCached: (state) => (installationId) => state.cache_options_groupe_cabinets[installationId],
  defaultIdCabinetRis: (state, getters) => getters.initializationSingleCabinet.id_cabinet || null,
  defaultIdCabinet: (state, getters) => getters.initializationSingleCabinet?.id || null,
  defaultNameCabinet: (state, getters) => getters.initializationSingleCabinet?.name || null,
  defaultCabinet: (state) => (state.options_cabinets || [])[0] || {},
  defaultSelectedCabinet: (state) => (state.cabinets || [])[0] || {},
  initializationSingleCabinet: (state, getters) => (!getters.defaultSelectedCabinet ? getters.defaultCabinet : getters.defaultSelectedCabinet),
  cabinetsPropertyFiltered: (state) => removeUnusePropertyFromFilter(state.cabinets),
  groupeCabinetsPropertyFiltered: (state) => removeUnusePropertyFromFilterGroupeCabinet(state.groupe_cabinet)
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
