import BaseLayout from '@/layout/BaseLayout';
import { getStore } from '@/util/storage';

export default [
  {
    path: '/investment-decision',
    component: BaseLayout,
    name: 'investment_decision',
    meta: {
      authRequired: true, title: 'Aide à la décision', icon: 'fas fa-dollar-sign', css_class: 'plateau'
    },
    children: [
      {
        path: 'investment_renouvellement_list',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=investment/investment_renouvellement_list.php`;
        },
        name: 'investment_renouvellement_list',
        meta: {
          authRequired: true, role: 'ROLE_INVESTMENT_DECISION_RENEWAL_READ', managementOfTheTechnicalPlatform: true, title: 'Aide au renouvellement', icon: 'fas fa-recycle', css_class: 'financier'
        }
      },
      {
        path: 'investment_achat_list',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=investment/investment_achat_list.php`;
        },
        name: 'investment_achat_list',
        meta: {
          authRequired: true, role: 'ROLE_INVESTMENT_DECISION_PURCHASE_READ', managementOfTheTechnicalPlatform: true, title: 'Décision d\'achat', icon: 'fas fa-credit-card', css_class: 'financier'
        }
      },
      {
        path: 'investment_location_list',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=investment/investment_location_list.php`;
        },
        meta: {
          authRequired: true, role: 'ROLE_INVESTMENT_DECISION_LOCATION_READ', managementOfTheTechnicalPlatform: false, title: 'Décision de location', icon: 'fas fa-upload', css_class: 'financier'
        }
      }
    ]
  }
];
