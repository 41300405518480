import objectiveService from '@/service/objectiveService';

const actions = {
  addObjectives({ commit }, form) {
    return objectiveService.insertObjectives(form)
      .then(objective => objective.id);
  }
};

export default {
  namespaced: true,
  actions
};
