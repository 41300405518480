const IMPORT_CSV = 'import_csv';
const IMPORT_CSV_SLUG = 'import_csv';
const IMPORT_CSV_PATHNAME = 'import_csv';
const IMPORT_CSV_API_ROUTE = '/api/import_csvs';

const DASHBOARD = {
  IMPORT_CSV: {
    STORE: 'importCsv',
    NAME: IMPORT_CSV,
    SLUG: IMPORT_CSV_SLUG,
    PATH_NAME: IMPORT_CSV_PATHNAME,
    API_ROUTE: IMPORT_CSV_API_ROUTE,
    CRUD: [],
    EXPORT_NAME: 'Historique Imports CSV'
  }
};

export default {
  DASHBOARD
};
