import { cmmFilterParamsTreatment } from '@/util/util';

const getters = {
  getFilterCES: (state, getters, rootState, rootGetters) => {
    return {
      id_installation: rootGetters['installation/defaultSelectedIDInstallation'],
      date_debut: rootGetters['filter/date_debut'],
      date_fin: rootGetters['filter/date_fin'],
      echelle: rootGetters['filter/echelle'],
      include_sat: rootGetters['filter/include_sat'],
      include_sun: rootGetters['filter/include_sun'],
      displayType: rootGetters['filter/displayType'],
      workday_count: rootGetters['filter/workday_count'],
      societe: rootGetters['filter/societePropertyFiltered'],
      cabinet: rootGetters['office/cabinetsPropertyFiltered'],
      groupe_cabinet: rootGetters['office/groupeCabinetsPropertyFiltered'],
      medecin: rootGetters['filter/medecinPropertyFiltered'],
      consolidateSubstitutes: rootGetters['filter/consolidateSubstitutes'],
      appareil: rootGetters['filter/appareilPropertyFiltered'],
      type_appareil: rootGetters['filter/type_appareil']
    };
  },
  getFilterComparaison: (state, getters, rootState, rootGetters) => {
    const { getFilterCES } = getters;
    const filterComparaison = {
      weeks: rootGetters['filter/weeks'],
      daysWeek: rootGetters['filter/daysWeek'],
      year: rootGetters['filter/year']
    };

    return { ...getFilterCES, ...filterComparaison };
  },
  getFilterPrevisional: (state, getters, rootState, rootGetters) => getters.getFilterComparaison,
  getFilterCmm: (state, getters, rootState, rootGetters) => {
    const filters = {
      installations: rootState.installation.installations.length > 0 ? rootGetters['installation/installationPropertyFiltered'] : rootGetters['installation/optionsInstallationPropertyFiltered'],
      cabinets: rootGetters['office/cabinetsPropertyFiltered'],
      groupe_cabinet: rootGetters['office/groupeCabinetsPropertyFiltered'],
      recherche: rootGetters['cmm/recherche'],
      materiel_inactif: rootGetters['cmm/materiel_inactif'],
      materiel_with_periodicty_disable: rootGetters['cmm/materiel_with_periodicty_disable'],
      occupation_status: rootGetters['cmm/occupation_status'],
      commissioningYear: rootGetters['cmm/commissioningYear']
    };

    return { ...cmmFilterParamsTreatment(filters) };
  }
};

export default {
  namespaced: true,
  getters
};
