import flashFocusService from '@/service/flashFocusService';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const state = {
  items: [],
  periodicityList: [],
  filters: {
    periodicityId: undefined,
    active: true
  }
};

const mutations = {
  UPDATE_ITEMS: (state, data) => {
    state.items = typeof data === 'object' ? data : {};
  },
  UPDATE_PERIODICITY_LIST: (state, periodicityList) => {
    state.periodicityList = periodicityList;
  },
  UPDATE_FILTER_PERIODICITY_ID: (state, value) => {
    state.filters.periodicityId = value;
  },
  UPDATE_FILTER_ACTIVE: (state, active) => {
    state.filters.active = active;
  }
};

const actions = {
  async fetchItems({
    commit, dispatch, getters
  }, sort) {
    updateAppLoading(dispatch, true);

    let items = [];
    try {
      const response = await flashFocusService.fetchFlashFocusList(getters.getFilters, sort);
      items = response.data;
      commit('UPDATE_ITEMS', items);
    } catch (error) {
      console.error(error);
    } finally {
      updateAppLoading(dispatch, false);
    }

    return items;
  },
  deleteFlashFocus({ dispatch }, flashFocusId) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return flashFocusService.deleteFlashFocus(flashFocusId)
      .catch((error) => {
        that.$message.error('Erreur lors de la suppression du flash focus');
        console.error(error);
      })
      .finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  async fetchFlashFocusPeriodicityList({ state, commit }) {
    if (state.periodicityList.length === 0) {
      const periodicityList = await flashFocusService.fetchFlashFocusPeriodicityList();
      commit('UPDATE_PERIODICITY_LIST', periodicityList);
    }
  },
  updateFilterPeriodicityId({ commit }, periodicityId) {
    commit('UPDATE_FILTER_PERIODICITY_ID', periodicityId);
  },
  updateFilterActive({ commit }, value) {
    commit('UPDATE_FILTER_ACTIVE', value);
  },
  resetFilters({ dispatch }) {
    dispatch('installation/updateInstallations', [], { root: true });
    dispatch('user/updateUserFilterSelected', undefined, { root: true });
    dispatch('updateFilterPeriodicityId', undefined);
    dispatch('updateFilterActive', true);
  },
  sendFlashFocus({ dispatch }, {
    flashFocusId, date, useDefaultRecipients, customRecipients
  }) {
    updateAppLoading(dispatch, true);
    const that = this._vm;
    flashFocusService.sendFlashFocus(flashFocusId, date, useDefaultRecipients, customRecipients).then(() => {
      that.$notify({
        title: 'Envoyé',
        message: 'Flash Focus envoyé avec succès',
        type: 'success'
      });
    }).catch(error => {
      that.$notify({
        title: 'Erreur',
        message: 'Une erreur est survenue lors de l\'envoi du Flash Focus',
        type: 'error'
      });
      console.error(error);
    }).finally(() => {
      updateAppLoading(dispatch, false);
    });
  }
};

const getters = {
  getPeriodicityNameById: (state) => (periodicityId) => {
    const periodicity = state.periodicityList.find((periodicityOption) => {
      return periodicityOption.id === periodicityId;
    });
    return periodicity ? periodicity.label.charAt(0).toUpperCase() + periodicity.label.slice(1).toLowerCase() : '';
  },
  getFilters: (state, getters, rootState) => {
    const { filters } = state;
    const { installation, user } = rootState;
    const installations = installation.installations.length > 0 ? installation.installations : [];
    filters.installations = installations.map(installation => installation.id_installation);
    filters.createdBy = user.userFilterSelected?.id;

    return filters;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
