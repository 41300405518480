const COMPARE_PERCENT_DATAS_KEY = [
  'var_prix_exam',
  'var_prix_dossier',
  'var_prix_patient',
  'Var_Radio',
  'Var_Echo',
  'Var_Mammo',
  'Var_Pano',
  'Var_Scanner',
  'Var_IRM',
  'Var_OT'
];

const COMPARE_CORRESPONDANCE_KEY_LABEL = {
  total: 'Total CA',
  hono: 'Honos',
  ft: 'FT',
  dep: 'DP',
  ca_CR: 'Honos Radio',
  ca_US: 'Honos Echo',
  ca_MG: 'Honos Mammo',
  ca_PX: 'Honos Pano/CB',
  ca_CT: 'Honos Scanner',
  ca_MR: 'Honos IRM',
  ca_OT: 'Honos Autres',
  dossier: 'Nbre de dossiers',
  examen: 'Nbre d\'examens',
  patient: 'Nbre d\'patients',
  nb_CR: 'Nbre examens Radio',
  nb_US: 'Nbre examens Echo',
  nb_MG: 'Nbre examens Mammo',
  nb_PX: 'Nbre examens Pano/CB',
  nb_CT: 'Nbre examens Scanner',
  nb_MR: 'Nbre examens IRM',
  nb_OT: 'Nbre examens Autres',
  avg: 'examens/dossiers/patients',
  per_dicom: 'examen par modalité'
};

export default {
  COMPARE_CORRESPONDANCE_KEY_LABEL,
  COMPARE_PERCENT_DATAS_KEY
};
