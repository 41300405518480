const FLASH_FOCUS = 'flash_focus';
const FLASH_FOCUS_SLUG = 'flash_focus';
const FLASH_FOCUS_PATHNAME = 'flash_focus';
const FLASH_FOCUS_API_ROUTE = '/api/flash_focus';

const DASHBOARD = {
  FLASH_FOCUS: {
    STORE: 'flashFocus',
    NAME: FLASH_FOCUS,
    SLUG: FLASH_FOCUS_SLUG,
    PATH_NAME: FLASH_FOCUS_PATHNAME,
    API_ROUTE: FLASH_FOCUS_API_ROUTE,
    CRUD: ['delete']
  }
};

export default {
  DASHBOARD
};
