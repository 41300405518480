import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/cockpit_expertise_sante',
    component: BaseLayout,
    name: 'cockpit_expertise_sante',
    meta: {
      authRequired: true, title: 'Cockpit Expertise Santé', icon: 'far fa-chart-bar', css_class: 'financier'
    },
    children: [
      {
        path: 'cockpit_synthese',
        component: () => import('@/views/CES/CESSynthesisBase'),
        name: 'cockpit_expertise_sante/cockpit_synthese',
        meta: {
          authRequired: true, role: 'ROLE_CES_SYNTHESIS_READ', title: 'Cockpit Synthèse', icon: 'fas fa-tachometer-alt', css_class: 'financier'
        },
        children: [
          {
            path: '/cockpit_expertise_sante/cockpit_synthese/global',
            component: () => import('@/views/CES/CESSynthesis'),
            name: 'cockpit_expertise_sante/cockpit_synthese/global',
            meta: {
              authRequired: true, role: 'ROLE_CES_SYNTHESIS_READ', activityManagement: true, cubeTitle: 'Cockpit Synthèse', title: 'Global', icon: 'fas fa-chart-line', css_class: 'financier'
            }
          },
          {
            path: '/cockpit_expertise_sante/cockpit_synthese/societe',
            component: () => import('@/views/CES/CESSynthesis'),
            name: 'cockpit_expertise_sante/cockpit_synthese/societe',
            meta: {
              authRequired: true, role: 'ROLE_CES_SYNTHESIS_READ', title: 'Par société', icon: 'chart-up', css_class: 'financier'
            }
          },
          {
            path: '/cockpit_expertise_sante/cockpit_synthese/cabinet',
            component: () => import('@/views/CES/CESSynthesis'),
            name: 'cockpit_expertise_sante/cockpit_synthese/cabinet',
            meta: {
              authRequired: true, role: 'ROLE_CES_SYNTHESIS_READ', title: 'Par cabinet', icon: 'clinic-medical-solid', css_class: 'financier'
            }
          },
          {
            path: '/cockpit_expertise_sante/cockpit_synthese/medecin',
            component: () => import('@/views/CES/CESSynthesis'),
            name: 'cockpit_expertise_sante/cockpit_synthese/medecin',
            meta: {
              authRequired: true, role: 'ROLE_CES_SYNTHESIS_READ', title: 'Par médecin', icon: 'fas fa-user-md', css_class: 'financier'
            }
          },
          {
            path: '/cockpit_expertise_sante/cockpit_synthese/appareil',
            component: () => import('@/views/CES/CESSynthesis'),
            name: 'cockpit_expertise_sante/cockpit_synthese/appareil',
            meta: {
              authRequired: true, role: 'ROLE_CES_SYNTHESIS_READ', title: 'Par appareil', icon: 'mri', css_class: 'financier'
            }
          }
        ]
      },
      {
        path: 'comparaison',
        component: () => import('@/views/CES/CESSynthesisBase'),
        name: 'cockpit_expertise_sante/comparaison',
        meta: {
          authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Comparaison N-1', icon: 'fas fa-list-ol', css_class: 'financier'
        },
        children: [
          {
            path: 'date_a_date',
            component: () => import('@/views/CES/CESComparison'),
            name: 'cockpit_expertise_sante/comparaison_n-1/date_a_date',
            meta: {
              authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Date à date', icon: 'fas fa-calendar-day', css_class: 'financier'
            },
            redirect: 'societe',
            children: [
              {
                path: 'societe',
                component: () => import('@/views/CES/CESComparison'),
                name: 'cockpit_expertise_sante/comparaison_n-1/date_a_date/societe',
                meta: {
                  authRequired: true, role: 'ROLE_CES_COMPARISON_READ', activityManagement: true, cubeTitle: 'Comparaison N-1', title: 'Par société', icon: 'chart-up', css_class: 'financier'
                }
              },
              {
                path: 'cabinet',
                component: () => import('@/views/CES/CESComparison'),
                name: 'cockpit_expertise_sante/comparaison_n-1/date_a_date/cabinet',
                meta: {
                  authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Par cabinet', icon: 'clinic-medical-solid', css_class: 'financier'
                }
              },
              {
                path: 'medecin',
                component: () => import('@/views/CES/CESComparison'),
                name: 'cockpit_expertise_sante/comparaison_n-1/date_a_date/medecin',
                meta: {
                  authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Par medecin', icon: 'fas fa-user-md', css_class: 'financier'
                }
              },
              {
                path: 'appareil',
                component: () => import('@/views/CES/CESComparison'),
                name: 'cockpit_expertise_sante/comparaison_n-1/date_a_date/appareil',
                meta: {
                  authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Par appareil', icon: 'mri', css_class: 'financier'
                }
              }
            ]
          },
          {
            path: 'par_semaine_et_par_jour',
            component: () => import('@/views/CES/CESComparison'),
            name: 'cockpit_expertise_sante/comparaison_n-1/par_semaine_et_par_jour',
            meta: {
              authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Par semaine et par jour', icon: 'fas fa-calendar-week', css_class: 'financier'
            },
            children: [
              {
                path: 'societe',
                component: () => import('@/views/CES/CESComparison'),
                name: 'cockpit_expertise_sante/comparaison_n-1/par_semaine_et_par_jour/societe',
                meta: {
                  authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Par société', icon: 'chart-up', css_class: 'financier'
                }
              },
              {
                path: 'cabinet',
                component: () => import('@/views/CES/CESComparison'),
                name: 'cockpit_expertise_sante/comparaison_n-1/par_semaine_et_par_jour/cabinet',
                meta: {
                  authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Par cabinet', icon: 'clinic-medical-solid', css_class: 'financier'
                }
              },
              {
                path: 'medecin',
                component: () => import('@/views/CES/CESComparison'),
                name: 'cockpit_expertise_sante/comparaison_n-1/par_semaine_et_par_jour/medecin',
                meta: {
                  authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Par medecin', icon: 'fas fa-user-md', css_class: 'financier'
                }
              },
              {
                path: 'appareil',
                component: () => import('@/views/CES/CESComparison'),
                name: 'cockpit_expertise_sante/comparaison_n-1/par_semaine_et_par_jour/appareil',
                meta: {
                  authRequired: true, role: 'ROLE_CES_COMPARISON_READ', title: 'Par appareil', icon: 'mri', css_class: 'financier'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'cockpit_previsionnel',
        component: () => import('@/views/CES/CESSynthesisBase'),
        name: 'cockpit_expertise_sante/cockpit_previsionnel',
        meta: {
          authRequired: true, role: 'ROLE_CES_PREVISIONAL_READ', title: 'Cockpit Prévisionnel', icon: 'fas fa-th-large', css_class: 'financier'
        },
        children: [
          {
            path: 'societe',
            component: () => import('@/views/CES/CESProvisional'),
            name: 'cockpit_expertise_sante/cockpit_previsionnel/societe',
            meta: {
              authRequired: true, role: 'ROLE_CES_PREVISIONAL_READ', activityManagement: true, cubeTitle: 'Cockpit Prévisionnel', title: 'Par société', icon: 'chart-up', css_class: 'financier'
            }
          },
          {
            path: 'cabinet',
            component: () => import('@/views/CES/CESProvisional'),
            name: 'cockpit_expertise_sante/cockpit_previsionnel/cabinet',
            meta: {
              authRequired: true, role: 'ROLE_CES_PREVISIONAL_READ', title: 'Par cabinet', icon: 'clinic-medical-solid', css_class: 'financier'
            }
          }

        ]
      },
      {
        path: 'fixation_dobjectifs',
        component: () => import('@/views/CES/CESObjective'),
        name: 'cockpit_expertise_sante/fixation_dobjectifs',
        hidden: true,
        meta: {
          authRequired: true, role: 'ROLE_CES_OBJECTIVE_READ', title: 'Fixation d\'objectifs', icon: 'fas fa-cogs', css_class: 'financier'
        },
        children: [
          {
            path: 'global',
            component: () => import('@/views/CES/CESObjective'),
            name: 'cockpit_expertise_sante/fixation_dobjectifs/global',
            meta: {
              authRequired: true, role: 'ROLE_CES_OBJECTIVE_READ', title: 'Global', icon: 'fas fa-chart-line', css_class: 'financier'
            }
          },
          {
            path: 'societe',
            component: () => import('@/views/CES/CESObjective'),
            name: 'cockpit_expertise_sante/fixation_dobjectifs/societe',
            meta: {
              authRequired: true, role: 'ROLE_CES_OBJECTIVE_READ', title: 'Par société', icon: 'chart-up', css_class: 'financier'
            }
          },
          {
            path: 'cabinet',
            component: () => import('@/views/CES/CESObjective'),
            name: 'cockpit_expertise_sante/fixation_dobjectifs/cabinet',
            meta: {
              authRequired: true, role: 'ROLE_CES_OBJECTIVE_READ', title: 'Par cabinet', icon: 'clinic-medical-solid', css_class: 'financier'
            }
          },
          {
            path: 'appareil',
            component: () => import('@/views/CES/CESObjective'),
            name: 'cockpit_expertise_sante/fixation_dobjectifs/appareil',
            meta: {
              authRequired: true, role: 'ROLE_CES_OBJECTIVE_READ', title: 'Par appareil', icon: 'mri', css_class: 'financier'
            }
          }

        ]
      }
    ]
  }
];
