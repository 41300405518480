import maintenanceService from '@/service/maintenanceService';

const state = {
  maintenances: []
};
const mutations = {
  UPDATE_MAINTENANCE: (state, data) => {
    state.maintenances = data;
  }
};
const actions = {
  addMaintenance({ commit }, form) {
    return maintenanceService.addMaintenances(form)
      .then(maintenance => maintenance.id);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
