import userGroupService from '@/service/userGroupService';

const state = {
  userGroup: []
};

const mutations = {
  SET_USER_GROUP(state, userGroup) {
    state.userGroup = userGroup;
  }
};

const actions = {
  fetchUserGroup({ state, commit }) {
    if (state.userGroup?.length) {
      return Promise.resolve(state.userGroup);
    }
    return userGroupService.fetchUserGroup()
      .then(userGroup => {
        commit('SET_USER_GROUP', userGroup);
        return userGroup;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
