import bugReportService from '@/service/bugReportService';

const actions = {
  createBugReport({ state, dispatch }, bugReport) {
    const that = this._vm;
    return bugReportService.createBugReport(bugReport)
      .then(() => {
        that.$notify({
          title: 'Déclaration envoyée',
          message: 'Votre déclaration d\'incident a bien été prise en compte',
          type: 'success'
        });
      })
      .catch((error) => {
        that.$message.error('Erreur lors de la création du rapport de bug');
        console.error(error);
      });
  }
};

export default {
  namespaced: true,
  actions
};
