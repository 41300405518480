import periodService from '@/service/periodService';

const state = {
  periods: []
};

const mutations = {
  SET_PERIODS(state, periods) {
    state.periods = periods;
  }
};

const actions = {
  getPeriods({ state, commit }) {
    if (state.periods?.length) {
      return Promise.resolve(state.periods);
    }
    return periodService.getPeriods()
      .then(periods => {
        commit('SET_PERIODS', periods);
        return periods;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
