import rentService from '@/service/rentService';
import { accorization, generateRandomIndex } from '@/util/util';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const state = {
  rents: []
};

const mutations = {
  SET_RENTS: (state, rents) => {
    state.rents = rents || [];
  },
  ADD_RENT: (state, rent) => {
    if (!rent.contrat && !rent.id) {
      rent.tmp_id = generateRandomIndex(8);
    }
    state.rents.unshift(rent);
  },
  APPLY_RENTS: (state, rent) => {
    const find = state.rents.find(r => r.tmp_id !== rent.tmp_id);
    if (find) {
      delete find.tmp_id;
      find.id = rent.id;
    }
  },
  REMOVE_RENTS: (state, rent) => {
    const key = rent.tmp_id ? 'tmp_id' : 'id';
    state.rents = state.rents.filter(r => r[key] !== rent[key]);
  }
};

const actions = {
  setRents({ commit }, rents) {
    commit('SET_RENTS', rents);
  },
  applyTmpRents({ commit, dispatch, state }, contratId) {
    const that = this._vm;
    if (!state.rents.length) {
      return Promise.resolve();
    }
    updateAppLoading(dispatch, true);
    const promises = [];
    const rentsToAdded = state.rents.filter(r => !r.id);
    rentsToAdded.forEach(rent => {
      const newRent = {
        ...rent,
        contrat: contratId
      };
      promises.push(rentService.createRent(newRent));
    });

    return Promise.allSettled(promises)
      .then(responses => {
        const rentsInError = [];
        responses.forEach((res, index) => {
          const rent = { ...rentsToAdded[index], ...res.value };
          const successCreated = res.status === 'fulfilled';
          if (successCreated) {
            commit('APPLY_RENTS', rent);
          } else {
            rentsInError.push(rentsToAdded[index]);
          }
        });

        if (rentsInError.length) {
          that.$message.error(`Attention, ${accorization(rentsInError, 'loyer', true)} n'ont pas pu être ajouté`);
          return Promise.reject(rentsInError);
        }

        return 'ok';
      })
      .catch(_ => {
        that.$message.error('Erreur lors de l\'ajout des loyers');
        return Promise.reject();
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  getRentsByContratId({ commit, dispatch }, contratId) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return rentService.getRentsByContratId(contratId)
      .then(rents => {
        commit('SET_RENTS', rents);
        return rents;
      }).catch(_ => {
        that.$message.error('Erreur lors de la recupération des loyer');
        return Promise.reject();
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  createRent({ commit, dispatch }, rent) {
    const that = this._vm;
    if (!rent.contrat) {
      commit('ADD_RENT', rent);
      return Promise.resolve(rent);
    }
    updateAppLoading(dispatch, true);
    return rentService.createRent(rent)
      .then(r => {
        const newRent = { ...rent, ...r };
        commit('ADD_RENT', newRent);
        return newRent;
      }).catch(_ => {
        that.$message.error('Erreur lors de l\'ajout du loyer');
        return Promise.reject();
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  updateRent({ dispatch, commit }, rent) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return rentService.updateRent(rent)
      .then(updatedRent => {
        that.$notify({
          title: 'Loyer modifié',
          message: 'Votre loyer a bien été modifié',
          type: 'success'
        });
        return updatedRent.id;
      }).catch(_ => {
        that.$message.error('Erreur lors de la modification du loyer');
        return Promise.reject();
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  deleteRent({ commit, dispatch, state }, rent) {
    const that = this._vm;
    if (rent.tmp_id) {
      commit('REMOVE_RENTS', rent);
      return Promise.resolve(rent);
    }
    updateAppLoading(dispatch, true);
    return rentService.deleteRent(rent.id)
      .then(_ => {
        commit('REMOVE_RENTS', rent);
        return rent;
      }).catch(_ => {
        that.$message.error('Erreur lors de la suppression du loyer');
        return Promise.reject();
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
