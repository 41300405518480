import { getStore } from '@/util/storage';
import DataAPI from '../lib/axios';

export default {
  getCodeExamByInstallation(installation) {
    return DataAPI.get('api/code_exams', {
      params: {
        installation
      }
    })
      .then(res => res.data);
  },
  registerFilterCodeExam(filterToRegister) {
    return DataAPI.post('/api/filter_exams', filterToRegister)
      .then(res => res.data);
  },
  getFiltersByUserAndInstallation(installation) {
    return DataAPI.get('/api/filter_exams', {
      params: {
        createdBy: getStore('USER').id,
        installation
      }
    })
      .then(res => res.data);
  },
  deleteFilter(filterId) {
    return DataAPI.delete(`/api/filter_exams/${filterId}`)
      .then(response => response.data);
  }
};
