import BaseLayout from '@/layout/BaseLayout';
import { getStore } from '@/util/storage';

export default [
  {
    path: '/rh',
    component: BaseLayout,
    name: 'human_resource',
    meta: {
      authRequired: true, title: 'Ressources humaines', icon: 'fas fa-briefcase', css_class: 'rh'
    },
    children: [
      {
        path: 'rh_liste_salarie',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_liste_salarie.php`;
        },
        name: 'rh_liste_salarie',
        meta: {
          authRequired: true, role: 'ROLE_HR_EMPLOYEE_READ', humanResourceManagement: true, title: 'Salariés', icon: 'fas fa-users', css_class: 'rh'
        }
      },
      {
        path: 'rh_liste_vip',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_liste_vip.php`;
        },
        name: 'rh_liste_vip',
        meta: {
          authRequired: true, role: 'ROLE_HR_VIP_READ', humanResourceManagement: true, title: 'Visites médicales', icon: 'fas fa-user-md', css_class: 'rh'
        }
      },
      {
        path: 'rh_liste_eip',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_liste_eip.php`;
        },
        name: 'rh_liste_eip',
        meta: {
          authRequired: true, role: 'ROLE_HR_EIP_READ', humanResourceManagement: true, title: 'Entretien Individuel Professionnel', icon: 'fas fa-comment', css_class: 'rh'
        }
      },
      {
        path: 'rh_liste_recrutement',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_liste_recrutement.php`;
        },
        name: 'rh_liste_recrutement',
        meta: {
          authRequired: true, role: 'ROLE_HR_RECRUTMENT_READ', humanResourceManagement: true, title: 'Recrutement', icon: 'fas fa-handshake', css_class: 'rh'
        }
      }
    ]
  }
];
