import documentService from '@/service/documentService';
import { EventBus } from '@/util/eventBus';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

function getTypeId(rootState, type) {
  const typesList = [...rootState.eml.eml_doc_types];
  return typesList.find(t => t.typeName === type)?.id;
}

const actions = {
  uploadDocuments({ dispatch, rootState }, { files, type, targetId }) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    const typeId = getTypeId(rootState, type);
    return documentService.uploadDocuments(files, type, typeId, targetId)
      .then(messages => {
        if (messages?.errors?.message) {
          that.$message.error(messages?.errors?.message);
          EventBus.$emit('openErrorDocumentModal', {
            files: messages?.errors.files, type, typeId, targetId
          });
          return messages?.errors?.message;
        }
        return messages?.success?.message;
      }).catch(() => {
        EventBus.$emit('openErrorDocumentModal', {
          files, type, typeId, targetId
        });
        that.$message.error('Erreur lors de l\'upload de certain fichier');
        return null;
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  uploadDocument({ dispatch, rootState }, { file, type, targetId }) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    const typeId = getTypeId(rootState, type);
    return documentService.uploadDocument(file, type, typeId, targetId)
      .catch(() => {
        that.$message.error('Erreur lors de l\'upload du document');
        return null;
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  deleteDocumentById({ commit, dispatch, rootState }, {
    fileId, type, removeFromDashboard, targetId
  }) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return documentService.deleteDocumentById(fileId, type)
      .then(() => {
        if (removeFromDashboard) {
          const { app } = rootState;
          const { type_dashboard } = app;
          const { STORE } = type_dashboard;
          commit(`${STORE}/REMOVE_DASHBOARD_DOCUMENTS`, fileId, { root: true });
          dispatch(`${STORE}/getDashboardHistoricElementDashboard`, { id: targetId, type }, { root: true });
        }
      }).catch((error) => {
        console.error(error);
        that.$message.error('Erreur lors de la suppression du document');
        return null;
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  getDocument({ dispatch }, { id, type, showLoader = true }) {
    if (showLoader) updateAppLoading(dispatch, true);
    return documentService.getDocument(id, type)
      .finally(() => {
        if (showLoader) updateAppLoading(dispatch, false);
      });
  },
  getMaterialDocuments({ dispatch }, materialId) {
    updateAppLoading(dispatch, true);
    return documentService.getMaterialDocuments(materialId)
      .finally(() => {
        updateAppLoading(dispatch, false);
      });
  }
};

export default {
  namespaced: true,
  actions
};
