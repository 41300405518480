import deviceService from '@/service/deviceService';

const state = {
  appareils: [],
  appareils_cached: {},
  options_appareils: [],
  options_appareils_cached: [],
  options_appareils_modalities: [],
  error: null
};

const mutations = {
  UPDATE_APPAREILS: (state, appareils) => {
    state.appareils = appareils;
  },
  UPDATE_OPTIONS_APPAREILS: (state, appareils) => {
    state.options_appareils = appareils;
  },
  UPDATE_OPTIONS_APPAREILS_CACHED: (state, appareils) => {
    state.options_appareils_cached = appareils;
  },
  UPDATE_OPTIONS_APPAREILS_MODALITIES: (state, appareils) => {
    state.options_appareils_modalities = appareils;
  },
  UPDATE_APPAREILS_CACHED: (state, { appareils, installationId }) => {
    state.appareils_cached[installationId] = appareils;
  },
  UPDATE_ERROR: (state, error) => {
    state.error = error;
  }
};

const actions = {
  filterDevicesByModalitiesIds({
    commit, rootState, state, dispatch
  }) {
    if (!state.options_appareils_modalities.length) {
      commit('UPDATE_OPTIONS_APPAREILS_MODALITIES', state.options_appareils);
    }
    dispatch('filter/updateFieldsLoading', true, { root: true });

    commit('UPDATE_APPAREILS', []);
    commit('UPDATE_ERROR', null);

    const { cabinets } = rootState.office;
    const { selected_modalities } = rootState.modality;
    const devices = !cabinets.length ? state.options_appareils_cached : state.options_appareils_modalities;

    if (!selected_modalities.length) {
      commit('UPDATE_OPTIONS_APPAREILS_MODALITIES', []);
      return dispatch('filterDevicesByOfficeIds');
    }

    dispatch('filterDevicesByOfficeIds')
      .then(() => {
        const filteredDevices = devices.filter(device => {
          return selected_modalities.find(modality => modality.typeDicom.includes(device.typeDicom));
        });

        if (!filteredDevices.length) {
          commit('UPDATE_ERROR', { message: 'Pas d\'appareil trouvé pour cette modalité' });
        }

        commit('UPDATE_OPTIONS_APPAREILS', filteredDevices);
        dispatch('filter/updateFieldsLoading', false, { root: true });
      });
  },
  filterDevicesByOfficeIds({
    commit, rootState, state, dispatch
  }) {
    const offices = rootState.office.cabinets;
    const devices = state.options_appareils_cached;

    commit('UPDATE_APPAREILS', []);
    commit('UPDATE_ERROR', null);

    if (!offices.length) {
      return dispatch('getOptionsAppareils');
    }

    const filteredDevices = devices.filter(device => {
      return !!offices.find(office => office.id_cabinet === device.idRisCabinet);
    });

    if (!filteredDevices.length) {
      commit('UPDATE_ERROR', { message: 'Pas d\'appareil trouvé pour ce cabinet' });
    }

    commit('UPDATE_OPTIONS_APPAREILS', filteredDevices);
  },
  getAppareils({
    commit, rootState, getters, rootGetters
  }, { id_installation, id_cabinet }) {
    const offices = rootState.office.options_cabinets;
    const cabinet = offices.find(({ id }) => id === id_cabinet);
    const cabinetIdRis = cabinet.id_cabinet;
    const installationId = id_installation || rootGetters['installation/defaultSelectedIDInstallation'];
    const cached = getters.appareilsCached(installationId);
    if (cached) {
      commit('UPDATE_APPAREILS', cached);
      return Promise.resolve(cached);
    }
    return deviceService.getAppareils(installationId, cabinetIdRis)
      .then(appareils => {
        commit('UPDATE_APPAREILS', appareils);
        commit('UPDATE_APPAREILS_CACHED', { appareils, installationId });
        return appareils;
      })
      .catch(error => {
        console.error(error);
        return null;
      });
  },
  getOptionsAppareils({
    commit, rootState, rootGetters
  }, id_installation) {
    const that = this._vm;
    const installationId = id_installation || rootGetters['installation/AllIdsInstallations'];
    commit('UPDATE_APPAREILS', []);
    return deviceService.getAppareils(installationId)
      .then(appareils => {
        commit('UPDATE_OPTIONS_APPAREILS', appareils);
        commit('UPDATE_OPTIONS_APPAREILS_CACHED', appareils);

        return appareils;
      })
      .catch(error => {
        console.error(error);
        that.$message.error('Une erreur est survenue lors du chargement des appareils, veuillez réesayer');
        return null;
      });
  }
};

const getters = {
  appareilsCached: (state) => (installationId) => state.appareils_cached[installationId]
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
