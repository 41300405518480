import DataAPI from '../lib/axios';

export default {
  getMaterials(installationId, officeId, active) {
    const params = {
      installation: installationId,
      cabinet: officeId
    };
    if (active) {
      params.actif = active;
    }

    return DataAPI.get('/api/contrat_materiels', {
      params
    }).then(response => response.data);
  },
  getOneMaterial(materialId) {
    return DataAPI.get(`/api/contrat_materiels/${materialId}`)
      .then(response => response.data);
  },
  addMaterial(material) {
    return DataAPI.post('/api/contrat_materiels', material)
      .then(response => response.data);
  },
  updateMaterial(material) {
    return DataAPI.put(`/api/contrat_materiels/${material.id}`, material)
      .then(response => response.data);
  },
  isSerialNumberUnique(serialNumber, materialId) {
    return DataAPI.get('/api/contrat_materiels/is_serial_number_unique', {
      params: {
        serialNumber,
        materialId
      }
    }).then(response => response.data);
  },
  fetchEventsByMaterial(materialId, eventDates, eventType, cqOrCrpType) {
    return DataAPI.get(`/cmm/events/${materialId}`, {
      params: {
        eventDates,
        eventType,
        cqOrCrpType
      }
    }).then(response => response.data);
  },
  savePlannedEvents(materials, dates, eventType, cqOrCrpType) {
    return DataAPI.post('/cmm/events/save', {
      materials,
      dates,
      eventType,
      cqOrCrpType
    }).then(response => response.data);
  }
};
