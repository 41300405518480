import BaseLayout from '@/layout/BaseLayout';
import { getStore } from '@/util/storage';

export default [
  {
    path: '/parametrage',
    component: BaseLayout,
    name: 'parametrage',
    meta: {
      authRequired: true, title: 'Paramétrages', icon: 'fas fa-sliders-h'
    },
    children: [
      {
        path: 'utilisateur',
        component: () => import('@/views/Setting/User/SettingUserEdit'),
        name: 'parametrage/utilisateur',
        meta: {
          authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Gestion des utilisateurs', icon: 'fas fa-user-lock'
        }
      },
      {
        path: 'ux_component',
        component: () => import('@/views/UXComponent/UXComponent'),
        name: 'UX Component',
        meta: {
          authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'UX Component', icon: 'fas fa-fire', css_class: ''
        }
      },
      {
        path: 'dash_admin',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=dash_admin.php`;
        },
        meta: {
          authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Dashboard administrateur', icon: 'fas fa-tachometer-alt'
        }
      },
      {
        path: 'admin',
        component: BaseLayout,
        meta: {
          authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Administration', icon: 'fas fa-globe'
        },
        children: [
          {
            path: 'installation_affichage',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/installation_affichage.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Liste des clients', icon: 'fab fa-black-tie'
            }
          },
          {
            path: 'admin_report_list',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/admin_report_list.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Liste des reporting', icon: 'far fa-list-alt'
            }
          },
          {
            path: 'log_dashboard',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/log_dashboard.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Logs', icon: 'fas fa-clipboard-list'
            }
          },
          {
            path: 'incident_dashboard',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/incident_dashboard.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Support incident', icon: 'fas fa-heartbeat'
            }
          }
        ]
      },
      {
        path: 'admin/rights',
        component: BaseLayout,
        meta: {
          authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Droits', icon: 'fas fa-user-lock'
        },
        children: [
          {
            path: 'groupes/menu_list3',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/groupes/menu_list3.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Menus', icon: 'fas fa-bars'
            }
          },
          {
            path: 'user_list',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/user_list.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Utilisateurs', icon: 'fas fa-user-friends'
            }
          },
          {
            path: 'groupes/groupes_list',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/groupes/groupes_list.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Groupes', icon: 'fas fa-users'
            }
          },
          {
            path: 'fail_to_ban_param',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/fail_to_ban_param.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Fail To Ban', icon: 'fas fa-ban'
            }
          },
          {
            path: 'ff_par_user',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/ff_par_user.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Flash Focus cabinets/users', icon: 'far fa-envelope'
            }
          },
          {
            path: 'droits_user_cabinet',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/droits_user_cabinet.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Utilisateurs - Cabinets', icon: 'fas fa-user-times'
            }
          }
        ]
      },
      {
        path: 'admin/hr',
        component: BaseLayout,
        meta: {
          authRequired: true, title: 'HR Management', icon: 'far fa-calendar-alt'
        },
        children: [
          {
            path: 'remp_liste_examen',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/remp_liste_examen.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Examens', icon: 'fas fa-tachometer-alt'
            }
          },
          {
            path: 'remp_liste_modeles_contrat',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/remp_liste_modeles_contrat.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SETTINGS_REPLACEMENT_CONTRACT', title: 'Modèles contrats', icon: 'far fa-file-word'
            }
          },
          {
            path: 'remp_liste_modeles_mail',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/remp_liste_modeles_mail.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Modèles Mail', icon: 'fas fa-envelope-open-text'
            }
          }
        ]
      },
      {
        path: 'admin/human_resources',
        component: BaseLayout,
        meta: {
          authRequired: true, title: 'Ressources humaines', icon: 'fas fa-briefcase'
        },
        children: [
          {
            path: 'rh_liste_modeles_mail',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/rh_liste_modeles_mail.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SETTINGS_HR_MAIL_TEMPLATE', title: 'Modèles Mail', icon: 'fas fa-envelope-open-text'
            }
          },
          {
            path: 'rh_config_modele',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/rh_config_modele.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SETTINGS_HR_MODEL', title: 'Configuration des modèles', icon: 'fas fa-cog'
            }
          },
          {
            path: 'rh_liste_template_formation',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/rh_liste_template_formation.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SETTINGS_HR_TRAINING', title: 'Configuration des formations', icon: 'fas fa-cog'
            }
          },
          {
            path: 'rh_add_prime',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/rh_add_prime.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SETTINGS_HR_BONUS', title: 'Gestion des primes', icon: 'fas fa-dollar-sign'
            }
          },
          {
            path: 'rh_liste_modeles_pdf',
            beforeEnter: () => {
              window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/rh_liste_modeles_pdf.php`;
            },
            meta: {
              authRequired: true, role: 'ROLE_SETTINGS_HR_PDF_TEMPLATE', title: 'Modèles PDF', icon: 'far fa-file-pdf'
            }
          }
        ]
      },
      {
        path: 'survey/patients/liste_questionnaires',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=admin/survey/patients/liste_questionnaires.php`;
        },
        meta: {
          authRequired: true, role: 'ROLE_SUPER_ADMIN', title: 'Questionnaire Patients', icon: 'far fa-address-book'
        }
      }
    ]
  }
];
