import DataAPI from '../lib/axios';

export default {
  addIncident(form) {
    return DataAPI.post('cmm/incidents/add', form)
      .then(response => {
        return response.data;
      });
  },
  getIncidents(materialId) {
    return DataAPI.get('/api/contrat_materiel_incidents', {
      params: {
        materiel: materialId
      }
    }).then(response => response.data);
  }
};
