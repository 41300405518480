import { clearStore, getStore } from '@/util/storage';
import router from './router/router';
import store from './store/store';

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some((route) => route.meta.authRequired);
  const hasToken = getStore('TOKEN');
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      const canAccessRoute = await store.dispatch('security/getRoutePermission', to.meta?.role);
      const hasRoles = store.getters['security/getRoles'];
      if (hasRoles.length > 0) {
        if (canAccessRoute) {
          next();
        }
      } else {
        try {
          await store.dispatch('security/setUserInfo', hasToken);
          next();
        } catch (error) {
          clearStore('TOKEN');
          clearStore('REFRESH_TOKEN');
          next({ name: 'login' });
        }
      }
    }
  } else {
    if (!authRequired) {
      next();
    } else {
      next({ name: 'login' });
    }
  }
});

router.afterEach((to, from) => {
  store.dispatch('app/updateTypeDashboard', to);
});
