import emlService from '@/service/emlService';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

function uploadEmlDocuments(dispatch, state, eml, targetId) {
  const emlsKeysPossibilities = state.eml_doc_types.map(e => e.typeName);
  const allPromise = [];
  for (let i = 0, len = emlsKeysPossibilities.length; i < len; i++) {
    const type = emlsKeysPossibilities[i];
    const files = eml[type];
    if (files?.length) {
      allPromise.push(dispatch('document/uploadDocuments', { files, type, targetId }, { root: true }));
    }
  }
  return Promise.allSettled(allPromise);
}

const state = {
  emls: [],
  eml_doc_types: []
};

const mutations = {
  UPDATE_EMLS: (state, emls) => {
    emls = !Array.isArray(emls) ? [emls] : emls;
    state.emls = emls;
  },
  UPDATE_EML_DOC_TYPES: (state, eml_doc_types) => {
    state.eml_doc_types = eml_doc_types;
  }
};

const actions = {
  getEmlById({ commit, dispatch }, id) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return emlService.getEmlById(id)
      .then(emls => {
        commit('UPDATE_EMLS', emls);
        return emls.id;
      }).catch(error => {
        console.error(error);
        that.$message.error('Une erreur est survenue lors de l\'ajout de l\'EML');
        return Promise.reject(error);
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  getEmlByMaterialId({ commit, dispatch }, materialId) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return emlService.getEmlByMaterialId(materialId)
      .then(emls => {
        commit('UPDATE_EMLS', emls);
        return emls.id;
      }).catch(error => {
        console.error(error);
        that.$message.error('Une erreur est survenue lors de l\'ajout de l\'EML');
        return Promise.reject(error);
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  createEml({ dispatch }, eml) {
    if (eml.id) {
      return dispatch('updateEml', eml);
    }
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return emlService.createEml(eml)
      .then(async newEml => {
        await uploadEmlDocuments(dispatch, state, eml, newEml.id);
        that.$notify({
          title: 'EML ajouté',
          message: 'Votre EML a bien été ajouté',
          type: 'success'
        });
        return newEml.id;
      }).catch(error => {
        console.error(error);
        that.$message.error('Une erreur est survenue lors de l\'ajout de l\'EML');
        return Promise.reject(error);
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  updateEml({ dispatch }, eml) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return emlService.updateEml(eml)
      .then(updateEml => {
        that.$notify({
          title: 'Eml modifié',
          message: 'Votre Eml a bien été modifié',
          type: 'success'
        });
        return updateEml;
      }).catch(() => {
        that.$message.error('Erreur lors de la modification de l\'Eml');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  getEmlDocTypes({ commit, state }) {
    if (state.eml_doc_types?.length) {
      return Promise.resolve(state.eml_doc_types);
    }
    return emlService.getEmlDocTypes()
      .then(emlDocType => {
        commit('UPDATE_EML_DOC_TYPES', emlDocType);
        return emlDocType;
      });
  },
  getEmlDocuments({ dispatch }, emlId) {
    updateAppLoading(dispatch, true);
    return emlService.getEmlDocuments(emlId)
      .finally(() => {
        updateAppLoading(dispatch, false);
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
