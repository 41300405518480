import DataAPI from '../lib/axios';

export default {
  getAllBusinessPlan(data) {
    let params = {};

    if (data.order) {
      params = {
        title: data.title,
        isActive: data.isActive,
        type: data.type,
        'businessPlanPractices.practice.id': data.businessPlanPractices,
        'businessPlanDevices.device.id': data.businessPlanDevices,
        'order[title]': data.order.title,
        'order[startDate]': data.order.startDate,
        'order[createdAt]': data.order.createdAt,
        'order[updatedAt]': data.order.updatedAt,
        'order[businessPlanPractices.practice.libelle]': data.order.businessPlanPractices,
        'order[businessPlanDevices.device.libelle]': data.order.businessPlanDevices
      };
    } else {
      params = {
        title: data.title,
        isActive: data.isActive,
        type: data.type,
        'businessPlanPractices.practice.id': data.businessPlanPractices,
        'businessPlanDevices.device.id': data.businessPlanDevices
      };
    }
    return DataAPI.get('/api/business_plans/', { params });
  },
  getBusinessPlan(id) {
    return DataAPI.get(`/api/business_plans/${id}`);
  },
  addBusinessPlan(data) {
    return DataAPI.post('/api/business_plans', data);
  },
  updateBusinessPlan(id, data) {
    return DataAPI.put(`/api/business_plans/${id}`, data);
  },
  deleteBusinessPlan(id) {
    return DataAPI.delete(`/api/business_plans/${id}`);
  },

  addBusinessPlanVersion(data) {
    return DataAPI.post('/api/business_plan_versions', data);
  },
  updateBusinessPlanVersion(id, data) {
    return DataAPI.put(`/api/business_plan_versions/${id}`, data);
  },
  deleteBusinessPlanVersions(id) {
    return DataAPI.delete(`/api/business_plan_versions/${id}`);
  },

  getAppareilStats(data) {
    return DataAPI.get(`/api/appareil/${data.id}/stats`, {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
        installationId: data.installationId
      }
    });
  },
  getCabinetStats(data) {
    return DataAPI.get(`/api/cabinet/${data.id}/stats`, {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
        installationId: data.installationId
      }
    });
  }
};
