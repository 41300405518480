import Vue from 'vue';
import Hotjar from 'vue-hotjar';
import Vuelidate from 'vuelidate';
import moment from 'moment';
import vueMoment from 'vue-moment';
import 'moment/locale/fr';
import Notification from 'vue-notification';
import Cookies from 'js-cookie';
import Element from 'element-ui';
import elementLocale from 'element-ui/lib/locale';
import elementLangFr from 'element-ui/lib/locale/lang/fr';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueNumeric from 'vue-numeric';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/util/directive/utilDirective.js';
import App from './App.vue';
import '@/icons/icons';
import '@/permission';
import '@/util/customRoute';
import router from './router/router';
import store from './store/store';
import * as filters from '@/filters/filters';
import Prototype from '@/prototype/prototype';
import '@/assets/scss/_global.scss';
import '@/assets/scss/_colors.scss';
import '@/assets/scss/_buttons.scss';
import '@/assets/scss/_medgest-selector.scss';
import '@/assets/scss/_element-ui-custom.scss';
import '@/assets/scss/element-variables.scss';
import '@/assets/scss/_fontAwesome.scss';
import '@/assets/css/style.css';
import 'leaflet/dist/leaflet.css';

Vue.use(Vuelidate);
Vue.use(Notification, { name: 'alertM' });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(vueMoment, { moment });
Vue.use(Prototype);
Vue.use(VueNumeric);
Vue.use(Hotjar, {
  id: '2306524',
  isProduction: process.env.VUE_APP_HOTJAR === 'true'
});
elementLocale.use(elementLangFr);
Vue.use(Element, {
  // set element-ui default size
  size: Cookies.get('size') || 'medium'
});

Object.keys(filters)
  .forEach((key) => {
    Vue.filter(key, filters[key]);
  });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  data() {
    return {
      messageStr: 'Hello'
    };
  },
  render: (h) => h(App)
}).$mount('#app');

const app = new Vue({
  store
});

if (window.Cypress) {
  window.app = app;
}
