import DataAPI from '../lib/axios';

export default {
  insertObjectives(form) {
    return DataAPI.post('/fixation/objectif/insert', form);
  },

  getObjectiveByType(objectifTarget, page) {
    return DataAPI.get(`/fixation/objectif/${page}`, { params: { objectifTarget } });
  }
};
