import DataAPI from '@/lib/axios';

export default {
  getCS(filters, tab, page, childTab = null) {
    let baseRoute = `/cockpit-synthese/${tab}`;
    if (tab === 'financier') {
      baseRoute += `/${page}`;
    } else if (tab === 'modalite') {
      baseRoute += `/${childTab}`;
    }
    return DataAPI.get(baseRoute, { params: { filters } }).then(res => res.data);
  },
  fetchModalityList(installation) {
    return DataAPI.get('/cockpit-synthese/modalities', {
      params: {
        installation
      }
    })
      .then(res => res.data);
  },
  getGraphExamens(infos) {
    return DataAPI.post('/stats/examen/graph', infos);
  },
  getSocieteGetByInstallation(infos) {
    return DataAPI.post('/stats/societe/get-by-installation', infos);
  },
  getMedecinGetByInstallation(infos) {
    return DataAPI.post('/stats/medecin/get-by-installation', infos);
  },
  getAppareilGetByInstallation(infos) {
    return DataAPI.post('/stats/appareil/get-by-installation', infos);
  },
  filterParams(filters, page = 'appareil') {
    const global = [
      'id_installation',
      'date_debut',
      'date_fin',
      'workday_count',
      'echelle',
      'include_sat',
      'include_sun',
      'displayType'
    ];
    const societe = [...global, 'societe'];
    const cabinet = [...societe, 'cabinet', 'groupe_cabinet'];
    const medecin = [...cabinet, 'medecin', 'type_appareil', 'consolidateSubstitutes'];
    const appareil = [...medecin, 'appareil'];

    const alloweds = {
      global, societe, cabinet, medecin, appareil
    };

    const allowed = alloweds[page];

    return Object.keys(filters)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = filters[key];
        return obj;
      }, {});
  }
};
