import Vue from 'vue';
import VueRouter from 'vue-router';
import app from '@/router/routes/app';
import ces from '@/router/routes/ces';
import feedback from '@/router/routes/feedback';
import investmentDecision from '@/router/routes/investmentDecision';
import businessPlan from '@/router/routes/businessPlan';
import cmm from '@/router/routes/cmm';
import replacement from '@/router/routes/replacement';
import humanResource from '@/router/routes/humanResource';
import education from '@/router/routes/education';
import flashFocus from '@/router/routes/flashFocus';
import settings from '@/router/routes/settings';

Vue.use(VueRouter);

const routes = [
  ...app,
  ...ces,
  ...feedback,
  ...businessPlan,
  ...cmm,
  ...investmentDecision,
  ...humanResource,
  ...education,
  ...replacement,
  ...flashFocus,
  ...settings
];

const router = new VueRouter({ routes });

export default router;
