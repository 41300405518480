import DataAPI from '../lib/axios';

export default {
  getPrevisionnel(infos) {
    return DataAPI.post('/previsionnel/general', infos);
  },
  filterParams(filters) {
    const allowed = [
      'id_installation',
      'societe',
      'cabinet',
      'medecin',
      'appareil',
      'date_debut',
      'date_fin',
      'workday_count',
      'echelle',
      'include_sat',
      'include_sun',
      'weeks',
      'daysWeek',
      'year'
    ];

    const filtered = Object.keys(filters)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = filters[key];
        return obj;
      }, {});

    return filtered;
  }
};
