<template>
    <div :class="{'has-logo': showLogo}"
         data-cy="menu">
        <logo v-if="showLogo"
              :collapse="false">
        </logo>
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu
                :default-active="activeMenu"
                :collapse="false"
                :unique-opened="true"
                :collapse-transition="false"
                :background-color="'#ffffff'"
                :text-color="'#15223c'"
                :active-text-color="'#409eff'"
                mode="vertical">
                <el-tooltip
                    placement="right">
                    <div slot="content">
                        Déclaration d'un incident<br />sur les fonctionnalités de l’application<br />Cockpit Expertise Santé
                    </div>
                    <el-button class="button-incident"
                               type="warning"
                               icon="el-icon-warning-outline"
                               plain
                               @click="openBugReportModal()">
                        Déclarer un incident
                    </el-button>
                </el-tooltip>

                <template v-for="menuRoute in menuRoutes">
                    <sidebar-item
                        v-if="$_menuMixin_isRouteAllowed(menuRoute) && $_menuMixin_showIfChildren(menuRoute)"
                        :key="menuRoute.path"
                        :item="menuRoute"
                        :base-path="menuRoute.path" />
                </template>

                <div class="menu-wrapper"
                     @click="onClickRedirectToMyProfile()">
                    <el-menu-item :index="'my-profile'">
                        <item :icon="'fas fa-user'"
                              :title="'Mon profil'" />
                    </el-menu-item>
                </div>

                <div class="menu-wrapper custom-menu-wrapper"
                     data-cy="submit-logout"
                     @click="logout">
                    <el-menu-item :index="'logout'">
                        <i style="color: #000000"
                           class="fa fa-sign-out-alt"></i>
                        <item :title="'Déconnexion'" />
                    </el-menu-item>
                </div>

                <div class="menu-wrapper menu-footer">
                    <el-menu-item :index="'menu-footer'">
                        <ul>
                            <li>
                                <a :href="medgestUrl"
                                   target="_blank"
                                   v-html="copyright"></a>
                            </li>
                            <li>
                                <a :href="medgestLegalNoticeUrl"
                                   target="_blank">Mentions légales</a>
                            </li>
                            <li>
                                <a :href="medgestConfidentialityPolicy"
                                   target="_blank">Politique de confidentialité</a>
                            </li>
                        </ul>
                    </el-menu-item>
                </div>
            </el-menu>
        </el-scrollbar>
        <BugReportModal ref="bugReportModal" />
    </div>
</template>

<script>
import '@/assets/scss/_sidebar.scss';
import { mapGetters, mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import Logo from '@/layout/components/Sidebar/Logo';
import SidebarItem from '@/layout/components/Sidebar/SidebarItem';
import Item from '@/layout/components/Sidebar/Item';
import menuMixin from '@/mixins/menuMixin';
import BugReportModal from '@/layout/components/BugReport/BugReportModal';

export default {
  components: {
    Item,
    SidebarItem,
    Logo,
    BugReportModal
  },
  mixins: [menuMixin],
  data() {
    return {
      menuRoutes: [],
      medgestUrl: 'https://www.medgest.fr'
    };
  },
  computed: {
    ...mapState('app', ['sidebar']),
    ...mapGetters('security', ['userToken']),
    medgestLegalNoticeUrl() {
      return `${this.medgestUrl}/mentions-legales/`;
    },
    medgestConfidentialityPolicy() {
      return `${this.medgestUrl}/politique-de-confidentialite/`;
    },
    showLogo() {
      return true;
    },
    copyright() {
      return `&#169; MEDGEST ${moment().year()}`;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      if (meta.hidden) {
        return route.matched[route.matched?.length - 2]?.path;
      }
      return path;
    }
  },
  created() {
    this.menuRoutes = cloneDeep(this.$router.options.routes);
  },
  methods: {
    onClickRedirectToMyProfile() {
      window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${this.userToken}&page=mon_profil.php`;
    },
    openBugReportModal() {
      this.$refs.bugReportModal.openModal();
    },
    async logout() {
      await this.$store.dispatch('security/logout', true);
    }
  }
};
</script>

<style lang="scss">
  ul.el-menu {
    li.el-submenu .el-submenu__title > span{
      white-space: pre-line;
      display: inline-block;
      max-width: 60%;
      line-height: 20px;
    }
    .button-incident {
      font-size: 0.95rem;
      margin-top: 15px;
      margin-bottom: 5px;
      margin-left: 15px;
      padding: 12px;
    }
    .el-menu-item {
        line-height: 51.3px;
        height: 51.3px;
    }
    .menu-wrapper {
      &.custom-menu-wrapper {
        i {
          color: #BFCBD9;
          margin-right: 16px;
          font-size: 14px;
          width: 14px;
          height: 14px;
        }
      }
      &.menu-footer {
        border-top: 1px solid $grey;
        .el-menu-item {
          height: 120px;
          margin: 15px 0px;
          text-align: center;
          font-size: 10px;
          background-color: $white !important;
          &:hover {
            background-color: inherit !important;
          }
          ul {
            width: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              width: 100%;
              height: 21px;
              line-height: 21px;
              padding: 0;
              margin: 0;
              a {
                line-height: 21px;
                height: 21px;
                vertical-align: middle;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        &:hover {
          background-color: inherit !important;
        }
      }
    }
  }
</style>
