<template>
    <div :class="classObj"
         class="app-wrapper">
        <div v-if="device === 'mobile'"
             class="drawer-bg" />
        <sidebar class="sidebar-container" />
        <div class="main-container">
            <app-main />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { AppMain, Sidebar } from './components/layout';

export default {
  name: 'Layout',
  components: {
    AppMain,
    Sidebar
  },
  computed: {
    ...mapState('app', ['device']),
    classObj() {
      return {
        mobile: this.device === 'mobile'
      };
    }
  }
};
</script>
<style lang="scss" scoped>
  .app-wrapper {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
