import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/flash_focus',
    component: BaseLayout,
    name: 'flash_focus',
    meta: {
      authRequired: true, title: 'Flash Focus', icon: 'fas fa-rocket'
    },
    redirect: 'dashboard_flash_focus',
    children: [
      {
        path: 'dashboard_flash_focus',
        component: () => import('@/views/FlashFocus/FlashFocusDashboard'),
        name: 'flash_focus/dashboard_flash_focus',
        meta: {
          authRequired: true, role: 'ROLE_FLASH_FOCUS_READ', title: 'Dashboard Flash Focus', icon: 'fas fa-desktop'
        },
        children: [
          {
            path: 'create',
            hidden: true,
            component: () => import('@/views/FlashFocus/Form/FlashFocusForm'),
            name: 'flash_focus/dashboard_flash_focus/create',
            meta: {
              authRequired: true, role: 'ROLE_FLASH_FOCUS_CREATE', hidden: true, title: 'Création'
            }
          },
          {
            path: 'edit',
            hidden: true,
            component: () => import('@/views/FlashFocus/Form/FlashFocusForm'),
            name: 'flash_focus/dashboard_flash_focus/edit',
            meta: {
              authRequired: true, role: 'ROLE_FLASH_FOCUS_UPDATE', hidden: true, title: 'Modification'
            }
          }
        ]
      },
      {
        path: '/flash_focus/detail',
        hidden: true,
        component: () => import('@/views/FlashFocus/FlashFocusDetail'),
        name: 'flash_focus/dashboard_flash_focus/detail',
        meta: { authRequired: true, hidden: true, title: 'Détails' }
      },
      {
        path: '/flash_focus/manual-send',
        hidden: true,
        component: () => import('@/views/FlashFocus/FlashFocusManualSend'),
        name: 'flash_focus/dashboard_flash_focus/envoi_manuel',
        meta: { authRequired: true, hidden: true, title: 'Envoi manuel' }
      }
    ]
  }
];
