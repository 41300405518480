import comparisonService from '@/service/comparisonService';

const state = {
  tab_comparaison: {},
  tab_selected: {
    financier: {
      tabId: 'total'
    },
    nb_dep: {
      tabId: 'dossier'
    },
    pme: {
      tabId: 'avg'
    }
  }
};

const mutations = {
  UPDATE_TAB_COMPARAISON: (state, tabulation) => {
    state.tab_comparaison = tabulation;
  },
  UPDATE_TAB_ACTIVE: (state, tabs) => {
    state.tab_selected = tabs;
  }
};

const actions = {
  getTabs({ commit }) {
    return comparisonService.getTab()
      .then((response) => {
        const { tab } = response.data;
        commit('UPDATE_TAB_COMPARAISON', tab);
        return tab;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  },
  updateTabsActive({ commit }, tabs) {
    commit('UPDATE_TAB_ACTIVE', tabs);
  },
  getGraphTables(tab_comparaison) {
    return comparisonService.getGraphTableForComparaison(tab_comparaison)
      .then((response) => {
        const { tab } = response.data;
        return tab;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
