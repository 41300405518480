import cmmMapMaterialService from '@/service/cmmMapMaterialService';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const actions = {
  async mapFileExists({ dispatch }, {
    installationId, officeId, building, floor
  }) {
    updateAppLoading(dispatch, true);
    const fileExists = await cmmMapMaterialService.mapFileExists(installationId, officeId, building, floor);
    updateAppLoading(dispatch, false);

    return fileExists;
  },
  async getMapData({ commit }, {
    installationId, officeId, building, floor, room
  }) {
    const mapData = await cmmMapMaterialService.getMapData(installationId, officeId, building, floor, room);
    return mapData;
  },
  uploadMap({ dispatch }, { mapFormData }) {
    updateAppLoading(dispatch, true);
    const that = this._vm;
    return cmmMapMaterialService.uploadMap(mapFormData)
      .then((response) => {
        that.$notify({
          title: 'OK',
          message: 'Votre fichier a bien été enregistré',
          type: 'success'
        });
        return response.data;
      })
      .catch((error) => {
        that.$notify({
          title: 'Erreur',
          message: 'Erreur lors de l\'upload de votre fichier',
          type: 'warning'
        });
        console.error(error);
        return null;
      })
      .finally(() => {
        updateAppLoading(dispatch, false);
      });
  }
};

export default {
  namespaced: true,
  actions
};
