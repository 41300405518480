import { render, staticRenderFns } from "./UIButtonCancel.vue?vue&type=template&id=04999fa8&scoped=true&"
import script from "./UIButtonCancel.vue?vue&type=script&lang=js&"
export * from "./UIButtonCancel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04999fa8",
  null
  
)

export default component.exports