import DataAPI from '../lib/axios';

const importUrl = '/api/import_csvs/import';
const contentTypeMultipart = { headers: { 'Content-Type': 'multipart/form-data' } };

export default {
  fetchImportCsvList(filters, sort) {
    const params = {
      ...filters
    };

    if (!filters.type) {
      delete params.type;
    }

    if (sort) {
      const order = sort.order === 'ascending' ? 'asc' : 'desc';
      switch (sort.prop) {
        case 'importCellState':
          sort.prop = 'success';
          break;
        case 'importCellNbValid':
          sort.prop = 'nbRowsImported';
          break;
        case 'typeLabel':
          sort.prop = 'type';
          break;
        case 'installationLibelle':
          sort.prop = 'installation.libelleCourt';
          break;
        case 'createdByUser':
          sort.prop = 'createdBy.nom';
          break;
        case 'createdAtDateTime':
          sort.prop = 'createdAt';
          break;
        case 'updatedAtDateTime':
          sort.prop = 'updatedAt';
          break;
        default:
          break;
      }

      params[`order[${sort.prop}]`] = order;
    }

    return DataAPI.get('/api/import_csvs', {
      params
    }).then(res => res.data);
  },
  fetchImportCsv(importCsvId) {
    return DataAPI.get(`/api/import_csvs/${importCsvId}`);
  },
  downloadImportFile(importCsvId, module) {
    return DataAPI.get(`/api/import_csvs/file/import/${module}/${importCsvId}`, { responseType: 'blob' })
      .then(res => {
        return {
          url: URL.createObjectURL(res.data),
          type: res.data.type,
          name: `import_csv_${importCsvId}.csv`
        };
      });
  },
  downloadOfficeCorrespondenceFile(importCsvId, module) {
    return DataAPI.get(`/api/import_csvs/file/office/${module}/${importCsvId}`, { responseType: 'blob' })
      .then(res => {
        return {
          url: URL.createObjectURL(res.data),
          type: res.data.type,
          name: `cabinets_correspondances_${importCsvId}.csv`
        };
      });
  },
  downloadErrorFile(importCsvId, module) {
    return DataAPI.get(`/api/import_csvs/file/error/${module}/${importCsvId}/txt`, { responseType: 'blob' })
      .then(res => {
        return {
          url: URL.createObjectURL(res.data),
          type: res.data.type,
          name: `erreurs_csv_${importCsvId}`
        };
      });
  },
  downloadExportModality() {
    return DataAPI.get('/api/export/modality', { responseType: 'blob' })
      .then(res => {
        return {
          url: URL.createObjectURL(res.data),
          type: res.data.type,
          name: 'export_modalites.csv'
        };
      });
  },
  getErrorFile(importCsvId, module) {
    return DataAPI.get(`/api/import_csvs/file/error/${module}/${importCsvId}`);
  },
  uploadImportCsv(importCsv, importCsvIdToUpdate) {
    const formData = new FormData();
    formData.append('importType', importCsv.importType);
    if (importCsv.importType !== 'installation') {
      formData.append('installationId', importCsv.installationId);
    }
    formData.append('module', importCsv.module);
    formData.append('file', importCsv.file?.raw);

    let url = `${importUrl}`;
    if (importCsvIdToUpdate) {
      formData.append('_method', 'PUT');
      url += `/${importCsvIdToUpdate}`;
    }
    return DataAPI.post(url, formData, contentTypeMultipart)
      .then(res => res.data);
  }
};
