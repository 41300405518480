import CES from '@/util/constant/constantCesSynthesis';
import COMPARE from '@/util/constant/constantCesCompare';
import CMM from '@/util/constant/constantCmm';
import FLASHFOCUS from '@/util/constant/constantFlashFocus';
import IMPORT_CSV from '@/util/constant/constantImportCsv';

const EXCEL = {
  FORMAT: {
    TEXT: {
      MATCH: ['Date', 'date'],
      VALUE: '@'
    },
    NUMBER: {
      MATCH: ['nb_', 'var_value_nb_', 'Var_nb'],
      VALUE: '# ##0;-# ##0'
    },
    CURRENCY: {
      MATCH: ['var_value_', 'total_ca_', 'ca_', 'mnt_', 'Prix_'],
      VALUE: '# ##0 €;-# ##0 €'
    },
    PERCENT: {
      MATCH: ['var_'],
      VALUE: '0\\%'
    }
  }
};
const DATE_FORMAT = {
  MOMENT: {
    SHOW_IT: 'DD/MM/YYYY',
    VALUE: 'YYYY-MM-DD',
    SENTENCE: {
      SHOW_IT: 'dddd DD MMMM YYYY'
    }
  },
  ELEMENT_UI: {
    SHOW_IT: 'dd/MM/yyyy',
    VALUE: 'yyyy-MM-dd'
  }
};
const DATEPICKER = {
  PICKER_OPTION: {
    firstDayOfWeek: 1
  }
};
const IMAGE = {
  TYPE: {
    'application/pdf': {
      title: 'pdf',
      image: 'icon-pdf.png'
    },
    'application/msword': {
      title: 'doc',
      image: 'icon-docx.png'
    },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      title: 'docx',
      image: 'icon-docx.png'
    },
    'application/vnd.oasis.opendocument.text': {
      title: 'odt',
      image: 'icon-odt.png'
    },
    'application/vnd.ms-excel': {
      title: 'csv',
      image: 'icon-csv.png'
    },
    'text/csv': {
      title: 'csv',
      image: 'icon-csv.png'
    },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      title: 'xlsx',
      image: 'icon-csv.png'
    }
  }
};
const ICON_CLASSES = {
  ICON_CIRCLE: {
    SUCCESS: 'el-icon-success',
    WARNING: 'el-icon-warning',
    DANGER: 'el-icon-error',
    DEFAULT: 'el-icon-time'
  }
};
const MODALITY_COLORS = {
  CR: '#27A2E6',
  US: '#40C083',
  MG: '#FB6285',
  PX: '#9964F8',
  OT: '#8B4513',
  MR: '#FDCD67',
  CT: '#FC9F51',
  CONEBEAM: '#CCB5F5',
  OSTEODENSITOMETRIE: '#BFE1F5'
};
const DASHBOARD = { ...CMM.DASHBOARD, ...FLASHFOCUS.DASHBOARD, ...IMPORT_CSV.DASHBOARD };

export default {
  CES,
  COMPARE,
  CMM,
  FLASHFOCUS,
  IMPORT_CSV,
  DASHBOARD,
  EXCEL,
  DATE_FORMAT,
  DATEPICKER,
  IMAGE,
  ICON_CLASSES,
  MODALITY_COLORS
};
