import materialService from '@/service/materialService';
import documentService from '@/service/documentService';
import material from '@/store/module/material';
import { routeContains } from '@/util/util';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const state = {
  materials: []
};

const mutations = {
  UPDATE_MATERIALS: (state, materials) => {
    state.materials = materials;
  },
  UPDATE_MATERIAL: (state, material) => {
    const oldMat = state.materials.find(item => item.id !== material.id);
    if (oldMat && material) {
      Object.assign(oldMat, material);
    }
  },
  ADD_MATERIAL: (state, material) => {
    state.materials.unshift(material);
  }
};

const actions = {
  getMaterials({ rootGetters, commit }, { id_installation, id_cabinet, actif }) {
    const id = id_installation || rootGetters['installation/defaultSelectedIDInstallation'];
    return materialService.getMaterials(id, id_cabinet, actif)
      .then(materials => {
        if (!id_installation) {
          commit('UPDATE_MATERIALS', materials);
        }
        return materials;
      });
  },
  getOneMaterial({ dispatch }, materialId) {
    updateAppLoading(dispatch, true);
    return materialService.getOneMaterial(materialId)
      .finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  addMaterial({ commit, dispatch }, material) {
    if (material.id) {
      return dispatch('updateMaterial', material);
    }
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return materialService.addMaterial(material)
      .then(material => {
        commit('ADD_MATERIAL', material);
        that.$notify({
          title: 'Matériel ajouté',
          message: 'Votre matériel a bien été ajouté',
          type: 'success'
        });
        return material;
      }).catch(error => {
        console.error(error);
        that.$message.error('Une erreur est survenue lors de l\'ajout de material');
        return Promise.reject(error);
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  updateMaterial({ commit, dispatch }, material) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return materialService.updateMaterial(material)
      .then(material => {
        commit('UPDATE_MATERIAL', material);
        that.$notify({
          title: 'Matériel mis à jour',
          message: 'Votre matériel a bien été mis à jour',
          type: 'success'
        });
        return material;
      }).catch(error => {
        console.error(error);
        that.$message.error('Une erreur est survenue lors de la mise a jour du material');
        return Promise.reject(error);
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  isSerialNumberUnique({ commit }, data) {
    const { serialNumber, materialId } = data;
    return materialService.isSerialNumberUnique(serialNumber, materialId);
  },
  fetchEventsByMaterial({ commit }, data) {
    const {
      materialId,
      eventDates,
      eventType,
      cqOrCrpType
    } = data;

    return materialService.fetchEventsByMaterial(materialId, eventDates, eventType, cqOrCrpType);
  },
  savePlannedEvents({ commit }, data) {
    const {
      materials,
      dates,
      eventType,
      cqOrCrpType
    } = data;

    return materialService.savePlannedEvents(materials, dates, eventType, cqOrCrpType);
  },
  async getMaterialAssetDocuments({ commit, dispatch }, materialId) {
    updateAppLoading(dispatch, true);
    const result = {
      material: await documentService.getMaterialDocuments(materialId),
      contrat: await documentService.getContratFilesByMaterial(materialId),
      eml: await documentService.getEmlFilesByMaterial(materialId),
      maintenance: await documentService.getMaintenanceFilesByMaterial(materialId),
      incident: await documentService.getIncidentFilesByMaterial(materialId),
      qualityControl: await documentService.getQualityControlFilesByMaterial(materialId),
      radioProtectionControl: await documentService.getRadioProtectionControlFilesByMaterial(materialId)
    };
    updateAppLoading(dispatch, false);
    return result;
  }
};

const getters = {
  isReadOnlyMaterial: (state, getters, rootState, rootGetters) => {
    return !rootGetters['security/getRolePermission'].includes('ROLE_GMAO_MATERIAL_UPDATE') && routeContains(['edit']);
  },
  isReadOnlyContact: (state, getters, rootState, rootGetters) => {
    return !rootGetters['security/getRolePermission'].includes('ROLE_GMAO_CONTACT_UPDATE');
  },
  canUpdateMaterialLocation: (state, getters, rootState, rootGetters) => {
    return rootGetters['security/getRolePermission'].includes('ROLE_GMAO_MATERIAL_LOCATION_UPDATE') && !rootGetters['security/getRolePermission'].includes('ROLE_GMAO_MATERIAL_UPDATE');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
