import DataAPI from '../lib/axios';

export default {
  getAppareils(id_installation, id_cabinet) {
    return DataAPI.get('/api/appareils', {
      params: {
        installation: id_installation,
        idRisCabinet: id_cabinet
      }
    }).then(response => {
      return response.data.filter(el => el.obsolete !== '1');
    });
  }
};
