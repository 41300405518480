<template>
    <el-dialog :title="title"
               :visible.sync="visible"
               :show-close="false"
               :destroy-on-close="true"
               custom-class="errorDocumentModal"
               :append-to-body="appendToBody"
               width="70%"
               top="10vh">
        <h4 class="sub-title"
            v-html="subTitle" />

        <div class="container-documents">
            <div v-for="file in files"
                 :key="file.name"
                 class="file-item">
                <div class="avatar">
                    <div v-if="getPreview(file.file_type).title"
                         class="preview-block">
                        <img :src="require(`@/assets/images/${getPreview(file.file_type).image}`)"
                             :alt="getPreview(file.file_type).title">
                    </div>
                    <div v-else
                         class="img-block">
                        <img :src="file.url"
                             :alt="file.name">
                    </div>
                </div>
                <span>{{ file.name }}</span>
            </div>
        </div>

        <span slot="footer"
              class="dialog-footer">
            <el-row>
                <el-col :span="12">
                    <UIButtonCancel @click="closeModal" />
                </el-col>
                <el-col :span="12">
                    <UIButtonValidate
                        :save-icon="true"
                        button-name="Réessayer"
                        @click="tryUploadDocuments" />
                </el-col>
            </el-row>
        </span>
    </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import UIButtonCancel from '@/components/UI/Button/UIButtonCancel';
import UIButtonValidate from '@/components/UI/Button/UIButtonValidate';

export default {
  name: 'CMMDocumentErrorModalView',
  components: {
    UIButtonCancel,
    UIButtonValidate
  },
  props: {
    title: {
      type: String,
      default: 'Problème avec certains documents'
    },
    subTitle: {
      type: String,
      default: 'Les documents suivant n\'ont pas pu être sauvegardés.<br/> Cliquez sur <span class="text-primary">Réessayer</span> pour tenter de les uploader à nouveau.'
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      files: null,
      type: null,
      targetId: null
    };
  },
  computed: {
    getPreview() {
      return type => {
        return { ...this.$CONSTANTS.IMAGE.TYPE[type] };
      };
    }
  },
  methods: {
    ...mapActions('document', ['uploadDocuments']),
    openModal({ files, type, targetId }) {
      this.files = files;
      this.type = type;
      this.targetId = targetId;
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    tryUploadDocuments() {
      const { files, type, targetId } = this;
      this.uploadDocuments({ files, type, targetId });
      this.closeModal();
    }
  }
};
</script>
<style lang='scss'>
.errorDocumentModal {
  .sub-title{
    position: relative;
    top: -40px;
    word-break: break-word;
  }
  .container-documents{
    margin-top: -30px;
    .file-item{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;
      .avatar{
        padding: 10px;
        width: 80px;
        height: 80px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        .preview-block{
          display: flex;
          width: 100%;
          height: 100%;
          background: $grey-extra-light;
          overflow: hidden;
          border-radius: 5px;
          img{
            margin: auto;
            width: 70%;
            height: auto;
          }
        }
        .img-block{
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          width: 100%;
          height: 100%;
          background: $grey-extra-light;
          overflow: hidden;
          border-radius: 5px;
          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .dialog-footer {
    .el-row {
      .el-col {
        display: flex;
        &:first-child {
          justify-content: flex-start;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>
