import axios from 'axios';
import { getStore } from '@/util/storage';
import store from '@/store/store';
import router from '@/router/router';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API
});

service.interceptors.request.use(
  config => {
    if (getStore('TOKEN')) {
      config.headers.Authorization = `Bearer ${getStore('TOKEN')}`;
    }
    // impersonation process
    if (getStore('SWITCH_USER')) {
      config.params = config.params || {};
      config.params._switch_user = getStore('SWITCH_USER');
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

service.interceptors.response.use(
  response => response,
  async error => {
    const { config, response: { status, data } } = error;
    if (status === 401 && data.message === 'Expired JWT Token') {
      await store.dispatch('security/getNewToken');
      if (router.currentRoute.name !== 'login') {
        return Promise.resolve(service(config));
      }
    }
    if (status === 401 && config.url === '/api/security/token/refresh') {
      if (!config?.url?.includes('/security/login')) {
        await store.dispatch('security/logout');
      }
    }
    if (status === 403) {
      if (data.detail === 'unauthorized office') {
        await store.dispatch('office/resetOptionCabinetsCached');
        await store.dispatch('office/getCabinet', { idsInstallation: store.getters['installation/defaultSelectedIDInstallation'] });
      }
      router.push({ name: '403' });
    }
    return Promise.reject(error);
  }
);
export default service;
