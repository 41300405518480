import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/gmao',
    component: BaseLayout,
    name: 'gmao',
    meta: {
      authRequired: true, title: 'GMAO', icon: 'far fa-file-alt', css_class: 'plateau'
    },
    children: [
      {
        path: 'dashboard_parc_materiel',
        component: () => import('@/views/CMM/Dashboard/CMMDashboard'),
        name: 'gmao/dashboard_parc_materiel',
        meta: {
          authRequired: true, role: 'ROLE_GMAO_MATERIAL_READ', managementOfTheTechnicalPlatform: true, title: 'Dashboard Parc Matériel', icon: 'fas fa-laptop-medical', css_class: 'plateau'
        },
        children: [
          {
            path: ':id/edit',
            hidden: true,
            component: () => import('@/views/CMM/Material/CMMMaterialDetail'),
            name: 'gmao/dashboard_parc_materiel/editMaterial',
            meta: {
              hidden: true, role: 'ROLE_GMAO_MATERIAL_READ', edit: true, title: 'Édition'
            }
          },
          {
            path: ':id/map',
            hidden: true,
            component: () => import('@/views/CMM/Map/CMMMapMaterial'),
            name: 'gmao/dashboard_parc_materiel/map_material',
            meta: { hidden: true, edit: true, title: 'Carte matériel' }
          }
        ]
      },
      {
        path: 'financier',
        component: () => import('@/views/CMM/Dashboard/CMMDashboard'),
        name: 'gmao/financier',
        meta: {
          authRequired: true, role: 'ROLE_GMAO_FINANCIAL_READ', managementOfTheTechnicalPlatform: true, title: 'Financier', icon: 'fas fa-dollar-sign', css_class: 'plateau'
        }
      },
      {
        path: 'maintenance_preventive',
        component: () => import('@/views/CMM/Dashboard/CMMDashboard'),
        name: 'gmao/maintenance_preventive',
        meta: {
          authRequired: true, role: 'ROLE_GMAO_MAINTENANCE_READ', managementOfTheTechnicalPlatform: true, title: 'Maintenance Préventive', icon: 'fas fa-wrench', css_class: 'plateau'
        }
      },
      {
        path: 'maintenance_curative',
        component: () => import('@/views/CMM/Dashboard/CMMDashboard'),
        name: 'gmao/maintenance_curative',
        meta: {
          authRequired: true, role: 'ROLE_GMAO_INCIDENT_READ', managementOfTheTechnicalPlatform: true, title: 'Maintenance Curative', icon: 'fas fa-fire', css_class: 'plateau'
        }
      },
      {
        path: 'controle_qualite',
        component: () => import('@/views/CMM/Dashboard/CMMDashboard'),
        name: 'gmao/controle_qualite',
        meta: {
          authRequired: true, role: 'ROLE_GMAO_CQ_READ', managementOfTheTechnicalPlatform: true, title: 'Contrôle Qualité', icon: 'fas fa-check', css_class: 'plateau'
        }
      },
      {
        path: 'controle_radioprotection',
        component: () => import('@/views/CMM/Dashboard/CMMDashboard'),
        name: 'gmao/controle_radioprotection',
        meta: {
          authRequired: true, role: 'ROLE_GMAO_CRP_READ', managementOfTheTechnicalPlatform: true, title: 'Contrôle Radioprotection', icon: 'fas fa-heart', css_class: 'plateau'
        }
      },
      {
        path: 'eml',
        component: () => import('@/views/CMM/Dashboard/CMMDashboard'),
        name: 'gmao/suivi_des_autorisations_eml',
        meta: {
          authRequired: true, role: 'ROLE_GMAO_EML_READ', managementOfTheTechnicalPlatform: true, title: 'Suivi des autorisations EML', icon: 'fas fa-check-square', css_class: 'plateau'
        }
      },
      {
        path: 'contact',
        component: () => import('@/views/CMM/Dashboard/CMMDashboard'),
        name: 'gmao/contact',
        meta: {
          authRequired: true, role: 'ROLE_GMAO_CONTACT_READ', title: 'Contact', icon: 'fas fa-user', css_class: 'plateau'
        }
      },
      {
        path: 'contacts/:id/edit',
        hidden: true,
        component: () => import('@/views/CMM/Contact/CMMContactDetail'),
        name: 'gmao/contact/editContact',
        meta: { edit: true, role: 'ROLE_GMAO_CONTACT_UPDATE', title: 'Édition' }
      },
      {
        path: 'import_csv',
        name: 'gmao/import_csv',
        component: () => import('@/views/ImportCsv/ImportCsvDashboard'),
        meta: {
          authRequired: true, role: 'ROLE_GMAO_CSV_IMPORT_READ', title: 'Import CSV', icon: 'fas fa-upload', css_class: 'plateau'
        },
        children: [
          {
            path: 'detail',
            hidden: true,
            component: () => import('@/views/ImportCsv/ImportCsvDetail'),
            name: 'gmao/import_csv/detail',
            meta: { title: 'Détails de l\'import', role: 'ROLE_GMAO_CSV_IMPORT_READ' }
          },
          {
            path: 'create',
            hidden: true,
            component: () => import('@/views/ImportCsv/ImportCsvForm'),
            name: 'gmao/import_csv/create',
            meta: { title: 'Création d\'import', role: 'ROLE_GMAO_CSV_IMPORT_CREATE' }
          },
          {
            path: 'update',
            hidden: true,
            component: () => import('@/views/ImportCsv/ImportCsvForm'),
            name: 'gmao/import_csv/update',
            meta: { title: 'Modification d\'import', role: 'ROLE_GMAO_CSV_IMPORT_UPDATE' }
          }
        ]
      },
      {
        path: 'import_intelligent_locations',
        name: 'gmao/import_intelligent_locations',
        component: () => import('@/views/CMM/Map/CMMMapMaterialImport'),
        meta: {
          authRequired: true, role: 'ROLE_GMAO_IL_IMPORT_CREATE', title: 'Import Intelligent Location', icon: 'fas fa-upload'
        },
        children: [
        ]
      }
    ]
  }
];
