const MATERIEL = 'materiel';
const MAINTENANCE = 'maintenance';
const INCIDENT = 'incident';
const QUALITY_CONTROL = 'quality_controls';
const RADIOPROTECTION_CONTROL = 'radioprotection_control';
const EML = 'eml';
const FINANCIAL = 'financial';
const CONTACT = 'contact';
const FLASH_FOCUS = 'flash_focus';
const IMPORT_CSV = 'import_csv';

const MATERIEL_SLUG = 'materiel';
const MAINTENANCE_SLUG = 'maintenance';
const INCIDENT_SLUG = 'incident';
const QUALITY_CONTROL_SLUG = 'cq';
const RADIOPROTECTION_CONTROL_SLUG = 'crp';
const EML_SLUG = 'eml';
const FINANCIAL_SLUG = 'contrat';
const CONTACT_SLUG = 'contact';
const FLASH_FOCUS_SLUG = 'flash_focus';
const IMPORT_CSV_SLUG = 'import_csv';

const MATERIEL_PATHNAME = 'materiel';
const MAINTENANCE_PATHNAME = 'maintenance_preventive';
const INCIDENT_PATHNAME = 'maintenance_curative';
const QUALITY_CONTROL_PATHNAME = 'controle_qualite';
const RADIOPROTECTION_CONTROL_PATHNAME = 'controle_radioprotection';
const EML_PATHNAME = 'eml';
const FINANCIAL_PATHNAME = 'financier';
const CONTACT_PATHNAME = 'contact';
const FLASH_FOCUS_PATHNAME = 'flash_focus';
const IMPORT_CSV_PATHNAME = 'import_csv';

const MAINTENANCE_API_ROUTE = '/api/contrat_materiel_maintenances';
const INCIDENT_API_ROUTE = '/api/contrat_materiel_incidents';
const QUALITY_CONTROL_API_ROUTE = '/api/contrat_materiel_cqs';
const RADIOPROTECTION_CONTROL_API_ROUTE = '/api/contrat_materiel_crps';
const EML_API_ROUTE = '/api/contrat_materiel_has_emls';
const FINANCIAL_API_ROUTE = '/api/contrat_contrats';
const CONTACT_API_ROUTE = '/api/contrat_contacts';
const FLASH_FOCUS_API_ROUTE = '/api/flash_focus';
const IMPORT_CSV_API_ROUTE = '/api/import_csvs';

const MATERIEL_DOCUMENT_API_ROUTE = '/api/contrat_document_materiels';
const MAINTENANCE_DOCUMENT_API_ROUTE = '/api/contrat_maintenance_documents';
const INCIDENT_DOCUMENT_API_ROUTE = '/api/contrat_incident_documents';
const QUALITY_CONTROL_DOCUMENT_API_ROUTE = '/api/contrat_c_q_documents';
const RADIOPROTECTION_CONTROL_DOCUMENT_API_ROUTE = '/api/contrat_r_p_documents';
const FINANCIAL_DOCUMENT_API_ROUTE = '/api/contrat_document_contrats';
const EML_DOCUMENT_API_ROUTE = '/api/contrat_eml_has_docs';

const CMM_DEFAULT_EXPANDABLES = ['service', 'category'];
const CMM_DASHBOARD_TABLE_EXPANDABLES_BREAKPOINTS = [2200, 1920, 1600, 1400, 1200, 900, 600];
const CMM_TABLES_FIELDS_IN_CURRENCY = ['globalMaintenanceCost', 'totalFundingAndPurchase', 'totalFundingAndPurchaseUTD', 'totalRentMaintenance', 'totalRentUTDMaintenance', 'totalFundingAndPurchaseRemaining', 'totalRentMaintenanceRemaining'];

const CMM_ROUTE_KEY_FOR_TYPE_DOCUMENTS = {
  contratDocuments: FINANCIAL_SLUG,
  technicalDocuments: MATERIEL,
  devis: EML,
  plan_implantation: EML,
  cv_materiel: EML,
  eml_autres: EML,
  cv_diplome: EML,
  cce: EML,
  plan_locaux: EML,
  bail_locaux: EML,
  rdpm: EML,
  tableau_previ: EML,
  autorisation_finale: EML
};

const CMM_FORM_INFO = {
  maintenance: {
    title: 'Ajouter une maintenance',
    label: 'Date de dernière maintenance',
    tableKey: 'dateMaintenance',
    backRoute: 'contrat_materiel_maintenances',
    titleHistoricElement: 'Historique des maintenances'
  },
  incident: {
    title: 'Ajouter un incident',
    label: 'Date de l\'incident',
    tableKey: 'dateIncident',
    backRoute: 'contrat_materiel_incidents',
    titleHistoricElement: 'Historique des incidents'
  },
  cqi: {
    title: 'Ajouter un CQI',
    label: 'Date de dernier CQI',
    tableKey: 'dateCq',
    backRoute: 'contrat_materiel_cqs'
  },
  cqe: {
    title: 'Ajouter un CQE',
    label: 'Date de dernier CQE',
    tableKey: 'dateCq',
    backRoute: 'contrat_materiel_cqs'
  },
  crpi: {
    title: 'Ajouter un CRPI',
    label: 'Date de dernier CRPI',
    tableKey: 'dateCrp',
    backRoute: 'contrat_materiel_crps'
  },
  crpe: {
    title: 'Ajouter un CRPE',
    label: 'Date de dernier CRPE',
    tableKey: 'dateCrp',
    backRoute: 'contrat_materiel_crps'
  },
  eml: {
    title: 'Ajouter un EML',
    backRoute: ''
  }
};

const DASHBOARD = {
  MATERIEL: {
    STORE: 'cmm',
    NAME: MATERIEL,
    SLUG: MATERIEL_SLUG,
    PATH_NAME: MATERIEL_PATHNAME,
    DOCUMENT_API_ROUTE: MATERIEL_DOCUMENT_API_ROUTE,
    CRUD: {
      ROLE_GMAO_MATERIAL_LOCATION_UPDATE: 'updateMaterial',
      ROLE_GMAO_MATERIEL_EXPORT_READ: 'exportMaterial',
      ROLE_GMAO_MATERIAL_MAP_READ: 'mapMaterial',
      ROLE_GMAO_CONTACT_READ: 'contact'
    },
    EXPANDABLES_COLUMNS: {
      2200: [],
      1920: CMM_DEFAULT_EXPANDABLES,
      1600: CMM_DEFAULT_EXPANDABLES,
      1400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      1200: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      900: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber'])
    },
    EXPORT_NAME: 'Parc matériel'
  },
  MAINTENANCE: {
    STORE: 'cmm',
    NAME: MAINTENANCE,
    SLUG: MAINTENANCE_SLUG,
    PATH_NAME: MAINTENANCE_PATHNAME,
    API_ROUTE: MAINTENANCE_API_ROUTE,
    DOCUMENT_API_ROUTE: MAINTENANCE_DOCUMENT_API_ROUTE,
    CRUD: {
      ROLE_GMAO_MAINTENANCE_CREATE: 'create',
      ROLE_GMAO_MAINTENANCE_UPDATE: 'editEventDate',
      ROLE_GMAO_CONTACT_READ: 'contact'
    },
    HISTORIC_CRUD: {
      ROLE_GMAO_MAINTENANCE_UPDATE: 'editRow',
      ROLE_GMAO_MAINTENANCE_DELETE: 'delete',
      ROLE_GMAO_MAINTENANCE_READ: 'exportMaterialControlContract'
    },
    EXPANDABLES_COLUMNS: {
      2200: CMM_DEFAULT_EXPANDABLES,
      1920: CMM_DEFAULT_EXPANDABLES,
      1600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      1400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'totalUnavailabilityMaintenance']),
      1200: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalUnavailabilityMaintenance']),
      900: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalUnavailabilityMaintenance']),
      600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalUnavailabilityMaintenance', 'dateMaintenance', 'dateFin']),
      400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalUnavailabilityMaintenance', 'dateMaintenance', 'dateFin'])
    },
    DELETE_MSG: 'La maintenance et les documents associés ont été supprimés',
    EXPORT_NAME: 'Maintenances Préventives',
    HISTORIC_EXPORT_FIEDLS: [
      {
        key: 'dateMaintenance',
        label: 'Date de maintenance',
        sortable: true
      },
      {
        key: 'indisponibilite',
        label: 'Indisponibilité (h)',
        sortable: true
      },
      {
        key: 'cost',
        label: 'Coût (€)',
        sortable: true
      },
      {
        key: 'serialNumber',
        label: 'Numéro de série',
        sortable: true
      },
      {
        key: 'controlResultName',
        label: 'Conformité',
        sortable: true
      },
      {
        key: 'countNbForMaintenanceDocuments',
        label: 'Documents associés',
        sortable: true
      },
      {
        key: 'createdBy',
        label: 'Créé par',
        sortable: true
      }
    ]
  },
  INCIDENT: {
    STORE: 'cmm',
    NAME: INCIDENT,
    SLUG: INCIDENT_SLUG,
    PATH_NAME: INCIDENT_PATHNAME,
    API_ROUTE: INCIDENT_API_ROUTE,
    DOCUMENT_API_ROUTE: INCIDENT_DOCUMENT_API_ROUTE,
    CRUD: {
      ROLE_GMAO_INCIDENT_CREATE: 'create',
      ROLE_GMAO_CONTACT_READ: 'contact'
    },
    HISTORIC_CRUD: {
      ROLE_GMAO_INCIDENT_UPDATE: 'editRow',
      ROLE_GMAO_CONTACT_DELETE: 'delete'
    },
    EXPANDABLES_COLUMNS: {
      2200: [],
      1920: CMM_DEFAULT_EXPANDABLES,
      1600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      1400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'totalUnavailabilityIncident']),
      1200: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalUnavailabilityIncident']),
      900: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalUnavailabilityIncident']),
      600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalUnavailabilityIncident', 'dateIncident', 'dateFin']),
      400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalUnavailabilityIncident', 'dateIncident', 'dateFin'])
    },
    DELETE_MSG: 'L\'incident et les documents associés ont été supprimés',
    EXPORT_NAME: 'Maintenances Curatives',
    HISTORIC_EXPORT_FIEDLS: [
      {
        key: 'dateIncident',
        label: 'Date de maintenance curative',
        sortable: true
      },
      {
        key: 'motif',
        label: 'Motif',
        sortable: true
      },
      {
        key: 'indisponibilite',
        label: 'Indisponibilité (h)',
        sortable: true
      },
      {
        key: 'cost',
        label: 'Coût (€)',
        sortable: true
      },
      {
        key: 'serialNumber',
        label: 'Numéro de série',
        sortable: true
      },
      {
        key: 'countNbForIncidentDocuments',
        label: 'Documents associés',
        sortable: true
      },
      {
        key: 'createdBy',
        label: 'Créé par',
        sortable: true
      }
    ]
  },
  QUALITY_CONTROL: {
    STORE: 'cmm',
    NAME: QUALITY_CONTROL,
    SLUG: QUALITY_CONTROL_SLUG,
    PATH_NAME: QUALITY_CONTROL_PATHNAME,
    API_ROUTE: QUALITY_CONTROL_API_ROUTE,
    DOCUMENT_API_ROUTE: QUALITY_CONTROL_DOCUMENT_API_ROUTE,
    CRUD: {
      ROLE_GMAO_CQ_CREATE: 'create',
      ROLE_GMAO_CQ_UPDATE: 'editEventDate',
      ROLE_GMAO_CONTACT_READ: 'contact'
    },
    HISTORIC_CRUD: {
      ROLE_GMAO_CQ_UPDATE: 'editRow',
      ROLE_GMAO_CQ_DELETE: 'delete',
      ROLE_GMAO_CQ_READ: 'exportMaterialControlContract'
    },
    EXPANDABLES_COLUMNS: {
      2200: [],
      1920: CMM_DEFAULT_EXPANDABLES,
      1600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      1400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      1200: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      900: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateCqi', 'dateCqe']),
      600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateCqi', 'dateCqe']),
      400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateCqi', 'dateCqe', 'dateFin'])
    },
    DELETE_MSG: 'Le contrôle qualité et les documents associés ont été supprimés',
    EXPORT_NAME: 'Contrôles Qualité',
    HISTORIC_EXPORT_FIEDLS: [
      {
        key: 'dateCq',
        label: 'Date de contrôle qualité',
        sortable: true
      },
      {
        key: 'type',
        label: 'Type',
        sortable: true
      },
      {
        key: 'indisponibilite',
        label: 'Indisponibilité (h)',
        sortable: true
      },
      {
        key: 'cost',
        label: 'Coût (€)',
        sortable: true
      },
      {
        key: 'serialNumber',
        label: 'Numéro de série',
        sortable: true
      },
      {
        key: 'controlResultName',
        label: 'Conformité',
        sortable: true
      },
      {
        key: 'countNbForCqDocuments',
        label: 'Documents associés',
        sortable: true
      },
      {
        key: 'createdBy',
        label: 'Créé par',
        sortable: true
      }
    ]
  },
  RADIOPROTECTION_CONTROL: {
    STORE: 'cmm',
    NAME: RADIOPROTECTION_CONTROL,
    SLUG: RADIOPROTECTION_CONTROL_SLUG,
    PATH_NAME: RADIOPROTECTION_CONTROL_PATHNAME,
    API_ROUTE: RADIOPROTECTION_CONTROL_API_ROUTE,
    DOCUMENT_API_ROUTE: RADIOPROTECTION_CONTROL_DOCUMENT_API_ROUTE,
    CRUD: {
      ROLE_GMAO_CRP_CREATE: 'create',
      ROLE_GMAO_CRP_UPDATE: 'editEventDate',
      ROLE_GMAO_CONTACT_READ: 'contact'
    },
    HISTORIC_CRUD: {
      ROLE_GMAO_CRP_UPDATE: 'editRow',
      ROLE_GMAO_CRP_DELETE: 'delete',
      ROLE_GMAO_CRP_READ: 'exportMaterialControlContract'
    },
    EXPANDABLES_COLUMNS: {
      2200: [],
      1920: CMM_DEFAULT_EXPANDABLES,
      1600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      1400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      1200: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      900: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateCrpi', 'dateCrpe']),
      600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateCrpi', 'dateCrpe']),
      400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateCrpi', 'dateCrpe', 'dateFin'])
    },
    DELETE_MSG: 'Le contrôle qualité et les documents associés ont été supprimés',
    EXPORT_NAME: 'Contrôles Radioprotection',
    HISTORIC_EXPORT_FIEDLS: [
      {
        key: 'dateCrp',
        label: 'Date de contrôle radioprotection',
        sortable: true
      },
      {
        key: 'type',
        label: 'Type',
        sortable: true
      },
      {
        key: 'indisponibilite',
        label: 'Indisponibilité (h)',
        sortable: true
      },
      {
        key: 'cost',
        label: 'Coût (€)',
        sortable: true
      },
      {
        key: 'serialNumber',
        label: 'Numéro de série',
        sortable: true
      },
      {
        key: 'controlResultName',
        label: 'Conformité',
        sortable: true
      },
      {
        key: 'countNbForCrpDocuments',
        label: 'Documents associés',
        sortable: true
      },
      {
        key: 'createdBy',
        label: 'Créé par',
        sortable: true
      }
    ]
  },
  EML: {
    STORE: 'cmm',
    NAME: EML,
    SLUG: EML_SLUG,
    PATH_NAME: EML_PATHNAME,
    API_ROUTE: EML_API_ROUTE,
    DOCUMENT_API_ROUTE: EML_DOCUMENT_API_ROUTE,
    CRUD: {
      ROLE_GMAO_EML_CREATE: 'create',
      ROLE_GMAO_CONTACT_READ: 'contact'
    },
    HISTORIC_CRUD: {
      ROLE_GMAO_EML_UPDATE: 'update',
      ROLE_GMAO_EML_DELETE: 'delete'
    },
    EXPANDABLES_COLUMNS: {
      2200: [],
      1920: CMM_DEFAULT_EXPANDABLES,
      1600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      1400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      1200: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateEmlWindow', 'dateEmlRenew']),
      900: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateEmlWindow', 'dateEmlRenew']),
      600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateEmlWindow', 'dateEmlRenew', 'dateEmlEcheance']),
      400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateEmlWindow', 'dateEmlRenew', 'dateEmlEcheance'])
    },
    DELETE_MSG: 'L\'EML et les documents associés ont été supprimés',
    EXPORT_NAME: 'EML'
  },
  FINANCIAL: {
    STORE: 'cmm',
    NAME: FINANCIAL,
    SLUG: FINANCIAL_SLUG,
    PATH_NAME: FINANCIAL_PATHNAME,
    API_ROUTE: FINANCIAL_API_ROUTE,
    GROUP_HEADER: ['totalRentUTDFunding', 'totalRentUTDMaintenance', 'globalMaintenanceCost'],
    EXPANDABLES_COLUMNS: {
      2200: [],
      1920: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'serialNumber']),
      1600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'serialNumber']),
      1400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      1200: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      900: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalRentUTDFunding', 'totalRentUTDMaintenance', 'globalMaintenanceCost']),
      600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalRentUTDFunding', 'totalRentUTDMaintenance', 'globalMaintenanceCost']),
      400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'totalRentUTDFunding', 'totalRentUTDMaintenance', 'globalMaintenanceCost'])
    },
    HISTORIC_EXPANDABLES_COLUMNS: {
      2200: ['prestataire', 'comment'],
      1920: ['prestataire', 'comment'],
      1600: ['prestataire', 'comment'],
      1400: ['prestataire', 'comment', 'endDate'],
      1200: ['prestataire', 'comment', 'startDate', 'endDate'],
      900: ['prestataire', 'comment', 'startDate', 'endDate'],
      600: ['prestataire', 'comment', 'startDate', 'endDate'],
      400: ['prestataire', 'comment', 'startDate', 'endDate']
    },
    CRUD: {
      ROLE_GMAO_CONTRAT_CREATE: 'create',
      ROLE_GMAO_CONTACT_READ: 'contact'
    },
    HISTORIC_CRUD: {
      ROLE_GMAO_CONTRAT_UPDATE: 'update',
      ROLE_GMAO_CONTRAT_DELETE: 'delete'
    },
    DOCUMENT_API_ROUTE: FINANCIAL_DOCUMENT_API_ROUTE,
    CHILDRENS: {
      SLUG: 'rents',
      HISTORIC_CRUD: {
        ROLE_GMAO_RENT_UPDATE: 'update',
        ROLE_GMAO_RENT_DELETE: 'delete'
      },
      API_ROUTE: '/api/contrat_rents'
    },
    DELETE_MSG: 'Le contrat et les documents associés ont été supprimés',
    EXPORT_NAME: 'Contrats'
  },
  CONTACT: {
    STORE: 'cmm',
    NAME: CONTACT,
    SLUG: CONTACT_SLUG,
    PATH_NAME: CONTACT_PATHNAME,
    API_ROUTE: CONTACT_API_ROUTE,
    DOCUMENT_API_ROUTE: null,
    CRUD: {
      ROLE_GMAO_CONTACT_UPDATE: 'updateContact',
      ROLE_GMAO_CONTACT_DELETE: 'delete'
    },
    EXPANDABLES_COLUMNS: {
      2200: [],
      1920: [],
      1600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      1400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite']),
      1200: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber']),
      900: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateFinGarantie']),
      600: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateFinGarantie']),
      400: CMM_DEFAULT_EXPANDABLES.concat(['marque', 'labelModalite', 'serialNumber', 'dateMes'])
    },
    EXPORT_NAME: 'Liste de contacts'
  },
  FLASH_FOCUS: {
    STORE: 'flashFocus',
    NAME: FLASH_FOCUS,
    SLUG: FLASH_FOCUS_SLUG,
    PATH_NAME: FLASH_FOCUS_PATHNAME,
    API_ROUTE: FLASH_FOCUS_API_ROUTE,
    CRUD: {
      ROLE_GMAO_FF_DELETE: 'delete'
    }
  },
  IMPORT_CSV: {
    STORE: 'importCsv',
    NAME: IMPORT_CSV,
    SLUG: IMPORT_CSV_SLUG,
    PATH_NAME: IMPORT_CSV_PATHNAME,
    API_ROUTE: IMPORT_CSV_API_ROUTE,
    CRUD: []
  }
};

const IL_STATUS = {
  accepted: ['IN USE', 'AVAILABLE', 'UNKNOWN'],
  translation: {
    french: {
      'IN USE': 'Occupé',
      AVAILABLE: 'Libre',
      UNKNOWN: 'Inconnu'
    }
  },
  css_class: {
    'IN USE': 'danger',
    AVAILABLE: '',
    UNKNOWN: 'info'
  }
};

export default {
  DASHBOARD,
  CMM_FORM_INFO,
  CMM_ROUTE_KEY_FOR_TYPE_DOCUMENTS,
  CMM_DASHBOARD_TABLE_EXPANDABLES_BREAKPOINTS,
  CMM_TABLES_FIELDS_IN_CURRENCY,
  IL_STATUS
};
