import jwt_decode from 'jwt-decode';
import router from '@/router/router';
import securityService from '@/service/securityService';
import { clearStore, getStore, setStore } from '@/util/storage';
import { getFullUserRoles } from '@/util/util';

const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
const ROLE_LINET = 'ROLE_LINET';
const TOKEN = 'TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const USER = 'USER';
const SWITCH_USER = 'SWITCH_USER';

const state = {
  user: null,
  token: getStore(TOKEN),
  roles: []
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    setStore(TOKEN, token);
  },
  SET_REFRESH_TOKEN: (state, refresh_token) => {
    setStore(REFRESH_TOKEN, refresh_token);
  },
  SET_USER_INFO(state, infos) {
    const { user } = infos;
    state.user = user;
    state.roles = user.roles;
    setStore(USER, user);
  },
  LOGIN_FAILURE(state) {
    state.token = null;
    clearStore(TOKEN);
    clearStore(USER);
  },
  LOGOUT(state) {
    state.user = null;
    state.token = null;
    state.roles = [];
    clearStore(TOKEN);
    clearStore(REFRESH_TOKEN);
    clearStore(USER);
    clearStore(SWITCH_USER);
  },
  SET_SWITCH_USER_ROUTES(state, routes) {
    state.routes = routes;
  },
  SET_SWITCH_USER_ROLE(state, roles) {
    state.roles = roles;
  },
  SET_SWITCH_USER_LOGIN: (state, switchUserLogin) => {
    setStore('SWITCH_USER', switchUserLogin);
  }
};

const actions = {
  login({ commit, dispatch }, payload) {
    return securityService.login({ username: payload.login, password: payload.password })
      .then(async response => {
        commit('SET_TOKEN', response.token);
        commit('SET_REFRESH_TOKEN', response.refresh_token);
        dispatch('setUserInfo', response.token);
      });
  },
  getNewToken({ commit }) {
    return securityService.refreshToken(getStore(REFRESH_TOKEN))
      .then(async response => {
        commit('SET_TOKEN', response.token);
        commit('SET_REFRESH_TOKEN', response.refresh_token);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async setUserInfo({ commit, dispatch, rootGetters }, token) {
    const decodedToken = jwt_decode(token);
    commit('SET_USER_INFO', decodedToken);
    await dispatch('installation/initState', null, { root: true });
    await dispatch('office/resetCacheOptionsGroupeCabinets', null, { root: true });
  },
  getRoutePermission({ commit }, role) {
    if (!role) {
      return true;
    }

    return securityService.getRoutePermission(role)
      .then(response => {
        return response.status === 200;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  errorLogin({ commit }) {
    commit('LOGIN_FAILURE', 'Identifiants incorrects');
  },
  logout({ commit }) {
    commit('LOGOUT');
    router.push({ name: 'login' });
  },
  allowedTo({ state, getters, rootState }, actionName) {
    const { CRUD, STORE } = rootState.app.type_dashboard;

    if (STORE === 'cmm') {
      return CRUD.includes(actionName);
    }

    return true;
  },
  setSwitchUserRoutes({ commit, dispatch }, switchUserLogin) {
    commit('SET_SWITCH_USER_LOGIN', switchUserLogin);
    return securityService.switchUser()
      .then(response => {
        commit('SET_SWITCH_USER_ROLE', response.user.roles);
        dispatch('installation/initState', null, { root: true });
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const getters = {
  getRolePermission(state) {
    return getFullUserRoles(state.roles);
  },
  getRoles(state) {
    return state.roles;
  },
  userToken(state) {
    const storeToken = getStore(TOKEN);
    const urlParams = new URLSearchParams(window.location.href);
    const queryToken = urlParams.get('token') || router.currentRoute?.query?.token;
    const { token } = state;
    return !!token ? token : (!!queryToken) ? queryToken : (!!storeToken) ? storeToken : undefined;
  },
  getUser() {
    return state.user;
  },
  userIsAdmin(state) {
    return state.roles.includes(ROLE_SUPER_ADMIN);
  },
  userIsLinet(state) {
    return state.roles.includes(ROLE_LINET);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
