import { mapGetters, mapActions } from 'vuex';
import rolesHierarchy from '@/util/constant/rolesHierarchy.json';

export default {
  computed: {
    ...mapGetters('security', ['userIsAdmin', 'getRolePermission'])
  },
  methods: {
    $_menuMixin_isRouteAllowed(route) {
      if (!route || this.userIsAdmin) {
        return true;
      }

      if (route.meta && route.meta.role && this.getRolePermission.includes(route.meta.role)) {
        return true;
      }

      return !route.meta || !route.meta.role;
    },
    $_menuMixin_showIfChildren(route) {
      const childrenToDisplay = [];
      const fullRoles = this.getRolePermission;
      function findIfChildrenToDisplay(route) {
        route.children.forEach(children => {
          if ((children.meta && 'role' in children.meta) && fullRoles.includes(children.meta.role)) {
            childrenToDisplay.push(children);
          } else if (children && children.children && (children.meta && !('role' in children.meta))) {
            findIfChildrenToDisplay(children);
          }
        });
      }
      if (route && route.children && (route.meta && !('role' in route.meta))) {
        findIfChildrenToDisplay(route);
        return childrenToDisplay.length !== 0;
      }
      return true;
    }
  }
};
