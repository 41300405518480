<template>
    <div class="sidebar-logo-container">
        <router-link key="expand"
                     class="sidebar-logo-link"
                     to="/">
            <img v-if="logo"
                 :src="logo"
                 class="sidebar-logo" />
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  data() {
    return {
      title: '',
      logo: 'logo-full.png'
    };
  }
};
</script>

<style lang="scss" scoped>
  .sidebar-logo-container {
    position: relative;
    width: 100%;
    height: 105px;
    line-height: 105px;
    border-bottom: 1px solid $grey;
    background-color: $white;
    text-align: center;
    overflow: hidden;
    .sidebar-logo-link {
      height: 100%;
      width: 100%;
      display: flex;
      .sidebar-logo {
        margin: auto;
        height: 60%;
        vertical-align: middle;
      }
      .sidebar-title {
        display: inline-block;
        margin: 0;
        color: #fff;
        font-weight: 600;
        line-height: 50px;
        font-size: 14px;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        vertical-align: middle;
      }
    }
  }
</style>
