<template>
    <el-dialog title="Déclarer un incident"
               :visible.sync="visible"
               :show-close="false"
               :destroy-on-close="true"
               :append-to-body="true"
               custom-class="bugReportModal"
               width="40%"
               top="10vh">
        <BugReportAddForm ref="bugReportForm"
                          @closeModal="closeModal" />
        <span slot="footer"
              class="dialog-footer">
            <el-row>
                <el-col :span="12">
                    <UIButtonCancel @click="closeModal" />
                </el-col>
                <el-col :span="12">
                    <el-col>
                        <UIButtonValidate :save-icon="true"
                                          buttonName="Envoyer incident"
                                          @click="save" />
                    </el-col>
                </el-col>
            </el-row>
        </span>
    </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import UIButtonValidate from '@/components/UI/Button/UIButtonValidate';
import UIButtonCancel from '@/components/UI/Button/UIButtonCancel';
import BugReportAddForm from '@/layout/components/BugReport/BugReportAddForm';

export default {
  name: 'BugReportModal',
  components: {
    UIButtonValidate,
    UIButtonCancel,
    BugReportAddForm
  },
  data() {
    return {
      visible: false
    };
  },
  created() {
    this.getModules();
  },
  methods: {
    ...mapActions('module', ['getModules']),
    async save() {
      await this.$refs.bugReportForm?.validForm();
    },
    openModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    }
  }
};
</script>
<style lang='scss'>
  .bugReportModal {
    min-width: 600px;
    padding: 20px;
    .el-form-item{
      display: flex;
      align-items: center;
      .el-form-item__label{
        line-height: 16px;
        word-break: normal;
        text-align: left;
        width: 30%;
      }
      .el-form-item__content{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      .el-select{
        width: 100%;
      }
    }
    .el-dialog__body{
      padding: 20px;
      .el-row{
        .el-col{
          padding: 0 20px;
        }
      }
    }
    .dialog-footer {
      .el-row {
        .el-col {
          display: flex;
          &:last-child {
            justify-content: end;
          }
        }
      }
    }
  }
</style>
