import medicalClassService from '@/service/medicalClassService';

const state = {
  medicalClasses: []
};

const mutations = {
  SET_MEDICAL_CLASSES: (state, medicalClasses) => {
    state.medicalClasses = medicalClasses;
  }
};

const actions = {
  getMedicalClasses({ state, commit }) {
    if (state.medicalClasses?.length) {
      return Promise.resolve(state.medicalClasses);
    }
    return medicalClassService.getMedicalClasses()
      .then(medicalClasses => {
        commit('SET_MEDICAL_CLASSES', medicalClasses);
        return medicalClasses;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
