<template>
    <div v-if="!item.hidden"
         class="menu-wrapper">
        <template
            v-if="
                hasOneShowingChild(item.children, item) &&
                    (!onlyOneChild.children || onlyOneChild.noShowingChildren)
            ">
            <app-link v-if="onlyOneChild.meta"
                      :to="resolvePath(onlyOneChild.path)">
                <el-menu-item :index="resolvePath(onlyOneChild.path)"
                              :class="{'submenu-title-noDropdown': !isNest}">
                    <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
                          :title="onlyOneChild.meta.title" />
                </el-menu-item>
            </app-link>
        </template>

        <el-submenu v-else
                    ref="subMenu"
                    :index="resolvePath(item.path)"
                    popper-append-to-body
                    :class="item.meta.css_class">
            <template slot="title">
                <item v-if="item.meta"
                      :icon="item.meta && item.meta.icon"
                      :title="item.meta.title" />
            </template>
            <template v-for="child in item.children">
                <sidebar-item
                    v-if="$_menuMixin_isRouteAllowed(child) && $_menuMixin_showIfChildren(child)"
                    :key="child.path"
                    :is-nest="true"
                    :item="child"
                    :base-path="resolvePath(child.path)"
                    class="nest-menu" />
            </template>
        </el-submenu>
    </div>
</template>

<script>
import path from 'path';
import Item from './Item';
import AppLink from './Link';
import menuMixin from '@/mixins/menuMixin';

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [menuMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        }
        // Temp set(will be used if only has one showing child)
        this.onlyOneChild = item;
        return true;
      });
      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      return path.resolve(this.basePath, routePath);
    }
  }
};
</script>

<style lang="scss">
  .financier, .is-opened.financier {
    .el-submenu__title, .el-menu-item {
      background-color: $menuFinancialBg !important;
      &, i {
        color: #15223c !important;
      }
    }
  }
  .financier {
    .submenu-title-noDropdown,
    .el-submenu__title {
      &:hover {
        background-color: $menuFinancialHover !important;
      }
    }
    & .nest-menu .el-submenu > .el-submenu__title,
    & .el-menu .el-menu-item {
      background-color: $subMenuFinancialBg !important;
      &.is-active {
        color: #409eff !important;
      }
      &:hover {
        background-color: $subMenuFinancialHover !important;
      }
    }
  }

  .plateau, .is-opened.plateau {
    .el-submenu__title, .el-menu-item {
      background-color: $menuTechnicalPlatformBg !important;
      &, i {
        color: #15223c !important;
      }
    }
  }

  .plateau {
    .submenu-title-noDropdown,
    .el-submenu__title {
      &:hover {
        background-color: $menuTechnicalPlatformHover !important;
      }
    }
    & .nest-menu .el-submenu > .el-submenu__title,
    & .el-menu .el-menu-item {
      background-color: $subMenuTechnicalPlatformBg !important;
      &.is-active {
        color: #409eff !important;
      }
      &:hover {
        background-color: $subMenuTechnicalPlatformHover !important;
      }
    }
  }

  .rh, .is-opened.rh {
    .el-submenu__title, .el-menu-item{
      background-color: $menuHumanResourceBg !important;
      &, i {
        color: #15223c !important;
      }
    }
  }

  .rh {
    .submenu-title-noDropdown,
    .el-submenu__title {
      &:hover {
        background-color: $menuHumanResourceHover !important;
      }
    }
    .is-active > .el-submenu__title {
      color: #15223c !important;
    }
    & .nest-menu .el-submenu > .el-submenu__title,
    & .el-menu .el-menu-item {
      background-color: $subMenuHumanResourceBg !important;
      &.is-active {
        color: #409eff !important;
      }
      &:hover {
        background-color: $subMenuHumanResourceHover !important;
      }
    }
  }

  .replacement, .is-opened.replacement {
    .el-submenu__title, .el-menu-item{
      background-color: $menuReplacementBg !important;
      &, i {
        color: #15223c !important;
      }
    }
  }

  .replacement {
    .submenu-title-noDropdown,
    .el-submenu__title {
      &:hover {
        background-color: $menuReplacementHover !important;
      }
    }
    .is-active > .el-submenu__title {
      color: #15223c !important;
    }
    & .nest-menu .el-submenu > .el-submenu__title,
    & .el-menu .el-menu-item {
      background-color: $subMenuReplacementBg !important;
      &.is-active {
        color: #409eff !important;
      }
      &:hover {
        background-color: $subMenuReplacementHover !important;
      }
    }
  }
</style>
