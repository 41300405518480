import DataAPI from '../lib/axios';

export default {
  getContrat(id) {
    return DataAPI.get(`/api/contrat_contrats/${id}`)
      .then(res => res.data);
  },
  getContratByMaterialId(materialId, sort) {
    const params = {
      materiel: materialId
    };

    if (sort) {
      const order = sort.order === 'ascending' ? 'asc' : 'desc';
      switch (sort.prop) {
        case 'startDate':
          sort.prop = 'dateDebut';
          break;
        case 'endDate':
          sort.prop = 'dateFin';
          break;
        case 'contratType':
          sort.prop = 'contratType.name';
          break;
        default:
          break;
      }

      params[`order[${sort.prop}]`] = order;
    }

    return DataAPI.get('/api/contrat_contrats', { params }).then(res => res.data);
  },
  getContratTypes() {
    return DataAPI.get('/api/contrat_contrat_types')
      .then(res => res.data);
  },
  createContrat(contrat) {
    return DataAPI.post('/api/contrat_contrats', contrat)
      .then(res => res.data);
  },
  updateContrat(contrat) {
    return DataAPI.put(`/api/contrat_contrats/${contrat.id}`, contrat)
      .then(res => res.data);
  },
  getContratDocuments(contratId) {
    if (!contratId) {
      return Promise.resolve([]);
    }
    return DataAPI.get('/api/contrat_document_contrats', {
      params: {
        contrat: contratId
      }
    }).then(res => res.data);
  }
};
