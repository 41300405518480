import DataAPI from '../lib/axios';

export default {
  getEmlById(id) {
    return DataAPI.get(`/api/contrat_materiel_has_emls/${id}`)
      .then(res => res.data);
  },
  getEmlByMaterialId(materialId) {
    return DataAPI.get('/api/contrat_materiel_has_emls', {
      params: {
        materiel: materialId
      }
    }).then(res => res.data);
  },
  createEml(eml) {
    return DataAPI.post('/api/contrat_materiel_has_emls', eml)
      .then(res => res.data);
  },
  updateEml(eml) {
    return DataAPI.put(`/api/contrat_materiel_has_emls/${eml.id}`, eml)
      .then(res => res.data);
  },
  getEmlDocTypes() {
    return DataAPI.get('/api/contrat_eml_doc_types')
      .then(res => res.data);
  },
  getEmlDocuments(emlId) {
    if (!emlId) {
      return Promise.resolve([]);
    }
    return DataAPI.get('/api/contrat_eml_has_docs', {
      params: {
        eml: emlId
      }
    }).then(res => res.data);
  }
};
