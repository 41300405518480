<template>
    <div id="app">
        <router-view />
        <CMMDocumentErrorModal ref="errorDocumentModal" />
    </div>
</template>

<script>
import { EventBus } from '@/util/eventBus';
import CMMDocumentErrorModal from '@/views/CMM/Document/CMMDocumentErrorModal';

export default {
  name: 'App',
  components: {
    CMMDocumentErrorModal
  },
  mounted() {
    EventBus.$on('openErrorDocumentModal', files => {
      this.$refs?.errorDocumentModal.openModal(files);
    });
  },
  beforeDestroy() {
    EventBus.$off('openErrorDocumentModal');
  }
};
</script>
