import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login')
  },
  {
    path: '/cockpit-sas',
    name: 'cockpitSas',
    component: () => import('@/views/Login/LoginCockpitSas'),
    hidden: true
  },
  {
    path: '/logout-sas',
    name: 'logoutSas',
    component: () => import('@/views/Login/LogoutCockpitSas')
  },
  {
    path: '/',
    component: BaseLayout,
    redirect: '/accueil',
    children: [
      {
        path: 'accueil',
        component: () => import('@/views/Welcome/Welcome'),
        name: 'accueil',
        meta: {
          authRequired: true, title: 'Accueil', icon: 'fas fa-home'
        }
      },
      {
        path: '403',
        component: () => import('@/views/ErrorPage/ErrorPage'),
        name: '403',
        meta: {
          authRequired: true,
          title: 'Accès refusé',
          headline: 'Vous ne pouvez pas accéder à cette page.'
        },
        hidden: true
      }
    ]
  },
  {
    path: '*',
    component: BaseLayout,
    redirect: '404',
    children: [
      {
        path: '404',
        component: () => import('@/views/ErrorPage/ErrorPage'),
        name: '404',
        meta: {
          authRequired: true,
          title: 'Page introuvable',
          headline: 'La page demandée n\'existe pas.'
        },
        hidden: true
      }
    ]
  }
];
