import codeExamService from '@/service/codeExamService';

const state = {
  codeExams: [],
  optionsCodeExams: [],
  codeExamFilters: []
};

const mutations = {
  UPDATE_CODE_EXAMS: (state, codeExams) => {
    state.codeExams = codeExams;
  },
  UPDATE_OPTIONS_CODE_EXAMS: (state, optionsCodeExams) => {
    state.optionsCodeExams = optionsCodeExams;
  },
  UPDATE_CODE_EXAMS_FILTER: (state, codeExamFilters) => {
    state.codeExamFilters = codeExamFilters;
  },
  DELETE_CODE_EXAMS_FILTER: (state, codeExamFilterId) => {
    state.codeExamFilters = state.codeExamFilters.filter(c => c.id !== codeExamFilterId);
  }
};

const actions = {
  fetchCodeExams({ commit, rootGetters }) {
    return codeExamService.getCodeExamByInstallation(rootGetters['installation/defaultSelectedIDInstallation'])
      .then(codeExams => {
        commit('UPDATE_CODE_EXAMS', codeExams);
      });
  },
  fetchFilters({ commit, rootGetters }) {
    return codeExamService.getFiltersByUserAndInstallation(rootGetters['installation/defaultSelectedIDInstallation'])
      .then(codeExamFilters => {
        commit('UPDATE_CODE_EXAMS_FILTER', codeExamFilters);
      });
  },
  updateOptions({ commit }, optionsCodeExams) {
    commit('UPDATE_OPTIONS_CODE_EXAMS', optionsCodeExams);
  },
  applyRegisterFilters({ commit, dispatch }, filter) {
    if (filter) {
      commit('UPDATE_OPTIONS_CODE_EXAMS', filter.codeExam);
    }
  },
  addRegisterFilters({ commit, state, rootGetters }, filterName) {
    const filterToRegister = {
      name: filterName,
      installation: rootGetters['installation/defaultSelectedIDInstallation'],
      codeExam: state.optionsCodeExams.map(ce => ce.id)
    };
    return codeExamService.registerFilterCodeExam(filterToRegister)
      .then(() => {
        const that = this._vm;
        const h = that.$createElement;
        that.$notify.success({
          title: 'Filtre sauvegardé !',
          message: h('p', null, [
            h('span', null, 'Le filtre '),
            h('strong', null, that.$capitalize(filterName)),
            h('span', null, ' à bien été enregistré.')
          ])
        });
      });
  },
  removeRegisterFilters({ commit }, id) {
    return codeExamService.deleteFilter(id)
      .then(() => {
        commit('DELETE_CODE_EXAMS_FILTER', id);
        const that = this._vm;
        that.$notify.success({
          title: 'Filtre supprimé !'
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
