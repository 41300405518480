const GLOBAL = 'global';
const SOCIETE = 'societe';
const CABINET = 'cabinet';
const MEDECIN = 'medecin';
const APPAREIL = 'appareil';
const CORRESPONDANT = 'correspondant';

const SOCIETE_ACTIVE_FILTER = [GLOBAL];
const CABINET_ACTIVE_FILTER = [...SOCIETE_ACTIVE_FILTER, SOCIETE];
const MEDECIN_ACTIVE_FILTER = [...CABINET_ACTIVE_FILTER, CABINET];
const APPAREIL_ACTIVE_FILTER = [...MEDECIN_ACTIVE_FILTER, MEDECIN];
const CORRESPONDANT_ACTIVE_FILTER = [...MEDECIN_ACTIVE_FILTER];

const TAB = {
  GLOBAL: {
    NAME: GLOBAL,
    KEY_ID: 'id_installation'
  },
  SOCIETE: {
    NAME: SOCIETE,
    ACTIVE_FILTER: SOCIETE_ACTIVE_FILTER,
    KEY_ID: 'id_societe'
  },
  CABINET: {
    NAME: CABINET,
    ACTIVE_FILTER: CABINET_ACTIVE_FILTER,
    KEY_ID: 'id_cabinet'
  },
  MEDECIN: {
    NAME: MEDECIN,
    ACTIVE_FILTER: MEDECIN_ACTIVE_FILTER
  },
  APPAREIL: {
    NAME: APPAREIL,
    ACTIVE_FILTER: APPAREIL_ACTIVE_FILTER,
    KEY_ID: 'id_ris_appareil'
  },
  CORRESPONDANT: {
    NAME: CORRESPONDANT,
    ACTIVE_FILTER: CORRESPONDANT_ACTIVE_FILTER
  }
};

export default {
  TAB
};
