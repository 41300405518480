import CONSTANTS from '@/util/constant/constantApp';
import { ucFirst } from '@/util/util';
import DataAPI from '../lib/axios';

const { MAINTENANCE, QUALITY_CONTROL, RADIOPROTECTION_CONTROL } = CONSTANTS.DASHBOARD;

export default {
  getDashboard(page, filters, sort) {
    return DataAPI.post(`/cmm/contrat_materiel_dashboard/${page}`, {
      filters,
      sort
    });
  },
  getDashboardHistoricElementDashboard(page, targetId, type, target, sort) {
    const params = {
      [target]: targetId,
      type: (type && (type === 'i' || type === 'e')) ? type : undefined
    };

    if (sort) {
      const order = sort.order === 'ascending' ? 'asc' : 'desc';
      if (sort.prop === 'controlResult') {
        sort.prop = 'controlResult.name';
      }

      params[`order[${sort.prop}]`] = order;
    }

    return DataAPI.get(page, { params }).then(res => res.data);
  },
  getDashboardDocument(page, name, id) {
    return DataAPI.get(`${page}?${name}=${id}`);
  },
  getDashboardMaintenance(infos) {
    return DataAPI.post('/cmm/contrat_materiel_maintenance', infos);
  },
  getDashboardIncident(infos) {
    return DataAPI.post('/cmm/contrat_materiel_incident', infos);
  },
  getListeContrat(infos) {
    return DataAPI.post('/cmm/contrat_parc_materiel/contrat', infos);
  },
  addContact(contact) {
    return DataAPI.post('/api/contrat_contacts', contact)
      .then(res => res.data);
  },
  getListContacts(installationId) {
    return DataAPI.get('/api/contrat_contacts', {
      params: {
        installation: installationId
      }
    }).then(res => res.data);
  },
  addMaterialContact(contactId, materialId) {
    return DataAPI.post('/api/contrat_materiel_has_contacts', {
      contact: contactId,
      materiel: materialId
    }).then(res => res.data);
  },
  removeMaterialContact(contactId) {
    return DataAPI.delete(`/api/contrat_materiel_has_contacts/${contactId}`)
      .then(res => res.data);
  },
  getListMaterialContacts(materialId) {
    return DataAPI.get('api/contrat_materiel_has_contacts', {
      params: {
        materiel: materialId
      }
    }).then(res => res.data?.map(c => ({ ...c.contact, relationId: c.id })));
  },
  getListeDocument(infos) {
    return DataAPI.post('/cmm/contrat_parc_materiel/document', infos);
  },
  addEvent(form, page) {
    return DataAPI.post(`/api/${page}`, form)
      .then(response => response.data);
  },
  updateEvent(eventId, form, page) {
    return DataAPI.put(`/api/${page}/${eventId}`, form)
      .then(response => response.data);
  },
  removeDashboardItem(id, route) {
    return DataAPI.delete(`${route}/${id}`)
      .then(response => response.data);
  },
  updateEventDate(form, page) {
    return DataAPI.put(`/cmm/eventDate/update/${page}`, form)
      .then(response => response.data);
  },
  getEvent(eventId, typeDashboard) {
    return DataAPI.get(`/api/contrat_materiel_${typeDashboard}s/${eventId}`)
      .then(response => response.data);
  },
  getEvents(dashboard, selectedDevice, selectedInstallation, start, end, done) {
    const startParamName = `date${ucFirst(dashboard)}[after]`;
    const endParamName = `date${ucFirst(dashboard)}[before]`;
    const params = {
      'materiel.installation': selectedInstallation,
      [startParamName]: start,
      [endParamName]: end
    };

    if (selectedDevice) {
      params.materiel = selectedDevice;
    }

    if (done !== null) {
      params.done = done;
    }

    return DataAPI.get(`/api/contrat_materiel_${dashboard}s`, {
      params
    })
      .then(response => response.data);
  },
  updateMaintenance(form) {
    return DataAPI.put(`/api/contrat_materiel_maintenances/${form.id}`, form)
      .then(response => response.data);
  },
  updateIncident(incident) {
    return DataAPI.put(`/api/contrat_materiel_incidents/${incident.id}`, incident)
      .then(response => response.data);
  },
  updateCq(cq) {
    return DataAPI.put(`/api/contrat_materiel_cqs/${cq.id}`, cq)
      .then(response => response.data);
  },
  updateCrp(crp) {
    return DataAPI.put(`/api/contrat_materiel_crps/${crp.id}`, crp)
      .then(response => response.data);
  },
  filterParams(filters, page = 'dashboard') {
    let allowed = [];

    switch (page) {
      case MAINTENANCE.NAME:
      case QUALITY_CONTROL.NAME:
      case RADIOPROTECTION_CONTROL.NAME:
        allowed = ['installations', 'recherche', 'materiel_inactif', 'materiel_with_periodicty_disable'];
        break;
      default:
        allowed = ['installations', 'recherche', 'materiel_inactif'];
        break;
    }

    return Object.keys(filters)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = filters[key];
        return obj;
      }, {});
  },
  materialEventHistoric(entity, installations, actif) {
    return DataAPI.get(`/api/contrat_materiel_${entity}s`, {
      params: {
        'materiel.actif': actif,
        'materiel.installation': installations
      }
    }).then(response => response.data);
  }
};
