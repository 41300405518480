import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import original_slugify from 'slugify';
import XLSX from 'sheetjs-style';
import rolesHierarchy from '@/util/constant/rolesHierarchy.json';
import CONSTANTS from '@/util/constant/constantApp';
import store from '@/store/store';
import router from '@/router/router';

const pluralizeImport = require('pluralize-fr');

/**
 * Get number items + word (plurialize if needed)
 * ? Example :
 *   - 1 mail send
 *   - 2 mails sends
 */
export function accorization(items, singularWord, showNumber, messageOnEmpty) {
  let message = '';
  const isNumber = typeof items === 'number';
  const number = (
    isNumber ? items : items && items.length
  ) || 0;
  if (showNumber) {
    message += `${number} `;
  }
  if (number === 0 && messageOnEmpty) {
    message = messageOnEmpty;
  } else {
    message += number >= 2 ? pluralizeImport(singularWord) : singularWord;
  }

  return message;
}

export function plurialize(singularWord) {
  return pluralizeImport(singularWord);
}

export function updateObjectProperties(original, dataToUpdate) {
  function loop(original, dataToUpdate) {
    for (const key in dataToUpdate) {
      const value = dataToUpdate[key];
      if (typeof value === 'object') {
        loop(original[key], value);
      } else {
        original[key] = value;
      }
    }
  }
  loop(original, dataToUpdate);
}

/**
 * ? This function extract some props from existing Object.
 *
 * Example :
 *   const object = {id: 1, name: 'toto', age: 42};
 *   const object2 = pick(object, ['id', 'age']);
 *   object2 = {id: 1, age: 42}
 */
export function pick(obj, props) {
  if (!obj || !props) return;
  const picked = {};
  props.forEach(prop => {
    picked[prop] = obj[prop];
  });
  return { ...picked };
}

/**
 * ! ONLY FOR elInput USAGE
 * ? Retrieve list of matching string depending of Query string
 *
 * Example :
 *   const dataList = ['apple', 'samsung', ...];
 *   const queryString = 'pa';
 *   const result = getMatchingStringFromQuery(dataList, queryString);
 *   result = [{value: 'apple'}]
 */
export function getMatchingStringFromQuery(dataList, queryString) {
  const parseQuerySearchResult = (results) => [...results.map(result => ({ value: result }))];
  const extractFilteredDataListFromQuery = (dataList, queryString) => {
    const str = queryString.toLowerCase(); // trigger on 3 first letter => queryString.toLowerCase().substring(0, 3)
    return dataList.filter((x) => {
      const xSub = x.toLowerCase(); // trigger on 3 first letter => x.substring(0, 3).toLowerCase();
      return x.toLowerCase().includes(str) || checkMatchingName(xSub, str);
    });
  };
  const checkMatchingName = (name, str) => {
    const pattern = str.split('').map((x) => `(?=.*${x})`).join('');
    const regex = new RegExp(`${pattern}`, 'g');
    return name.match(regex);
  };

  const results = queryString ? extractFilteredDataListFromQuery(dataList, queryString) : [];
  return parseQuerySearchResult(results);
}

export function delete_row_on_xlsx_sheet(ws, row_index) {
  delete_row(ws, row_index);

  function delete_row(ws, row_index) {
    const variable = XLSX.utils.decode_range(ws['!ref']);
    for (let R = row_index; R < variable.e.r; ++R) {
      for (let C = variable.s.c; C <= variable.e.c; ++C) {
        ws[ec(R, C)] = ws[ec(R + 1, C)];
      }
    }
    variable.e.r--;
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
  }
  function ec(r, c) {
    return XLSX.utils.encode_cell({ r, c });
  }
}

export function keyboardNumberOnly(keycode) {
  const codes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  return codes.includes(keycode);
}

export function cumuleAlphabetPosition(text) {
  const array = [...text].map(a => parseInt(a, 36) - 10).filter(a => a >= 0);
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  return array.reduce(reducer);
}

export function routeContains(array, keyToCompare = 'fullPath') {
  const routeName = router.currentRoute[keyToCompare];
  return !!array.find(word => routeName.includes(word));
}

export function extractConstTabValue(string) {
  const {
    GLOBAL, SOCIETE, CABINET, MEDECIN, APPAREIL
  } = CONSTANTS.CES.TAB;
  const array = [GLOBAL.NAME, SOCIETE.NAME, CABINET.NAME, MEDECIN.NAME, APPAREIL.NAME];
  return array.find(word => string.includes(word));
}

export function extractConstDashboardValue(path) {
  const cloneConstant = cloneDeep(CONSTANTS);
  const { DASHBOARD } = cloneConstant;
  const array = Object.values(DASHBOARD);
  return array.find(({ PATH_NAME }) => path.includes(PATH_NAME));
}

export function generateRandomIndex(count) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < count; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

let debounceTimeout;
export function debounce(callback, wait = 1500, immediate) {
  return (...args) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      debounceTimeout = null;
      if (!immediate) callback(...args);
    }, wait);
    if (immediate && !debounceTimeout) callback(...args);
  };
}

export function slugify(string) {
  return original_slugify(string, {
    replacement: '_', // replace spaces with replacement character, defaults to `-`
    remove: /[*+~.()'"!:@]/g, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: false // strip special characters except replacement, defaults to `false`
    // locale: 'vi' // language code of the locale to use
  });
}

export function cleanObject(obj) {
  return Object.keys(obj).forEach(key => (obj[key] === null && delete obj[key]));
}

export function toThousandFilterCustom(num) {
  const rounded = Math.round(num);
  return !isNaN(rounded) ? (+rounded || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ' ')) : rounded;
}

export function filtreNumberExport(num) {
  return Math.round(num);
}

export function getExcelFormat(titleKey, type = null) {
  if (!titleKey) {
    return null;
  }
  const { FORMAT } = CONSTANTS.EXCEL;
  const formatsKey = Object.keys(FORMAT);

  let findKeyValue;
  for (const key of formatsKey) {
    const correspondences = FORMAT[key] ? FORMAT[key].MATCH : [];
    const keyValue = FORMAT[key] ? FORMAT[key].VALUE : undefined;
    const findIt = correspondences.find(corres => {
      const regex = new RegExp(`^${corres.toLowerCase()}`);
      const lowerTitleKey = titleKey.toLowerCase();
      return regex.test(lowerTitleKey);
    });
    if (findIt) {
      findKeyValue = keyValue;
      break;
    }
  }

  if (type === 'financier') {
    return findKeyValue || FORMAT.CURRENCY.VALUE;
  } if (type === 'dep') {
    return findKeyValue || FORMAT.NUMBER.VALUE;
  }
  return findKeyValue;
}

export function getDuplicate(source = [], target = [], duplicateBy = 'id') {
  return [...source].filter(s => target.find(t => t[duplicateBy] === s[duplicateBy]));
}

export function getWorkdayCount(date1, date2, workSat = 0, workSun = 0) {
  function padout(number) {
    return number < 10 ? `0${number}` : number;
  }
  function getEasterDate(Y) {
    const C = Math.floor(Y / 100);
    const N = Y - 19 * Math.floor(Y / 19);
    const K = Math.floor((C - 17) / 25);
    let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I -= 30 * Math.floor(I / 30);
    I -= Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
    let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
    J -= 7 * Math.floor(J / 7);
    const L = I - J;
    const M = 3 + Math.floor((L + 40) / 44);
    const D = L + 28 - 31 * Math.floor(M / 4);

    return `${padout(M)}-${padout(D + 1)}`;
  }

  // Tableau des jours feriés
  const publicHolidays = ['01-01', '05-01', '05-08', '07-14', '11-11', '08-15', '11-01', '12-25'];

  /**
   * Récupération de tous les lundi de Pâques
   */
  const dateFormat = CONSTANTS.DATE_FORMAT.MOMENT.VALUE;
  const startYear = moment(date1, dateFormat).toDate();
  const endYear = moment(date2, dateFormat).toDate();
  const yearStart = startYear.getFullYear();
  const yearEnd = endYear.getFullYear();
  const easterMondays = [];

  for (let i = yearStart; i <= yearEnd; i++) {
    const md = getEasterDate(i).split('-');
    const easter = new Date(i, md[0] - 1, md[1]);
    // création d'un tableau contenant tous les lundi de pâques sur la période
    easterMondays.push(easter.getTime());
  }

  const start = startYear.getTime();
  const end = endYear.getTime();

  let workdays = 0;

  const datei = new Date(start);

  for (let i = start; i <= end; i += 3600000 * 24) {
    const day = datei.getDay(); // 0=sun, 1=mon, ..., 6=sat
    const mmgg = `${(datei.getMonth() + 1 < 10 ? '0' : '') + (datei.getMonth() + 1)}-${datei.getDate() < 10 ? '0' : ''}${datei.getDate()}`;

    if (
      !publicHolidays.includes(mmgg)
      && !easterMondays.includes(i)
      && !(day === 6 && workSat === false)
      && !(day === 0 && workSun === false)
    ) {
      workdays++;
    }

    datei.setDate(datei.getDate() + 1);
  }

  return parseInt(workdays);
}

export function routeExist({ path, name }) {
  const routes = router.getRoutes() || [];
  // a route with undefined name and path but '/accueil' redirect is created somewhere and probably used when user request an unknown path
  // juste exclude it froml route exist check
  return !!routes.find(p => p.path === path || (p.name === name && p.redirect !== '/accueil'));
}

export function getProdUrl(path) {
  const token = store.getters['security/userToken'];
  const baseProdUrl = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${token}&page=`;

  const slash = '/';
  const pageSplit = path.split(slash);
  const posibilities = pageSplit.reduce((acc, value) => {
    const lastPart = acc[acc.length - 1] || '';
    const nextPossibility = lastPart + slash + value;
    return [...acc, nextPossibility];
  }).reverse();

  const routes = router.getRoutes() || [];
  for (const posibility of posibilities) {
    const alternative = routes.find(item => item.path === posibility);
    const page = alternative?.meta?.page;
    if (page && page !== '#') {
      return baseProdUrl + page;
    }
  }

  return null;
}

export function getPeriodicity(type) {
  const periodicity = {
    jour: 'Quotidienne',
    semaine: 'Hebdomadaire',
    mois: 'Mensuelle',
    annee: 'Annuelle'
  };

  return periodicity[type];
}

/**
 * Return numbers of weeks, months or years in a period
 */
export function getGranularityCount(d1, d2, unitOfTime) {
  const format = CONSTANTS.DATE_FORMAT.MOMENT.VALUE;
  d1 = moment(d1, format);
  d2 = moment(d2, format);

  const diff = d2.diff(d1, unitOfTime, true);
  // Round up value for average calculations; check with Simon or Matthieu if problem
  return Math.ceil(Math.abs(diff));
}

export function isObject(a) {
  return (!!a) && (a.constructor === Object);
}

export function waitAsync(second) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, second * 1000);
  });
}

export function getKeysDiff(obj1, obj2, exclude = []) {
  const diff = Object.keys(obj1).filter(key => {
    if (exclude.includes(key)) {
      return false;
    }
    if (typeof obj1[key] === 'boolean' || typeof obj1[key] === 'number' || typeof obj2[key] === 'number') {
      return obj1[key] != obj2[key];
    }
    if (isObject(obj1[key]) || isObject(obj2[key])) {
      return !isEqual(obj1[key], obj2[key]);
    }
    return obj1[key] != obj2[key] && (!isEmpty(obj1[key]) || !isEmpty(obj2[key]));
  });
  return diff;
}

export function extractDatesFromWeeksNumber(weeks, year, daysWeek) {
  const dates = [];
  const dateFormat = CONSTANTS.DATE_FORMAT.MOMENT.VALUE;
  weeks.forEach(week => {
    let currentDate = moment()
      .year(year)
      .startOf('week')
      .isoWeek(week)
      .format(dateFormat);
    for (let i = 1; i <= 7; i++) {
      const dayNumber = parseInt(moment(currentDate, dateFormat).format('e')) + 1;
      const isAuthorizedDay = daysWeek.find(d => d === dayNumber);
      if (!!isAuthorizedDay) {
        dates.push(currentDate);
      }
      if (i < 7) {
        currentDate = moment(currentDate, dateFormat)
          .add(1, 'days')
          .format(dateFormat);
      }
    }
  });
  return dates;
}

export function extractWeeksNumberFromDate(dates) {
  const weeksNumbers = [];
  const arrayOfDate = dates || [];
  arrayOfDate.forEach(date => {
    const weekNumber = moment(date, CONSTANTS.DATE_FORMAT.MOMENT.VALUE).isoWeek();
    if (!weeksNumbers.includes(weekNumber)) {
      weeksNumbers.push(weekNumber);
    }
  });

  return weeksNumbers;
}
export function loopNumber(start, end) {
  let increment = start;
  const result = [];

  while (increment <= end) {
    result.push(increment);
    increment++;
  }

  return result;
}

export function getFileExtension(file) {
  if (!file) return;
  return file.split('.').pop();
}

export function getFileMIMETypeByExtension(extension) {
  if (!extension) return;
  const { TYPE } = CONSTANTS.IMAGE;
  const listKeys = Object.keys(TYPE);
  return listKeys.find(type => {
    const value = TYPE[type];
    return value.title === extension;
  });
}

export function toEuro(value) {
  return /^[\d ]+$/.test(value) ? `${value} €` : value;
}

export function filterObjectByKey(object, arrayKey) {
  return Object.fromEntries(Object.entries(object).filter(([key, value]) => arrayKey.includes(key)));
}

export function removeUnusePropertyFromFilter(filterParam) {
  const returnFilterParam = [];
  const filterAllowedProperty = ['id', 'id_installation', 'id_societe', 'id_cabinet', 'id_ris_medecin', 'id_ris_appareil', 'name'];
  if (filterParam != null) {
    filterParam.forEach((obj) => {
      const param = filterObjectByKey(obj, filterAllowedProperty);
      returnFilterParam.push(param);
    });
  }
  return returnFilterParam;
}

export function removeUnusePropertyFromFilterGroupeCabinet(filterParam) {
  let returnFilterParam = {};
  const filterAllowedPropertyGroupeCabinet = ['id', 'cabinets'];
  if (filterParam != null && !isEmpty(filterParam)) {
    const filterGroupeCabinet = cloneDeep(filterObjectByKey(filterParam, filterAllowedPropertyGroupeCabinet));
    filterGroupeCabinet.cabinets = removeUnusePropertyFromFilter(filterGroupeCabinet.cabinets);
    returnFilterParam = filterGroupeCabinet;
  }
  return returnFilterParam;
}

export function cmmFilterParamsTreatment(filters) {
  const type_dashboard = store.getters['app/typeDashboard'];
  const date = store.getters['cmm/date'];
  const { NAME, SLUG } = type_dashboard;
  const CONTROLS_SLUGS = ['cq', 'crp'];
  const ONLY_LAST_PERIOD = ['incident'];
  const DATES_PERIOD_NEEDED = [...CONTROLS_SLUGS, 'incident', 'maintenance'];
  const { MAINTENANCE, QUALITY_CONTROL, RADIOPROTECTION_CONTROL } = CONSTANTS.DASHBOARD;

  if (![MAINTENANCE.NAME, QUALITY_CONTROL.NAME, RADIOPROTECTION_CONTROL.NAME].includes(NAME)) {
    delete filters.materiel_with_periodicty_disable;
  }
  if (DATES_PERIOD_NEEDED.includes(SLUG)) {
    if (CONTROLS_SLUGS.includes(SLUG)) {
      const keys = 'ie';
      [...keys].forEach(k => {
        filters[SLUG] = {
          [k]: {
            last: date.last[SLUG + k],
            next: date.next[SLUG + k]
          },
          ...filters[SLUG]
        };
      });
    } else {
      if (ONLY_LAST_PERIOD.includes(SLUG)) {
        filters[SLUG] = {
          last: date.last[SLUG]
        };
      } else {
        filters[SLUG] = {
          last: date.last[SLUG],
          next: date.next[SLUG]
        };
      }
    }
  }

  return filters;
}

export function getAllowedCrudActions(roles) {
  const role = store.getters['security/getRolePermission'];
  const rolesFiltered = Object.keys(roles)
    .filter(key => role.includes(key))
    .reduce((cur, key) => { return Object.assign(cur, { [key]: roles[key] }); }, {});
  return Object.values(rolesFiltered);
}

export function getFullUserRoles(userRoles) {
  const roleFinal = [];

  function flattenRoles(roles) {
    roles.forEach(el => {
      if (Object.keys(rolesHierarchy).includes(el)) {
        roleFinal.push(el);
        flattenRoles(rolesHierarchy[el]);
      } else {
        roleFinal.push(el);
      }
    });
  }
  flattenRoles(userRoles);
  return roleFinal;
}

export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
