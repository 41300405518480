import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import cmmService from '@/service/cmmService';
import { accorization } from '@/util/util';
import CONSTANTS from '@/util/constant/constantApp';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const getContactKey = (added) => (added ? 'liste_material_contact' : 'liste_contact');

const {
  QUALITY_CONTROL, RADIOPROTECTION_CONTROL
} = CONSTANTS.DASHBOARD;

const state = {
  data: {},
  societes: [],
  options_societes: [],
  recherche: [],
  date: {
    last: {
      maintenance: [],
      incident: [],
      cqi: [],
      cqe: [],
      crpi: [],
      crpe: []
    },
    next: {
      maintenance: [],
      cqi: [],
      cqe: [],
      crpi: [],
      crpe: []
    }
  },
  commissioningYear: '',
  materiel_inactif: 0,
  occupation_status: null,
  materiel_with_periodicty_disable: 0,
  liste_contact: [],
  liste_material_contact: [],
  dashboard_historics: [],
  dashboard_documents: [],
  fieldsLoading: false,
  filterIsOpen: false,
  materialEventHistoric: []
};

const mutations = {
  UPDATE_DATA: (state, data) => {
    state.data = typeof data === 'object' ? data : {};
  },
  UPDATE_RECHERCHE_MOD_MAR: (state, recherche_mod_mar) => {
    recherche_mod_mar = Array.isArray(recherche_mod_mar) ? recherche_mod_mar : [recherche_mod_mar];
    state.recherche = recherche_mod_mar;
  },
  UPDATE_MATERIEL_INACTIF: (state, materiel_inactif) => {
    state.materiel_inactif = materiel_inactif;
  },
  UPDATE_OCCUPATION_STATUS: (state, occupation_status) => {
    state.occupation_status = occupation_status;
  },
  UPDATE_MAINTENANCE: (state, { type, value }) => {
    state.maintenance[type] = value;
  },
  UPDATE_LISTE_CONTRAT: (state, liste_contrat) => {
    state.liste_contrat = liste_contrat;
  },
  UPDATE_COMMISSIONNING_YEAR: (state, commissioningYear) => {
    state.commissioningYear = commissioningYear;
  },
  UPDATE_LISTE_MATERIAL_CONTACT: (state, liste_contact) => {
    state.liste_material_contact = liste_contact;
  },
  UPDATE_LISTE_MATERIAL_CONTACT_ITEM: (state, contact) => {
    const old_contact = state.liste_material_contact.find(c => c.id === contact.id);
    if (old_contact) {
      Object.assign(old_contact, contact);
    }
  },
  UPDATE_LISTE_CONTACT: (state, liste_contact) => {
    state.liste_contact = liste_contact;
  },
  UPDATE_LISTE_CONTACT_ITEM: (state, contact) => {
    const old_contact = state.liste_contact.find(c => c.id === contact.id);
    if (old_contact) {
      Object.assign(old_contact, contact);
    }
  },
  MOVE_CONTACT_TO_OTHER_LIST: (state, { contactId, added }) => {
    const contactMoved = state[getContactKey(added)].find(contact => contact.id === contactId);
    state[getContactKey(added)] = state[getContactKey(added)].filter(contact => contact.id !== contactId);
    if (!added) {
      state[getContactKey(!added)].unshift(contactMoved);
    } else {
      const findContact = state[getContactKey(!added)].find(item => item.id === contactMoved.id);
      if (findContact) {
        Object.assign(findContact, contactMoved);
      } else {
        state[getContactKey(!added)].unshift(contactMoved);
      }
    }
  },
  UPDATE_DASHBOARD_HISTORICS: (state, data_historic) => {
    state.dashboard_historics = data_historic;
  },
  UPDATE_DASHBOARD_HISTORIC: (state, data_historic) => {
    const old_data_historic = state.dashboard_historics?.find(item => item.id === data_historic.id);
    if (old_data_historic) {
      Object.assign(old_data_historic, data_historic);
    }
  },
  UPDATE_FIELDS_LOADING: (state, fieldsLoading) => {
    state.fieldsLoading = fieldsLoading;
  },
  REMOVE_DASHBOARD_HISTORICS: (state, id) => {
    state.dashboard_historics = state.dashboard_historics.filter(item => item.id !== id);
  },
  UPDATE_DASHBOARD_DOCUMENTS: (state, data_document) => {
    state.dashboard_documents = data_document;
  },
  REMOVE_DASHBOARD_DOCUMENTS: (state, id) => {
    state.dashboard_documents = state.dashboard_documents.filter(item => item.id !== id);
  },
  UPDATE_MATERIEL_WITH_PERIODICTY_DISABLE: (state, materiel_with_periodicty_disable) => {
    state.materiel_with_periodicty_disable = materiel_with_periodicty_disable;
  },
  UPDATE_DATE_PERIODE: (state, { value, type, time }) => {
    const { minDate, maxDate } = value;
    state.date[time][type] = minDate && maxDate ? [moment(minDate).format(CONSTANTS.DATE_FORMAT.MOMENT.VALUE), moment(maxDate).format(CONSTANTS.DATE_FORMAT.MOMENT.VALUE)] : [];
  },
  UPDATE_DATATABLES_ITEMS: (state, materiel) => {
    const old_materiel = state.data?.tables?.dataTables?.find(item => item.id === materiel.id);
    if (old_materiel) {
      Object.assign(old_materiel, materiel);
    }
  },
  ADD_DATATABLES_ITEM: (state, materiel) => {
    state.data?.tables?.dataTables?.push(materiel);
  },
  UPDATE_FILTER_IS_OPEN: (state, bool) => {
    state.filterIsOpen = bool;
  },
  UPDATE_ALL_HISTORICAL_ELEMENTS: (state, elements) => {
    state.materialEventHistoric = elements;
  },
  CLEAR_ALL_HISTORICAL_ELEMENTS: (state) => {
    state.materialEventHistoric = [];
  }
};

const actions = {
  updateDatatablesItems({ commit }, materiel) {
    commit('UPDATE_DATATABLES_ITEMS', materiel);
  },
  addDatatablesItem({ commit }, materiel) {
    commit('ADD_DATATABLES_ITEM', materiel);
  },
  getDashboard({
    commit, dispatch, state, rootState, rootGetters
  }, sort) {
    const { app } = rootState;
    updateAppLoading(dispatch, true);

    const { NAME } = app.type_dashboard;
    const cmmFilterParams = rootGetters['filterParams/getFilterCmm'];
    cmmService.getDashboard(NAME, cmmFilterParams, sort)
      .then((response) => {
        commit('UPDATE_DATA', response.data);
      })
      .catch((error) => {
        console.error(error);
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  getDashboardHistoricElementDashboard({ commit, dispatch, rootState }, {
    id, type, isChild, sort
  }) {
    updateAppLoading(dispatch, true);
    const { app } = rootState;
    const { API_ROUTE, CHILDRENS, SLUG } = app.type_dashboard;
    const historicRoute = isChild ? CHILDRENS.API_ROUTE : API_ROUTE;
    const targetKey = isChild ? SLUG : 'materiel';
    return historicRoute ? cmmService.getDashboardHistoricElementDashboard(historicRoute, id, type, targetKey, sort)
      .then(historics_data => {
        commit('UPDATE_DASHBOARD_HISTORICS', historics_data);
        return historics_data;
      }).finally(() => updateAppLoading(dispatch, false))
      : Promise.resolve([]).finally(() => updateAppLoading(dispatch, false));
  },
  getDashboardDocument({ commit, dispatch, rootState }, id) {
    updateAppLoading(dispatch, true);
    const { app } = rootState;
    const { DOCUMENT_API_ROUTE, SLUG } = app.type_dashboard;
    cmmService.getDashboardDocument(DOCUMENT_API_ROUTE, SLUG, id)
      .then((response) => {
        commit('UPDATE_DASHBOARD_DOCUMENTS', response.data);
      }).catch((error) => {
        console.error(error);
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  addContact({
    state, commit, dispatch, rootGetters
  }, { contact, installationId, materialId }) {
    const id_installation = installationId || rootGetters['installation/defaultSelectedIDInstallation'];
    updateAppLoading(dispatch, true);
    const contactObj = {
      ...contact,
      installation: id_installation,
      material: materialId
    };
    return cmmService.addContact(contactObj)
      .then(res => {
        const contactId = res.id;
        const commitKey = materialId ? 'UPDATE_LISTE_MATERIAL_CONTACT' : 'UPDATE_LISTE_CONTACT';
        const contactList = materialId ? cloneDeep(state.liste_material_contact) : cloneDeep(state.liste_contact);
        contactList.unshift({ ...contactObj, id: contactId });
        commit(commitKey, contactList);
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  moveContactToOtherList({ commit }, { contactId, added }) {
    commit('MOVE_CONTACT_TO_OTHER_LIST', { contactId, added });
  },
  getListContacts({
    commit, dispatch, rootGetters
  }, id_installation) {
    const id = id_installation || rootGetters['installation/defaultSelectedIDInstallation'];
    updateAppLoading(dispatch, true);
    return cmmService.getListContacts(id)
      .then(contacts => {
        commit('UPDATE_LISTE_CONTACT', contacts);
      })
      .catch((error) => {
        console.error(error);
        commit('UPDATE_LISTE_CONTACT', []);
      })
      .finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  applyAllMaterialContacts({ state, commit, dispatch }, { materialId, showLoader = true }) {
    // TODO : mériterai un refacto, mais je ne sais comment : by Afondevi
    const that = this._vm;
    const contacts = {
      toAdded: {
        list: state.liste_material_contact.filter(c => !c.relationId),
        promises: [],
        success: [],
        errors: []
      },
      toRemoved: {
        list: state.liste_contact.filter(c => c.relationId),
        promises: [],
        success: [],
        errors: []
      }
    };

    contacts.toAdded.list
      .forEach(contact => contacts.toAdded.promises.push(cmmService.addMaterialContact(contact.id, materialId)));

    contacts.toRemoved.list
      .forEach(contact => contacts.toRemoved.promises.push(cmmService.removeMaterialContact(contact.relationId)));

    if (showLoader) updateAppLoading(dispatch, true);
    const promises = [...contacts.toAdded.promises, ...contacts.toRemoved.promises];
    const lists = [...contacts.toAdded.list, ...contacts.toRemoved.list];
    return Promise.allSettled(promises)
      .then(responses => {
        responses.forEach((res, index) => {
          const isAddedItem = index <= contacts.toAdded.promises.length;
          const successCreated = res.status === 'fulfilled';
          const getKeyAdded = (toAdded) => (toAdded ? 'toAdded' : 'toRemoved');
          const getKeySuccess = (isSuccess) => (isSuccess ? 'success' : 'errors');
          if (successCreated) {
            commit('UPDATE_LISTE_MATERIAL_CONTACT_ITEM', { ...lists[index], relationId: res.value?.id });
            commit('UPDATE_LISTE_CONTACT_ITEM', { ...lists[index], relationId: undefined });
          }
          const ctts = contacts[getKeyAdded(isAddedItem)];
          ctts[getKeySuccess(successCreated)].push(ctts.list[index]);
        });

        const toAddedErrors = contacts.toAdded.errors;
        const toRemovedErrors = contacts.toRemoved.errors;
        if (toAddedErrors.length || toRemovedErrors.length) {
          if (toAddedErrors.length) {
            that.$message.error(`Attention, ${accorization(toAddedErrors, 'contact', true)} n'ont pas pu être ajouté`);
          }
          if (toRemovedErrors.length) {
            that.$message.error(`Attention, ${accorization(toRemovedErrors, 'contact', true)} n'ont pas pu être supprimé`);
          }
          return Promise.reject(contacts);
        }

        return 'ok';
      }).catch(_ => {
        that.$message.error('Erreur lors de l\'ajout des Contacts lié au materiel');
        return Promise.reject();
      }).finally(_ => {
        if (showLoader) updateAppLoading(dispatch, false);
      });
  },
  getListMaterialContacts({ commit, dispatch }, materialId) {
    updateAppLoading(dispatch, true);
    return cmmService.getListMaterialContacts(materialId)
      .then(contacts => {
        commit('UPDATE_LISTE_MATERIAL_CONTACT', contacts);
      })
      .catch(error => {
        console.error(error);
        commit('UPDATE_LISTE_MATERIAL_CONTACT', []);
      })
      .finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  addEvent({ dispatch }, payload) {
    const { form, page } = payload;
    return cmmService.addEvent(form, page)
      .then(event => {
        dispatch('getDashboard');
        return event.id;
      });
  },
  updateEvent({ dispatch }, payload) {
    const { eventId, form, page } = payload;
    return cmmService.updateEvent(eventId, form, page)
      .then(event => {
        dispatch('getDashboard');
        return event.id;
      });
  },
  updateEventDate({ commit }, payload) {
    const { form, page } = payload;
    return cmmService.updateEventDate(form, page)
      .then(event => event.id);
  },
  getEvent({ commit }, data) {
    const { eventId, typeDashboard } = data;
    return cmmService.getEvent(eventId, typeDashboard)
      .then(event);
  },
  getEvents({ commit }, data) {
    const {
      dashboard,
      selectedDevice,
      selectedInstallation,
      start,
      end,
      done
    } = data;
    return cmmService.getEvents(dashboard, selectedDevice, selectedInstallation, start, end, done)
      .then();
  },
  updateMaintenance({ commit }, form) {
    return cmmService.updateMaintenance(form)
      .then(event => event.id);
  },
  updateIncident({ commit }, form) {
    return cmmService.updateIncident(form)
      .then(event => event.id);
  },
  updateCq({ commit }, form) {
    return cmmService.updateCq(form)
      .then(event => event.id);
  },
  updateCrp({ commit }, form) {
    return cmmService.updateCrp(form)
      .then(event => event.id);
  },
  updateCommissioningYear({ commit }, commissioningYear) {
    commit('UPDATE_COMMISSIONNING_YEAR', commissioningYear);
  },
  updateMaterielWithPeriodictyDisable({ commit }, materiel_with_periodicty_disable) {
    commit('UPDATE_MATERIEL_WITH_PERIODICTY_DISABLE', materiel_with_periodicty_disable);
  },
  updateRange({ commit, dispatch }, { dates, type, time }) {
    commit('UPDATE_DATE_PERIODE', { value: dates, type, time });
  },
  updateMaterielInactif({ commit }, materiel_inactif) {
    commit('UPDATE_MATERIEL_INACTIF', materiel_inactif);
  },
  updateOccupationStatus({ commit }, occupation_status) {
    commit('UPDATE_OCCUPATION_STATUS', occupation_status);
  },
  updateRechercheModMar({ commit }, recherche_mod_mar) {
    commit('UPDATE_RECHERCHE_MOD_MAR', recherche_mod_mar);
  },
  removeDashboardItem({
    commit, dispatch, rootState
  }, { id, isChild, typeDashboard }) {
    const that = this._vm;
    const { app } = rootState;
    const { API_ROUTE, CHILDRENS } = typeDashboard ? CONSTANTS.DASHBOARD[typeDashboard] : app.type_dashboard;
    const historicRoute = isChild ? CHILDRENS.API_ROUTE : API_ROUTE;
    updateAppLoading(dispatch, true);
    return cmmService.removeDashboardItem(id, historicRoute)
      .then(res => {
        commit('REMOVE_DASHBOARD_HISTORICS', id);
        that.$notify({
          title: 'OK',
          message: typeDashboard ? CONSTANTS.DASHBOARD[typeDashboard].DELETE_MSG : app.type_dashboard?.DELETE_MSG,
          type: 'success'
        });
        dispatch('getDashboard');
        return res;
      }).catch(error => {
        that.$notify({
          title: 'Erreur',
          message: 'Une erreur est survenue lors de la suppresion',
          type: 'error'
        });
        return error;
      }).finally(_ => {
        updateAppLoading(dispatch, false);
      });
  },
  resetCmmFilters({ dispatch, rootState }) {
    const { installation, app } = rootState;
    const { SLUG } = app.type_dashboard;
    const time = ['next', 'last'];
    const keys = 'ie';

    dispatch('office/updateCabinets', [], { root: true });
    dispatch('office/updateGroupeCabinet', [], { root: true });
    dispatch('installation/updateInstallations', installation.options_installations[0], { root: true });
    dispatch('updateMaterielInactif', false);
    dispatch('updateOccupationStatus', null);
    dispatch('updateMaterielWithPeriodictyDisable', false);
    dispatch('updateRechercheModMar', []);
    dispatch('updateCommissioningYear', '');

    for (const t in time) {
      switch (SLUG) {
        case QUALITY_CONTROL.SLUG:
        case RADIOPROTECTION_CONTROL.SLUG:
          [...keys].forEach(k => {
            dispatch('updateRange', { dates: [undefined, undefined], type: SLUG + k, time: time[t] });
          });
          break;
        default:
          dispatch('updateRange', { dates: [undefined, undefined], type: SLUG, time: time[t] });
          break;
      }
    }
  },
  onFiltersChanged({ dispatch, rootGetters }, values) {
    const { newVal, oldVal } = values;
    const idInstallationHasChanged = newVal.installations !== oldVal.installations;

    dispatch('updateFieldsLoading', true);

    const promises = [];
    if (idInstallationHasChanged) {
      promises.push(dispatch('office/getCabinet', rootGetters['installation/AllIdsInstallations'], { root: true }));
      promises.push(dispatch('office/getGroupeCabinet', rootGetters['installation/AllIdsInstallations'], { root: true }));
    }
    return Promise.allSettled(promises)
      .then(() => {
        dispatch('updateFieldsLoading', false);
        return true;
      });
  },
  toggleFilterIsOpen({ commit }, bool) {
    commit('UPDATE_FILTER_IS_OPEN', bool);
  },
  updateFieldsLoading({ commit }, bool) {
    commit('UPDATE_FIELDS_LOADING', bool);
  },
  getMaterialEventHistoric({ commit, state, rootState }, entity) {
    const { installation } = rootState;
    const actif = !(state.material_inactif === 0);// for front 0 means all actives (due to switch component) but for back it means all inactives...
    cmmService.materialEventHistoric(entity, installation.installations.map(item => item.id_installation), actif)
      .then((response) => {
        commit('UPDATE_ALL_HISTORICAL_ELEMENTS', response);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  clearMaterialEventHistoric({ commit }) {
    commit('CLEAR_ALL_HISTORICAL_ELEMENTS');
  }
};

const getters = {
  societe: (state) => state.societe,
  materiel_inactif: (state) => state.materiel_inactif,
  occupation_status: (state) => state.occupation_status,
  materiel_with_periodicty_disable: (state) => state.materiel_with_periodicty_disable,
  recherche: (state) => state.recherche,
  liste_contact: (state) => state.liste_contact,
  date: (state) => state.date,
  commissioningYear: (state) => state.commissioningYear,
  date_by_time: (state) => (time, type) => state.date[time][type]
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
