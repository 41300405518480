import DataAPI from '../lib/axios';

export default {
  updateContact(contact) {
    return DataAPI.put(`/api/contrat_contacts/${contact.id}`, contact)
      .then(res => res.data);
  },
  addContact(contact) {
    return DataAPI.post('/api/contrat_contacts', contact)
      .then(res => res.data);
  }
};
