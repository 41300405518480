import BaseLayout from '@/layout/BaseLayout';
import { getStore } from '@/util/storage';

export default [
  {
    path: '/education',
    component: BaseLayout,
    name: 'education',
    meta: {
      authRequired: true, title: 'Formations', icon: 'fas fa-graduation-cap', css_class: 'rh'
    },
    children: [
      {
        path: 'rh_formations_salaries',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_formations_salaries.php`;
        },
        name: 'rh_formations_salaries',
        meta: {
          authRequired: true, role: 'ROLE_EMPLOYEE_EDUCATION_READ', humanResourceManagement: true, title: 'Formation des salariés', icon: 'fas fa-user', css_class: 'rh'
        }
      },
      {
        path: 'rh_formations_obligatoires',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_formations_obligatoires.php`;
        },
        meta: {
          authRequired: true, role: 'ROLE_MANDATORY_EDUCATION_READ', title: 'Formation obligatoires', icon: 'fas fa-medkit', css_class: 'rh'
        }
      },
      {
        path: 'rh_formation_plan',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_formation_plan.php`;
        },
        name: 'rh_formation_plan',
        meta: {
          authRequired: true, role: 'ROLE_EDUCATION_PLAN_READ', humanResourceManagement: true, title: 'Plan de formation', icon: 'fas fa-map', css_class: 'rh'
        }
      },
      {
        path: 'rh_formations_calendrier',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_formations_calendrier.php`;
        },
        meta: {
          authRequired: true, role: 'ROLE_EDUCATION_CALENDAR_READ', title: 'Catalogue des formations', icon: 'fas fa-book-medical', css_class: 'rh'
        }
      },
      {
        path: 'rh_formations_budget',
        beforeEnter: () => {
          window.location.href = `${process.env.VUE_APP_BASE_URL_FRONT_OLD}/cockpit-sas.php?token=${getStore('TOKEN')}&page=rh/rh_formations_budget.php`;
        },
        meta: {
          authRequired: true, role: 'ROLE_EDUCATION_BUDGET_READ', title: 'Budget des formations', icon: 'fas fa-chart-line', css_class: 'rh'
        }
      }
    ]
  }
];
