import moduleService from '@/service/moduleService';

const state = {
  modules: []
};

const mutations = {
  SET_MODULES: (state, modules) => {
    state.modules = modules;
  }
};

const actions = {
  getModules({ state, commit }) {
    if (state.modules?.length) {
      return Promise.resolve(state.modules);
    }
    return moduleService.getModules()
      .then(modules => {
        commit('SET_MODULES', modules);
        return modules;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
