import cloneDeep from 'lodash.clonedeep';
import cmmService from '@/service/cmmService';
import contactService from '@/service/contactService';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const actions = {
  updateContact({ dispatch }, contact) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return contactService.updateContact(contact)
      .then(updatedContact => {
        that.$notify({
          title: 'Contact modifié',
          message: 'Votre contact a bien été modifié',
          type: 'success'
        });
        return updatedContact.id;
      }).catch(() => {
        that.$message.error('Erreur lors de la modification du contact');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  addContact({ dispatch }, { contact }) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return contactService.addContact(contact)
      .then(updatedContact => {
        that.$notify({
          title: 'Contact enregistré',
          message: 'Votre contact a bien été enregistré',
          type: 'success'
        });
        return updatedContact.id;
      }).catch(() => {
        that.$message.error('Erreur lors de l\'enregistrement du contact');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  }
};

export default {
  namespaced: true,
  actions
};
