import contractService from '@/service/contractService';
import userService from '@/service/userService';

const updateAppLoading = (dispatch, bool) => dispatch('app/updateLoading', bool, { root: true });

const state = {
  users: [],
  userFilterSelected: undefined
};

const mutations = {
  UPDATE_USERS: (state, users) => {
    state.users = users;
  },
  UPDATE_USER_FILTER_SELECTED: (state, user) => {
    state.userFilterSelected = user;
  }
};

const actions = {
  async fetchUserListByInstallationIds({ commit, rootState, rootGetters }, { installationIds = [], withInactives = false }) {
    const userIsAdmin = rootGetters['security/userIsAdmin'];
    if (!userIsAdmin && installationIds.length === 0) {
      const optionsInstallations = rootState.installation.options_installations;
      installationIds = optionsInstallations.map((optionInstallation => optionInstallation.id_installation));
    }

    return userService.fetchUserListByInstallationIds(installationIds, withInactives, userIsAdmin)
      .then(async users => {
        users.map((user) => {
          user.fullName = `${user.prenom.charAt(0).toUpperCase() + user.prenom.slice(1).toLowerCase()} ${user.nom.charAt(0).toUpperCase() + user.nom.slice(1).toLowerCase()}`;
          return user;
        });
        commit('UPDATE_USERS', users);
        return users;
      });
  },
  fetchUser({ dispatch }, id) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return userService.fetchUser(id)
      .catch(() => {
        that.$message.error('Erreur lors de la recuperation des informations utilisateur');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  saveUser({ dispatch }, user) {
    if (user.id) {
      return dispatch('updateUser', user);
    }
    return dispatch('createUser', user);
  },
  createUser({ dispatch }, user) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return userService.createUser(user)
      .then(() => {
        that.$notify({
          title: 'Utilisateur créé',
          message: 'L\'utilisateur a bien été ajouté',
          type: 'success'
        });
      }).catch(() => {
        that.$message.error('Erreur lors de la création de l\'utilisateur');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  updateUser({ dispatch }, user) {
    const that = this._vm;
    updateAppLoading(dispatch, true);
    return userService.updateUser(user)
      .then(() => {
        that.$notify({
          title: 'Utilisateur modifié',
          message: 'L\'utilisateur a bien été modifié',
          type: 'success'
        });
      }).catch(() => {
        that.$message.error('Erreur lors de la modification de l\'utilisateur');
        return Promise.reject();
      }).finally(() => {
        updateAppLoading(dispatch, false);
      });
  },
  updateUserFilterSelected({ commit }, userId) {
    commit('UPDATE_USER_FILTER_SELECTED', userId);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
